import { useCallback, useMemo } from 'react'
import { setTransactionHistoryList, setTransactionHistoryPagination, setPaymentHistoryList, setPaymentHistoryPagination } from '../Pages/Credit/Payment.Slice';
import API_URL from '../../Constants/api-path'
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios'

const usePayment = () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    const dispatch = useDispatch()
    const { transactionHistoryPagination, paymentHistoryPagination } = useSelector((state) => state.payment)

    const getTransactionHistoryById = useCallback(async (page_no = transactionHistoryPagination.page, limit = transactionHistoryPagination.limit) => {
        dispatch(setTransactionHistoryPagination({ page: page_no, limit: limit }))
        await axios
            .get(`${API_URL.GET_TRANSACTION_HISTORY_BY_ID}?page=${page_no}&limit=${limit}&company_id=${userData?.user?.company_id?._id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": userData.access_token,
                }
            })
            .then((res) => {
                let obj = {
                    data: res?.data?.data,
                    count: res?.data?.count,
                };
                dispatch(setTransactionHistoryList(obj));
                dispatch(setTransactionHistoryPagination({ page: page_no, limit: limit }));
            })
            .catch((err) => {
                console.error("ERROR", err);
            });
    }, [dispatch, transactionHistoryPagination.limit, transactionHistoryPagination.page, userData.access_token, userData?.user?.company_id?._id]);

    const getPaymentHistoryById = useCallback(async (page_no = paymentHistoryPagination.page, limit = paymentHistoryPagination.limit) => {
        dispatch(setPaymentHistoryPagination({ page: page_no, limit: limit }))

        await axios.get(`${API_URL.GET_PAYMENT_HISTORY_BY_ID}?page=${page_no}&limit=${limit}&company_id=${userData?.user?.company_id?._id}`, {
            headers: {
                "Content-Type": "application/json",
                "x-access-token": userData.access_token,
            }
        })
            .then((res) => {
                let obj = {
                    data: res?.data?.data,
                    count: res?.data?.count
                }
                dispatch(setPaymentHistoryList(obj))
                dispatch(setPaymentHistoryPagination({ page: page_no, limit: limit }));
            }).catch((err) => {
                console.error("ERROR", err)
            })
    }, [dispatch, paymentHistoryPagination.limit, paymentHistoryPagination.page, userData.access_token, userData?.user?.company_id?._id])


    const values = useMemo(() => ({
        getTransactionHistoryById: getTransactionHistoryById,
        getPaymentHistoryById: getPaymentHistoryById
    }), [getTransactionHistoryById, getPaymentHistoryById])

    return values
}

export default usePayment