import { Card, Skeleton, Spin, Tooltip } from "antd";
import React, { useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { checkForValidURL, formattedDate } from "../../Utils/Util";
import { useDispatch } from "react-redux";
import axios from "axios";
import API_URL from "../../Constants/api-path";
import company_icon3 from "../../assests/images/company_notfound.png";
import {
  setIsLoadingDescription,
  setIsOpenAiModal,
  setSelectedArticle,
} from "../Pages/Dashboard/news.slice";
import AiSummaryModal from "./AiSummaryModal";
import { LoadingOutlined } from "@ant-design/icons";
import AnswerAccordianRight from "./AnswerAccordianRight";
import { setShowMoreRight } from "../Pages/V2/Dashboard/Answer.slice";

function NewsTable({ title, cls, showMoreRight, itemkey, loading, data = { data: [1, 2, 3, 4] } }) {
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("userData"));
  // const [showMore, setShowMore] = React.useState(false);
  const sliceData = React.useMemo(() => showMoreRight?.open && showMoreRight?.index === itemkey ? data?.data : data?.data?.slice(0, 4), [data?.data, itemkey, showMoreRight?.index, showMoreRight?.open])

  const getNewsAiSummary = useCallback(
    async ({ news = {} }) => {
      if (news?._id) {
        dispatch(setIsLoadingDescription(true));
        await axios
          .get(API_URL.GET_FINANCE_NEWS_AI_SUMMARY + `?news_id=${news?._id}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userData?.access_token}`,
              },
            })
          .then((res) => {
            let article = { ...news, chatgpt_desc: res?.data?.data };
            dispatch(setSelectedArticle(article));
            dispatch(setIsLoadingDescription(false));
          })
          .catch((err) => {
            dispatch(setIsLoadingDescription(false));
            console.error("ERROR", err);
          });
      }
    },
    [dispatch, userData?.access_token]
  );
  return (
    <div className={`${cls}`}>
      <AnswerAccordianRight
        title={title}
        content={
          <div
          // className="custom_card_right"
          >
            <div className="ctm_header my-2">
              <Row>
                <Col xs={5}>
                  <Skeleton
                    rootClassName="custom_skeleton"
                    loading={loading}
                    style={{ width: 400, margin: 0 }}
                    active
                    title={true}
                    className="mb-0"
                    paragraph={{
                      rows: 0,
                      style: {
                        margin: 0,
                      },
                    }}
                  >
                    {" "}
                    <div>Headline</div>
                  </Skeleton>
                </Col>
                <Col xs={5}>
                  <Skeleton
                    loading={loading}
                    style={{ width: 300 }}
                    active
                    title={true}
                    className=" mb-0"
                    paragraph={{
                      rows: 0,
                      style: {
                        margin: 0,
                      },
                    }}
                  >
                    <div>Source</div>
                  </Skeleton>
                </Col>
                <Col xs={2}>
                  <Skeleton
                    loading={loading}
                    style={{ width: 200 }}
                    active
                    title={true}
                    className=" mb-0"
                    paragraph={{
                      rows: 0,
                      style: {
                        margin: 0,
                      },
                    }}
                  >
                    <div>Date</div>
                  </Skeleton>
                </Col>

                {/* <Col xs={2}>
                            <Skeleton loading={loading} style={{ width: 300 }} active title={true} className=" mb-0" paragraph={{
                                rows: 0, style: {
                                    margin: 0
                                }
                            }}><div>Credits</div></Skeleton>
                        </Col> */}
              </Row>
            </div>
            <div className="ctm_body">
              {loading && (
                <div
                  className="d-flex flex-column justify-content-center align-items-center"
                  style={{ height: 100 }}
                >
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 30 }} spin />
                    }
                  />
                  <span>Conducting a semantic search and ranking.</span>
                </div>
              )}

              {!loading &&
                sliceData?.map((data, id) => (
                  <Row
                    key={id}
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(setIsOpenAiModal(true));
                      dispatch(setSelectedArticle(data));
                      getNewsAiSummary({ news: data });
                      // navigate("/news/full-article");
                    }}
                  >
                    <Col xs={5}>
                      <Skeleton
                        loading={loading}
                        style={{ width: 400 }}
                        active
                        title={true}
                        className="mb-0"
                        paragraph={{
                          rows: 0,
                          style: {
                            margin: 0,
                          },
                        }}
                      >
                        {" "}
                        <Tooltip title={data?.title}>
                          <strong className="me-2 fw-normal truncate-text">
                            {data?.title}
                          </strong>
                        </Tooltip>
                      </Skeleton>
                    </Col>
                    <Col xs={5}>
                      <Skeleton
                        loading={loading}
                        style={{ width: 300 }}
                        active
                        title={true}
                        className="mb-0"
                        paragraph={{
                          rows: 0,
                          style: {
                            margin: 0,
                          },
                        }}
                      >
                        {" "}
                        <span className="d-flex align-items-center gap-2">
                          <span>
                            <img
                              src={checkForValidURL(data?.source?.icon)}
                              alt="logo"
                              className="img-contain"
                              height={"25px"}
                              width={"35px"}
                              onError={(e) => {
                                e.target.src = company_icon3;
                              }}
                            />
                          </span>
                          <small className="fw-normal truncate-text">
                            {data?.source?.name}
                          </small>
                        </span>
                      </Skeleton>
                    </Col>
                    <Col xs={2}>
                      <Skeleton
                        loading={loading}
                        style={{ width: 300 }}
                        active
                        title={true}
                        className="mb-0"
                        paragraph={{
                          rows: 0,
                          style: {
                            margin: 0,
                          },
                        }}
                      >
                        {" "}
                        <small className="fw-normal text-muted">
                          {formattedDate(data?.article_date)}{" "}
                        </small>
                      </Skeleton>
                    </Col>
                    {/* <Col xs={2} onMouseEnter={() => setHoveredItemId(data?._id)}
                                onMouseLeave={() => setHoveredItemId(null)}>
                                <Skeleton loading={loading} style={{ width: 300 }} active title={true} className="mb-0" paragraph={{
                                    rows: 0, style: {
                                        margin: 0
                                    }
                                }}>     <UnlockExpertButton
                                        data={data}
                                        hoveredItemId={hoveredItemId}
                                        unlockFunction={() => {
                                            getExpertById(data?._id);
                                            dispatch(setToggle(true));
                                            dispatch(setSelectedExpert(data));
                                        }}
                                    /></Skeleton>
                            </Col> */}
                  </Row>
                ))}

              {showMoreRight?.open && showMoreRight?.index === itemkey ? (
                <>
                  {/* <p className="list_style_ans_accordian">{contentTextShowMore}</p> */}
                  <div
                    className="d-flex align-items-center justify-content-end cursor-pointer themeBlue pointer"
                    onClick={() => {
                      dispatch(setShowMoreRight({
                        open: false,
                        index: itemkey
                      }))
                      // setShowMore(false);
                    }}
                  >
                    show less
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="d-flex align-items-center justify-content-end cursor-pointer themeBlue pointer"
                    onClick={() => {
                      dispatch(setShowMoreRight({
                        open: true,
                        index: itemkey
                      }))
                      // setShowMore(true);
                    }}
                  >
                    show more
                  </div>
                </>
              )}
              {!loading && data?.data?.length === 0 && (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: 100 }}
                >
                  <span>No News Found Related to search</span>
                </div>
              )}
            </div>
          </div>
        }
      />

      <AiSummaryModal />
    </div>
  );
}

export default NewsTable;
