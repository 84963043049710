import { LoadingOutlined } from "@ant-design/icons";
import { Card, Skeleton, Spin } from "antd";
import React from "react";
import { Col, Row } from "react-bootstrap";
import AnswerAccordianRight from "./AnswerAccordianRight";

function DummyTable({ title, cls, loading }) {
  return (
    <div className={`${cls}`}>
      <AnswerAccordianRight
        title={title}
        content={
          <div
          // className="custom_card_right"
          >
            <div className="ctm_header my-2">
              <Row>
                <Col xs={4}>
                  <Skeleton
                    rootClassName="custom_skeleton"
                    loading={loading}
                    style={{ width: 400, margin: 0 }}
                    active
                    title={true}
                    className="mb-0"
                    paragraph={{
                      rows: 0,
                      style: {
                        margin: 0,
                      },
                    }}
                  >
                    {" "}
                    <div>Title</div>
                  </Skeleton>
                </Col>
                <Col xs={3}>
                  <Skeleton
                    loading={loading}
                    style={{ width: 300 }}
                    active
                    title={true}
                    className=" mb-0"
                    paragraph={{
                      rows: 0,
                      style: {
                        margin: 0,
                      },
                    }}
                  >
                    <div>Expert</div>
                  </Skeleton>
                </Col>
                <Col xs={3}>
                  <Skeleton
                    loading={loading}
                    style={{ width: 300 }}
                    active
                    title={true}
                    className=" mb-0"
                    paragraph={{
                      rows: 0,
                      style: {
                        margin: 0,
                      },
                    }}
                  >
                    <div>Company</div>
                  </Skeleton>
                </Col>
                <Col xs={2}>
                  <Skeleton
                    loading={loading}
                    style={{ width: 200 }}
                    active
                    title={true}
                    className=" mb-0"
                    paragraph={{
                      rows: 0,
                      style: {
                        margin: 0,
                      },
                    }}
                  >
                    <div>Date</div>
                  </Skeleton>
                </Col>
              </Row>
            </div>
            <div className="ctm_body">
              {loading && (
                <div
                  className="d-flex flex-column justify-content-center align-items-center"
                  style={{ height: 100 }}
                >
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 30 }} spin />
                    }
                  />
                  <span>Your personalized answer is ready!</span>
                </div>
              )}
              {!loading && (
                <>
                  <Row>
                    <Col xs={4}>
                      <Skeleton
                        loading={loading}
                        style={{ width: 400 }}
                        active
                        title={true}
                        className="mb-0"
                        paragraph={{
                          rows: 0,
                          style: {
                            margin: 0,
                          },
                        }}
                      >
                        {" "}
                        <div>Evaluting a joint venture in newyork</div>
                      </Skeleton>
                    </Col>
                    <Col xs={3}>
                      <Skeleton
                        loading={loading}
                        style={{ width: 300 }}
                        active
                        title={true}
                        className="mb-0"
                        paragraph={{
                          rows: 0,
                          style: {
                            margin: 0,
                          },
                        }}
                      >
                        {" "}
                        <div>After Sales Manager</div>
                      </Skeleton>
                    </Col>
                    <Col xs={3}>
                      <Skeleton
                        loading={loading}
                        style={{ width: 300 }}
                        active
                        title={true}
                        className="mb-0"
                        paragraph={{
                          rows: 0,
                          style: {
                            margin: 0,
                          },
                        }}
                      >
                        {" "}
                        <div>Cummies Alberia</div>
                      </Skeleton>
                    </Col>
                    <Col xs={2}>
                      <Skeleton
                        loading={loading}
                        style={{ width: 300 }}
                        active
                        title={true}
                        className="mb-0"
                        paragraph={{
                          rows: 0,
                          style: {
                            margin: 0,
                          },
                        }}
                      >
                        {" "}
                        <div>26 Dec 2024</div>
                      </Skeleton>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>
                      <Skeleton
                        loading={loading}
                        style={{ width: 400 }}
                        active
                        title={true}
                        className="mb-0"
                        paragraph={{
                          rows: 0,
                          style: {
                            margin: 0,
                          },
                        }}
                      >
                        {" "}
                        <div>Evaluting a joint venture in newyork</div>
                      </Skeleton>
                    </Col>
                    <Col xs={3}>
                      <Skeleton
                        loading={loading}
                        style={{ width: 300 }}
                        active
                        title={true}
                        className="mb-0"
                        paragraph={{
                          rows: 0,
                          style: {
                            margin: 0,
                          },
                        }}
                      >
                        {" "}
                        <div>After Sales Manager</div>
                      </Skeleton>
                    </Col>
                    <Col xs={3}>
                      <Skeleton
                        loading={loading}
                        style={{ width: 300 }}
                        active
                        title={true}
                        className="mb-0"
                        paragraph={{
                          rows: 0,
                          style: {
                            margin: 0,
                          },
                        }}
                      >
                        {" "}
                        <div>Cummies Alberia</div>
                      </Skeleton>
                    </Col>
                    <Col xs={2}>
                      <Skeleton
                        loading={loading}
                        style={{ width: 300 }}
                        active
                        title={true}
                        className="mb-0"
                        paragraph={{
                          rows: 0,
                          style: {
                            margin: 0,
                          },
                        }}
                      >
                        {" "}
                        <div>26 Dec 2024</div>
                      </Skeleton>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>
                      <Skeleton
                        loading={loading}
                        style={{ width: 400 }}
                        active
                        title={true}
                        className="mb-0"
                        paragraph={{
                          rows: 0,
                          style: {
                            margin: 0,
                          },
                        }}
                      >
                        {" "}
                        <div>Evaluting a joint venture in newyork</div>
                      </Skeleton>
                    </Col>
                    <Col xs={3}>
                      <Skeleton
                        loading={loading}
                        style={{ width: 300 }}
                        active
                        title={true}
                        className="mb-0"
                        paragraph={{
                          rows: 0,
                          style: {
                            margin: 0,
                          },
                        }}
                      >
                        {" "}
                        <div>After Sales Manager</div>
                      </Skeleton>
                    </Col>
                    <Col xs={3}>
                      <Skeleton
                        loading={loading}
                        style={{ width: 300 }}
                        active
                        title={true}
                        className="mb-0"
                        paragraph={{
                          rows: 0,
                          style: {
                            margin: 0,
                          },
                        }}
                      >
                        {" "}
                        <div>Cummies Alberia</div>
                      </Skeleton>
                    </Col>
                    <Col xs={2}>
                      <Skeleton
                        loading={loading}
                        style={{ width: 300 }}
                        active
                        title={true}
                        className="mb-0"
                        paragraph={{
                          rows: 0,
                          style: {
                            margin: 0,
                          },
                        }}
                      >
                        {" "}
                        <div>26 Dec 2024</div>
                      </Skeleton>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>
                      <Skeleton
                        loading={loading}
                        style={{ width: 400 }}
                        active
                        title={true}
                        className="mb-0"
                        paragraph={{
                          rows: 0,
                          style: {
                            margin: 0,
                          },
                        }}
                      >
                        {" "}
                        <div>Evaluting a joint venture in newyork</div>
                      </Skeleton>
                    </Col>
                    <Col xs={3}>
                      <Skeleton
                        loading={loading}
                        style={{ width: 300 }}
                        active
                        title={true}
                        className="mb-0"
                        paragraph={{
                          rows: 0,
                          style: {
                            margin: 0,
                          },
                        }}
                      >
                        {" "}
                        <div>After Sales Manager</div>
                      </Skeleton>
                    </Col>
                    <Col xs={3}>
                      <Skeleton
                        loading={loading}
                        style={{ width: 300 }}
                        active
                        title={true}
                        className="mb-0"
                        paragraph={{
                          rows: 0,
                          style: {
                            margin: 0,
                          },
                        }}
                      >
                        {" "}
                        <div>Cummies Alberia</div>
                      </Skeleton>
                    </Col>
                    <Col xs={2}>
                      <Skeleton
                        loading={loading}
                        style={{ width: 300 }}
                        active
                        title={true}
                        className="mb-0"
                        paragraph={{
                          rows: 0,
                          style: {
                            margin: 0,
                          },
                        }}
                      >
                        {" "}
                        <div>26 Dec 2024</div>
                      </Skeleton>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>
                      <Skeleton
                        loading={loading}
                        style={{ width: 400 }}
                        active
                        title={true}
                        className="mb-0"
                        paragraph={{
                          rows: 0,
                          style: {
                            margin: 0,
                          },
                        }}
                      >
                        {" "}
                        <div>Evaluting a joint venture in newyork</div>
                      </Skeleton>
                    </Col>
                    <Col xs={3}>
                      <Skeleton
                        loading={loading}
                        style={{ width: 300 }}
                        active
                        title={true}
                        className="mb-0"
                        paragraph={{
                          rows: 0,
                          style: {
                            margin: 0,
                          },
                        }}
                      >
                        {" "}
                        <div>After Sales Manager</div>
                      </Skeleton>
                    </Col>
                    <Col xs={3}>
                      <Skeleton
                        loading={loading}
                        style={{ width: 300 }}
                        active
                        title={true}
                        className="mb-0"
                        paragraph={{
                          rows: 0,
                          style: {
                            margin: 0,
                          },
                        }}
                      >
                        {" "}
                        <div>Cummies Alberia</div>
                      </Skeleton>
                    </Col>
                    <Col xs={2}>
                      <Skeleton
                        loading={loading}
                        style={{ width: 300 }}
                        active
                        title={true}
                        className="mb-0"
                        paragraph={{
                          rows: 0,
                          style: {
                            margin: 0,
                          },
                        }}
                      >
                        {" "}
                        <div>26 Dec 2024</div>
                      </Skeleton>
                    </Col>
                  </Row>
                </>
              )}
              {!loading && [1, 2].length === 0 && (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: 100 }}
                >
                  <span>No Data Found Related to search</span>
                </div>
              )}
            </div>
          </div>
        }
      />
    </div>
  );
}

export default DummyTable;
