import React from 'react';
import { Modal } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenReqExpert } from '../NewDesign/Pages/ExpertPage/Expert.Slice';

const SuccessModalNew = () => {
    const { openReqExpert } = useSelector((state) => state.expert);
    const dispatch = useDispatch()
    return (
        <Modal
            title={""}
            open={openReqExpert}
            centered
            onOk={() => {
                dispatch(setOpenReqExpert(false))
            }}
            onCancel={() => {
                dispatch(setOpenReqExpert(false))
            }}
            footer={false}

        >
            <div className='d-flex flex-column justify-content-center align-items-center p-4'>
                <CheckCircleOutlined style={{
                    fontSize: 48,
                    color: "green"
                }} />
                <p className='fs-5'>Request sent Successfully!</p>
            </div>

        </Modal>
    );
};
export default SuccessModalNew;
