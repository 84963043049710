import axios from 'axios'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import useSWR from 'swr'
import API_URL from '../../Constants/api-path'
import { useDispatch, useSelector } from 'react-redux'
import { setAnswer, setCompaniesAnswer, setCurrent, setExpertAnswer, setNewsAnswer, setSecReportAnswer, setThreadQuestion, setTranscriptAnswer } from '../Pages/V2/Dashboard/Answer.slice'

const useAnswers = () => {
    // const { question } = useParams()
    const userData = JSON.parse(localStorage.getItem("userData"));
    const dispatch = useDispatch()
    const { answer, threadQuestion, question, answerTranscript, answerSecReport, answerNews, answerCompanies, answerExpert, current, activePanel, showMore, showMoreRight, thread_id } = useSelector((state) => state.answer)

    const getGptAnswer = useCallback(async () => {
        const res = await axios.get(API_URL.GPT_ANSWER + `?user_id=${userData?.user?._id}&question=${threadQuestion ? threadQuestion : question}&thread_id=${thread_id || ""}&limit=10&offset=0`)
        return res.data
    }, [userData?.user?._id, threadQuestion, question, thread_id])
    const getGptTranscriptAnswer = useCallback(async () => {
        const res = await axios.get(API_URL.GPT_ANSWER_TRANSCRIPT + `?thread_id=${thread_id}&limit=10&offset=0`)
        return res.data
    }, [thread_id])
    const getGptSecReportAnswer = useCallback(async () => {
        const res = await axios.get(API_URL.GPT_ANSWER_SEC_REPORT + `?thread_id=${thread_id}&limit=20&offset=0`)
        return res.data
    }, [thread_id])
    const getGptExpertAnswer = useCallback(async () => {
        const res = await axios.get(API_URL.GPT_ANSWER_EXPERT + `?thread_id=${thread_id}&limit=30&offset=0`)
        return res.data
    }, [thread_id])
    const getGptNewsAnswer = useCallback(async () => {
        const res = await axios.get(API_URL.GPT_ANSWER_NEWS + `?thread_id=${thread_id}&limit=20&offset=0`)
        return res.data
    }, [thread_id])
    const getGptCompaniesAnswer = useCallback(async () => {
        const res = await axios.get(API_URL.GET_COMPANIES + `?thread_id=${thread_id}&limit=20&offset=0`)
        return res.data
    }, [thread_id])
    const someFunction = () => {
        console.info('question: ', question);
    }

    const { isLoading } = useSWR(["get_gpt_answer", question, threadQuestion, thread_id], () => (question || threadQuestion) ? getGptAnswer() : someFunction(), {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (res) => {
            if (res) {
                dispatch(setCurrent(current + 1))
                dispatch(setAnswer(res))
                removeThreadQuestion()
            }
        },
        onError: (err) => {
            dispatch(setCurrent(current + 1))
            console.error("ERROR", err)
        }
    })

    const { isLoading: isLoadingTranscript } = useSWR(["get_gpt_transcript_answer", question, thread_id, threadQuestion, isLoading], () => thread_id && !isLoading ? getGptTranscriptAnswer() : someFunction(), {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (res) => {
            if (res) {
                dispatch(setCurrent(current + 1))
                dispatch(setTranscriptAnswer(res))
                removeThreadQuestion()
            }
        },
        onError: (err) => {
            dispatch(setCurrent(current + 1))
            console.error("ERROR", err)
        }
    })
    const { isLoading: isLoadingSecReport } = useSWR(["get_gpt_sec_report", question, thread_id, threadQuestion, isLoading], () => thread_id && !isLoading ? getGptSecReportAnswer() : someFunction(), {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (res) => {
            if (res) {
                dispatch(setCurrent(current + 1))
                dispatch(setSecReportAnswer(res))
                removeThreadQuestion()
            }
        },
        onError: (err) => {
            dispatch(setCurrent(current + 1))
            console.error("ERROR", err)
        }
    })
    const { isLoading: isLoadingExpert } = useSWR(["get_gpt_expert_answer", question, thread_id, threadQuestion, isLoading], () => thread_id && !isLoading ? getGptExpertAnswer() : someFunction(), {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (res) => {
            if (res) {
                dispatch(setCurrent(current + 1))
                dispatch(setExpertAnswer(res))
                removeThreadQuestion()
            }
        },
        onError: (err) => {
            dispatch(setCurrent(current + 1))
            console.error("ERROR", err)
        }
    })
    const { isLoading: isLoadingNews } = useSWR(["get_gpt_news_answer", question, thread_id, threadQuestion, isLoading], () => thread_id && !isLoading ? getGptNewsAnswer() : someFunction(), {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (res) => {
            if (res) {
                dispatch(setCurrent(current + 1))
                dispatch(setNewsAnswer(res))
                removeThreadQuestion()
            }
        },
        onError: (err) => {
            dispatch(setCurrent(current + 1))
            console.error("ERROR", err)
        }
    })
    const { isLoading: isLoadingCompanies } = useSWR(["get_gpt_companies_answer", question, thread_id, threadQuestion, isLoading], () => thread_id && !isLoading ? getGptCompaniesAnswer() : someFunction(), {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (res) => {
            if (res) {
                dispatch(setCurrent(current + 1))
                dispatch(setCompaniesAnswer(res))
                removeThreadQuestion()
            }
        },
        onError: (err) => {
            dispatch(setCurrent(current + 1))
            console.error("ERROR", err)
        }
    })
    const removeThreadQuestion = useCallback(() => {
        if (!isLoading && !isLoadingExpert && !isLoadingNews && !isLoadingSecReport && !isLoadingTranscript && !isLoadingCompanies) {
            console.log(
                "CAME HERE"
            );
            dispatch(setThreadQuestion(""))
        }
    }, [dispatch, isLoading, isLoadingCompanies, isLoadingExpert, isLoadingNews, isLoadingSecReport, isLoadingTranscript])
    const values = useMemo(() => ({
        answer, isLoading, isLoadingTranscript, isLoadingSecReport, isLoadingExpert, answerTranscript, answerSecReport, answerExpert, isLoadingNews, isLoadingCompanies, answerNews, current, activePanel, showMore, showMoreRight, threadQuestion, answerCompanies
    }), [answer, isLoading, isLoadingTranscript, isLoadingSecReport, isLoadingExpert, answerTranscript, answerSecReport, answerExpert, isLoadingNews, answerCompanies, isLoadingCompanies, answerNews, current, activePanel, showMore, showMoreRight, threadQuestion])
    return values
}

export default useAnswers
