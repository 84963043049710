import { Collapse, theme } from "antd";
import React, { useState } from "react";
import { MdBookmarkBorder } from "react-icons/md";
import barImg from "../../assests/images/bar_svg.png";
import { CaretRightOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { setShowMore } from "../Pages/V2/Dashboard/Answer.slice";

const AnswerAccordian = ({
  title,
  contentText,
  contentTextShowMore,
  itemKey, showMore
}) => {
  const { token } = theme.useToken();
  const panelStyle = {
    // marginBottom: 24,
    background: "#fff",
    borderRadius: "8px",
    border: "none",
  };
  const dispatch = useDispatch()
  const getItems = (panelStyle) => [
    {
      key: itemKey,
      label: (
        <div className="d-flex justify-content-between align-items-center fs-6">
          <span>{title}</span>
          <span>
            <MdBookmarkBorder className="fs-6 themeBlue" />
          </span>
        </div>
      ),
      children: (
        <div>
          {showMore?.open && showMore?.index === itemKey ? (
            <>
              <p className="list_style_ans_accordian">{contentTextShowMore}</p>
              <div
                className="d-flex align-items-center justify-content-end cursor-pointer themeBlue"
                onClick={() => {
                  dispatch(setShowMore({
                    open: false,
                    index: itemKey
                  }));
                }}
              >
                show less
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className="d-flex align-items-center">
                <p className="list_style_ans_accordian">{contentText}</p>
                {title === "News" && <img src={barImg} alt="bar_img" />}
              </div>
              <div
                className="d-flex align-items-center justify-content-end cursor-pointer themeBlue"
                onClick={() => {
                  dispatch(setShowMore({
                    open: true,
                    index: itemKey
                  }));
                }}
              >
                show more
              </div>
            </>
          )}
        </div>
      ),
      style: panelStyle,
    },
  ];
  return (
    <div className="custom_card">
      <Collapse
        bordered={false}
        defaultActiveKey={[itemKey]}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        style={
          {
            // background: token.colorBgContainer,
            // background: "#d9d9d9dd",
          }
        }
        items={getItems(panelStyle)}
      />
    </div>
  );
};

export default AnswerAccordian;
