import { Card, Skeleton, Spin, Tag, Tooltip } from "antd";
import React, { useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { checkForValidURL, formattedDate } from "../../Utils/Util";
import { useDispatch } from "react-redux";
import axios from "axios";
import API_URL from "../../Constants/api-path";
import company_icon3 from "../../assests/images/company_notfound.png";
import {
    setIsLoadingDescription,
    setIsOpenAiModal,
    setSelectedArticle,
} from "../Pages/Dashboard/news.slice";
import AiSummaryModal from "./AiSummaryModal";
import { LoadingOutlined } from "@ant-design/icons";
import AnswerAccordianRight from "./AnswerAccordianRight";
import { setCompanyTranscriptList, setIsLoadingCompanyTranscript, setOpenCompanyTranscriptModal, setShowMoreRight } from "../Pages/V2/Dashboard/Answer.slice";
import { useNavigate } from "react-router-dom";
import CompanyTranscriptModal from "./CompanyTranscriptModal";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";

function CompaniesTable({ title, cls, showMoreRight, itemkey, loading, data = { data: [1, 2, 3, 4] } }) {
    const dispatch = useDispatch();
    const userData = JSON.parse(localStorage.getItem("userData"));
    // const [showMore, setShowMore] = React.useState(false);
    const sliceData = React.useMemo(() => showMoreRight?.open && showMoreRight?.index === itemkey ? data?.data : data?.data?.slice(0, 4), [data?.data, itemkey, showMoreRight?.index, showMoreRight?.open])
    const navigate = useNavigate()
    const getCompanyTranscripts = useCallback(
        async ({ company = {} }) => {
            dispatch(setIsLoadingCompanyTranscript(true))
            if (company?._id) {
                await axios
                    .get(API_URL.GET_EARNING_CALL_TRANSCRIPT_LIST + `?symbol=${company?.symbol}&company_id=${company?._id}&cin=${company?.cin}`,
                        {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${userData?.access_token}`,
                            },
                        })
                    .then((res) => {
                        console.log('res: ', res);
                        dispatch(setCompanyTranscriptList(res?.data))
                        dispatch(setIsLoadingCompanyTranscript(false))
                        // navigate(`/company?company_id=${company?._id}`)
                    })
                    .catch((err) => {
                        console.error("ERROR", err);
                        dispatch(setIsLoadingCompanyTranscript(false))

                    });
            }
        },
        [dispatch, userData?.access_token]
    );
    return (
        <div className={`${cls}`}>
            <AnswerAccordianRight
                title={title}
                content={
                    <div
                    // className="custom_card_right"
                    >
                        <div className="ctm_header my-2">
                            <Row>
                                <Col xs={6}>
                                    <Skeleton
                                        rootClassName="custom_skeleton"
                                        loading={loading}
                                        style={{ width: 700, margin: 0 }}
                                        active
                                        title={true}
                                        className="mb-0"
                                        paragraph={{
                                            rows: 0,
                                            style: {
                                                margin: 0,
                                            },
                                        }}
                                    >
                                        {" "}
                                        <div>Name</div>
                                    </Skeleton>
                                </Col>
                                <Col xs={2} >
                                    <Skeleton
                                        loading={loading}
                                        style={{ width: 300 }}
                                        active
                                        title={true}
                                        className=" mb-0"
                                        paragraph={{
                                            rows: 0,
                                            style: {
                                                margin: 0,
                                            },
                                        }}
                                    >
                                        <div>Stock Price</div>
                                    </Skeleton>
                                </Col>
                                <Col xs={2} className="d-flex justify-content-center">
                                    <Skeleton
                                        loading={loading}
                                        style={{ width: 300 }}
                                        active
                                        title={true}
                                        className=" mb-0"
                                        paragraph={{
                                            rows: 0,
                                            style: {
                                                margin: 0,
                                            },
                                        }}
                                    >
                                        <div>Country</div>
                                    </Skeleton>
                                </Col>
                                <Col xs={2} className="d-flex justify-content-center">
                                    <Skeleton
                                        loading={loading}
                                        style={{ width: 300 }}
                                        active
                                        title={true}
                                        className=" mb-0"
                                        paragraph={{
                                            rows: 0,
                                            style: {
                                                margin: 0,
                                            },
                                        }}
                                    >
                                        <div>Exchange</div>
                                    </Skeleton>
                                </Col>


                            </Row>
                        </div>
                        <div className="ctm_body">
                            {loading && (
                                <div
                                    className="d-flex flex-column justify-content-center align-items-center"
                                    style={{ height: 100 }}
                                >
                                    <Spin
                                        indicator={
                                            <LoadingOutlined style={{ fontSize: 30 }} spin />
                                        }
                                    />
                                    <span>Conducting a semantic search and ranking.</span>
                                </div>
                            )}

                            {!loading &&
                                sliceData?.map((data, id) => (
                                    <Row
                                        key={id}
                                        style={{
                                            cursor: "pointer"
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            dispatch(setOpenCompanyTranscriptModal(true))
                                            getCompanyTranscripts({ company: data })
                                            // navigate("/news/full-article");
                                        }}
                                    >

                                        <Col xs={6}>
                                            <Skeleton
                                                loading={loading}
                                                style={{ width: 300 }}
                                                active
                                                title={true}
                                                className="mb-0"
                                                paragraph={{
                                                    rows: 0,
                                                    style: {
                                                        margin: 0,
                                                    },
                                                }}
                                            >
                                                {" "}
                                                <span className="d-flex align-items-center gap-2">
                                                    <span>
                                                        <img
                                                            src={checkForValidURL(data?.logo_url)}
                                                            alt="logo"
                                                            className="img-contain"
                                                            height={"35px"}
                                                            width={"35px"}
                                                            onError={(e) => {
                                                                e.target.src = company_icon3;
                                                            }}
                                                        />
                                                    </span>
                                                    <small className="fw-normal truncate-text">
                                                        {data?.name}
                                                    </small>
                                                    <Tag color="green" >
                                                        {data?.symbol}
                                                    </Tag>
                                                </span>
                                            </Skeleton>
                                        </Col>
                                        <Col xs={2} className="">
                                            <Skeleton
                                                loading={loading}
                                                style={{ width: 400 }}
                                                active
                                                title={true}
                                                className="mb-0"
                                                paragraph={{
                                                    rows: 0,
                                                    style: {
                                                        margin: 0,
                                                    },
                                                }}
                                            >
                                                <span className="d-flex align-items-center justify-content-start gap-1 text-start">
                                                    <span className="fw-bolder ">
                                                        {data.futures_chain?.[0]?.currency}{data?.futures_chain?.[0]?.extracted_price}
                                                    </span>
                                                    <span
                                                        className="fw-bolder d-flex align-items-center"
                                                        style={{
                                                            color: data?.futures_chain?.[0]?.price_movement?.movement === "Up" ? "green" : "red",
                                                        }}
                                                    >
                                                        {data?.futures_chain?.[0]?.price_movement?.movement === "Up" ? (
                                                            <MdArrowUpward />
                                                        ) : (
                                                            <MdArrowDownward />
                                                        )}
                                                        {Math.abs(data?.futures_chain?.[0]?.price_movement?.percentage)}%
                                                    </span>
                                                </span>
                                            </Skeleton>
                                        </Col>
                                        <Col xs={2} className="d-flex justify-content-center">
                                            <Skeleton
                                                loading={loading}
                                                style={{ width: 400 }}
                                                active
                                                title={true}
                                                className="mb-0"
                                                paragraph={{
                                                    rows: 0,
                                                    style: {
                                                        margin: 0,
                                                    },
                                                }}
                                            >
                                                {" "}
                                                <Tooltip title={data?.country}>
                                                    <strong className=" me-2 fw-normal truncate-text ">
                                                        {data?.country}
                                                    </strong>
                                                </Tooltip>
                                            </Skeleton>
                                        </Col>
                                        <Col xs={2} className="d-flex justify-content-center">
                                            <Skeleton
                                                loading={loading}
                                                style={{ width: 400 }}
                                                active
                                                title={true}
                                                className="mb-0"
                                                paragraph={{
                                                    rows: 0,
                                                    style: {
                                                        margin: 0,
                                                    },
                                                }}
                                            >
                                                {" "}
                                                <Tooltip title={data?.exchange}>
                                                    <strong className="me-2 fw-normal truncate-text">
                                                        {data?.exchange}
                                                    </strong>
                                                </Tooltip>
                                            </Skeleton>
                                        </Col>

                                    </Row>
                                ))}

                            {showMoreRight?.open && showMoreRight?.index === itemkey ? (
                                <>
                                    {/* <p className="list_style_ans_accordian">{contentTextShowMore}</p> */}
                                    <div
                                        className="d-flex align-items-center justify-content-end cursor-pointer themeBlue pointer"
                                        onClick={() => {
                                            dispatch(setShowMoreRight({
                                                open: false,
                                                index: itemkey
                                            }))
                                            // setShowMore(false);
                                        }}
                                    >
                                        show less
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div
                                        className="d-flex align-items-center justify-content-end cursor-pointer themeBlue pointer"
                                        onClick={() => {
                                            dispatch(setShowMoreRight({
                                                open: true,
                                                index: itemkey
                                            }))
                                            // setShowMore(true);
                                        }}
                                    >
                                        show more
                                    </div>
                                </>
                            )}
                            {!loading && data?.data?.length === 0 && (
                                <div
                                    className="d-flex justify-content-center align-items-center"
                                    style={{ height: 100 }}
                                >
                                    <span>No News Found Related to search</span>
                                </div>
                            )}
                        </div>
                    </div>
                }
            />

            <CompanyTranscriptModal />
        </div>
    );
}

export default CompaniesTable;
