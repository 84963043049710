import { Button, Form, Input, notification, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "reactstrap";
import API_PATH from "../Constants/api-path";
import { decodeToken } from "react-jwt";
import { useDispatch, useSelector } from "react-redux";
import {
  ExclamationCircleOutlined,
  LockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import API_URL from "../Constants/api-path";
import { email_login, otp_login } from "./auth.slice";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import OtpInput from "react-otp-input-rc-17";
function LoginForm() {
  const [visible, setVisible] = useState(false);
  const InputType = visible ? "text" : "password";
  const history = useNavigate();
  const loginData = JSON.parse(localStorage.getItem("userData"));
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [isAuth, setIsAuth] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);

  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);
  const [userData, setValue] = useState({
    password: "",
    email: "",
    registrationType: "cloud",
    loginType: "", //password or otp
  });

  const handleInputs = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setValue({ ...userData, [key]: value });
  };

  // const useOnce = React.useRef(false)

  // useEffect(() => {
  //   if (searchParams && !useOnce.current) {
  //     useOnce.current = true
  //     let token = searchParams?.get("token")
  //     let userLogin = decodeToken(token)
  //     setValue({
  //       ...userData,
  //       email: userLogin?.email,
  //       password: userLogin?.password
  //     })
  //   }

  // }, [searchParams, userData])

  // const [toastClosed, setToastClosed] = useState(false);
  // const [toastClosedInvalid, setToastClosedInvalid] = useState(false);
  // const [toastShown, setToastShown] = useState(false);

  // const handleClick = () => {
  //   if (!toastClosed) {
  //     toast("This is a toast message!", {
  //       onClose: () => setToastClosed(true),
  //     });
  //   }
  // };

  // useEffect(() => {
  //   if (isAuth) {
  //     let redirectPath = localStorage.getItem("redirectPath");
  //     if (redirectPath) {
  //       localStorage.removeItem("redirectPath");
  //       history(redirectPath);
  //     } else {
  //       history("/dashboard");
  //       setTimeout(() => {
  //         return toast.success("Login Successful!", {
  //           position: toast.POSITION.TOP_RIGHT,
  //           autoClose: 3000,
  //         });
  //       }, 100);
  //     }
  //   }
  // }, [isAuth]);

  const loginUser = async (e) => {
    const { email, password } = userData;
    localStorage.setItem("ChangePassword", JSON.stringify(password));
    dispatch(email_login({ email, password }));
    // e.preventDefault();
    // setIsAuth(false);
    // if (userData.email !== "" && userData.password !== "") {
    //   setSpinner(true);
    //   try {
    //     const res = await fetch(`${API_PATH.LOGINURL}`, {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       body: JSON.stringify(userData),
    //     });

    //     const result = await res.json();
    //     if (result) {
    //       setSpinner(false);
    //       if (result.status === 200) {
    //         result.user = JSON.parse(atob(result.user));
    //         result.access_token = atob(result.access_token);
    //         result.refresh_token = atob(result.refresh_token);

    //         localStorage.setItem("userData", JSON.stringify(result));
    //         //       localStorage.setItem('token', result.token);
    //         // localStorage.setItem('tokenExpirationDate', result.tokenExpirationDate);
    //         if (result.user.passString) {
    //           history("/reset-password");
    //         } else {
    //           setIsAuth(true);
    //           localStorage.setItem("isAuth", true);
    //           localStorage.setItem(
    //             "ChangePassword",
    //             JSON.stringify(userData.password)
    //           );
    //           history("/dashboard");
    //         }
    //       } else {
    //         // const res1 = await fetch(`${API_PATH.SIGNUPURL}`, {
    //         //   method: "POST",
    //         //   headers: {
    //         //     "Content-Type": "application/json",
    //         //   },
    //         //   body: JSON.stringify(userData),
    //         // });
    //         // const result1 = await res1.json();
    //         // if (result1.data) {
    //         //   let payload = {
    //         //     username: result1.data.name,
    //         //     company: result1.data.company,
    //         //     contactNumber: result1.data.contact_number,
    //         //     email: result1.data.email,
    //         //     password: userData.password,
    //         //     contactCode: result1.data.contact_code,
    //         //     country: result1.data.country,
    //         //     role_id: "629862f8f12b176d0ef72c7b",
    //         //   };

    //         //   const res2 = await fetch(`${API_PATH.USER_MANAGEMENT_POST}`, {
    //         //     method: "POST",
    //         //     headers: {
    //         //       "Content-Type": "application/json",
    //         //     },
    //         //     body: JSON.stringify(payload),
    //         //   });
    //         //   const result2 = await res2.json();
    //         //   if (result2.status === 200) {
    //         //     loginUser();
    //         //   }
    //         // }
    //         setSpinner(false);
    //         // if (!toastClosedInvalid) {
    //         return toast.error(result?.message || "Invalid credentials", {
    //           position: toast.POSITION.TOP_RIGHT,
    //           autoClose: 3000,
    //           // onClose: () => setToastClosedInvalid(true),
    //         });
    //         // }
    //       }
    //     } else {
    //       return toast.error("something went wrong server error", {
    //         position: toast.POSITION.TOP_RIGHT,
    //         autoClose: 3000,
    //       });
    //     }
    //   } catch (error) {
    //     console.log(error);
    //     return toast.error("something went wrong server error", {
    //       position: toast.POSITION.TOP_RIGHT,
    //       autoClose: 3000,
    //     });
    //   }
    // } else {
    //   // if (!toastClosed) {
    //   return toast.warning("All fields are mandatory!", {
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: 3000,
    //     onClose: () => setToastClosed(true),
    //   });
    //   // }
    // }
  };

  // useEffect(() => {
  //   if (isAuth) {
  //     history("/projects");
  //     setIsAuth(false);
  //   }
  // }, [isAuth]);

  const something = (event) => {
    if (event.keyCode === 13) {
      loginUser(event);
    }
  };
  const sendOTP = async () => {
    try {
      setLoadingEmail(true);
      const response = await fetch(`${API_URL.SENDOTP}/${userData?.email}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          registrationType: "client",
        }),
      });
      let login_response = await response.json();
      if (login_response?.status === 200 || login_response?.status === "200") {
        setLoadingEmail(false);
        form.setFieldValue("password", "");
        setValue({
          ...userData,
          password: "",
          loginType: "otp",
        });

        setTimeout(() => {
          return toast.success("OTP Sent Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }, 100);
      } else {
        setLoadingEmail(false);
        toast.error(login_response.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    } catch (error) {
      setLoadingEmail(false);
      notification.error({
        message: error.message,
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
  };
  return (
    <>
      <Form
        form={form}
        layout="vertical"
        className="no_hvr"
        autoComplete="off"
        onFinish={() => {
          if (userData?.loginType === "otp") {
            let { email, password } = userData;
            dispatch(otp_login({ email, password }));
          } else {
            loginUser();
          }
        }}
      >
        {/* <div className="form-group">
          <label> Business Email* </label>
          <input
            type="text"
            name="email"
            value={userData.email}
            onChange={handleInputs}
            className="form-control"
            autoComplete="nope"
            placeholder="Enter Your Business Email"
          />
        </div> */}
        {userData?.loginType === "" && (
          <Form.Item
            name="email"
            label="Business Email"
            rules={[
              {
                type: "email",
                message: "Please enter a valid email address!",
              },
              {
                required: true,
                message: "Email is required!",
              },
            ]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              type="text"
              name="email"
              value={userData?.email}
              onChange={handleInputs}
              // className="form-control"
              autoComplete="nope"
              placeholder="Enter Your Business Email"
              size="large"
            />
          </Form.Item>
        )}
        {userData?.loginType !== "" && (
          <div className="mb-4">
            {" "}
            <Tooltip title="Back">
              <MdOutlineKeyboardBackspace
                style={{
                  cursor: "pointer",
                  fontSize: 22,
                  marginRight: 4,
                }}
                onClick={() => {
                  form.setFieldValue("password", "");
                  setValue({
                    ...userData,
                    password: "",
                    registrationType: "client",
                    loginType: "", //password or otp
                  });
                }}
              />
            </Tooltip>
            <span className="fw-bold fs-5">Email -</span>{" "}
            <span className="fs-5"> {userData?.email}</span>
          </div>
        )}
        {userData?.loginType === "password" && (
          <>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                name="password"
                onChange={handleInputs}
                // className="form-control"
                id="password-field"
                type={InputType}
                size="large"
                autoComplete="nope"
                // onKeyDown={(e) => something(e)}
                placeholder="Password"
              />
            </Form.Item>
            <NavLink
              className="d-flex justify-content-end"
              to="/forgot-password"
              style={{ textDecoration: "none" }}
            >
              <small style={{ color: "#523EE8" }}>Forgot Password?</small>
            </NavLink>
          </>
        )}
        {/* <div className="form-group">
          <label> Password* </label>
          <div className="lable-input">
            <input
              name="password"
              onChange={handleInputs}
              id="password-field"
              type={InputType}
              value={userData.password}
              className="form-control"
              autoComplete="nope"
              onKeyDown={(e) => something(e)}
              placeholder="Password"
            />
            <span onClick={() => setVisible((visibilty) => !visibilty)}>
              {visible ? (
                <i className="fa fa-fw fa-eye" aria-hidden="false"></i>
              ) : (
                <i className="fa fa-fw fa-eye-slash" aria-hidden="true"></i>
              )}
            </span>
          </div>
          <NavLink
            className="d-flex justify-content-end"
            to="/forgot-password"
            style={{ textDecoration: "none" }}
          >
            <small style={{ color: "#523EE8" }}>Forgot Password?</small>
          </NavLink>
        </div> */}

        {userData?.loginType === "otp" && (
          <>
            <Form.Item
              name="password"
              label="OTP"
              rules={[
                {
                  required: true,
                  message: "Please input your OTP!",
                },
              ]}
            >
              {/* <div
              id="otp"
              className="d-flex justify-content-center align-items-center flex-row"
            > */}
              <OtpInput
                value={userData?.password}
                onChange={(otp) => {
                  setValue({
                    ...userData,
                    password: otp,
                  });
                }}
                numInputs={6}
                inputStyle={{
                  width: "100%",
                  height: "2.5rem",
                  margin: "0 0.5rem",
                  fontSize: "20px",
                  borderRadius: 4,
                  border: "1px solid #5746ec ",
                }}
              />
              {/* </div> */}
            </Form.Item>
          </>
        )}

        {userData?.loginType === "" && (
          <div className="d-flex align-items-center justify-content-center w-100 gap-2">
            <Form.Item shouldUpdate className="flex-1 w-100">
              <Button
                htmlType="button"
                className="login-btn"
                disabled={
                  userData?.email === "" ||
                  (userData?.email &&
                    form.getFieldError("email")?.length !== 0) ||
                  loadingEmail
                }
                onClick={() => {
                  let errorLen = form.getFieldError("email")?.length;
                  if (errorLen === 0) {
                    form.setFieldValue("password", "");
                    setValue({
                      ...userData,
                      password: "",
                      loginType: "password",
                    });
                  }
                }}
              >
                Login With Password
              </Button>
            </Form.Item>
            <Form.Item shouldUpdate className="flex-1 w-100">
              <Button
                htmlType="button"
                className="login-btn m-0"
                disabled={
                  userData?.email === "" ||
                  (userData?.email &&
                    form.getFieldError("email")?.length !== 0) ||
                  loading
                }
                onClick={() => {
                  let errorLen = form.getFieldError("email")?.length;
                  if (errorLen === 0) {
                    sendOTP();
                  }
                }}
              >
                Login With OTP
              </Button>
            </Form.Item>
          </div>
        )}

        {userData?.loginType !== "" && (
          <div className="form-group">
            <Form.Item shouldUpdate>
              <Button htmlType="submit" className="btn login-btn">
                {loading ? (
                  <>
                    <Spinner
                      size="sm"
                      style={{
                        width: "1rem",
                        height: "1rem",
                        marginRight: "5px",
                      }}
                    />
                    Login
                  </>
                ) : (
                  "Login"
                )}
              </Button>
            </Form.Item>
          </div>
        )}
        {userData?.loginType === "otp" && (
          <div className="form-group m-0">
            <p className="text-center m-0">
              <NavLink
                className="register-btn px-2"
                onClick={() => {
                  sendOTP();
                }}
              >
                Resend OTP
              </NavLink>
            </p>
          </div>
        )}
      </Form>

      {userData?.loginType === "" && (
        <div className="form-group">
          <p className="text-center">
            <NavLink to="/demo" className="register-btn px-2">
              Request Demo
            </NavLink>
          </p>
        </div>
      )}
    </>
  );
}
export default LoginForm;
