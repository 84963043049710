import { Collapse, theme } from 'antd'
import React from 'react'
import { MdBookmarkBorder } from "react-icons/md";
import barImg from "../../assests/images/bar_svg.png";
import { CaretRightOutlined } from '@ant-design/icons';

const AnswerAccordianRight = ({ title, content }) => {
    const { token } = theme.useToken();
    const panelStyle = {
        // marginBottom: 20,
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: 'none',
    };

    const getItems = () => [
        {
            key: '1',
            label: <div>{title}</div>,
            children: content,
            style: panelStyle,
        },

    ];
    return (
        <div className='custom_card_right'>
            <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                style={{
                    background: token.colorBgContainer,
                    // background: "#d9d9d9dd",
                }}
                items={getItems()}
            />
        </div>
    )
}

export default AnswerAccordianRight
