import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    answer: {},
    question: "",
    threadQuestion: "",
    thread_id: "",
    answerTranscript: {},
    answerExpert: {},
    answerNews: {},
    answerCompanies: {},
    answerSecReport: {},
    sec_report_summary: [],
    sec_report_loading: false,
    openCompanyTranscriptModal: false,
    isLoadingCompanyTranscript: false,
    selectedCompanyTranscript: {},
    selectedCompanyTranscriptData: {},
    companyTranscriptList: {},
    current: 0,
    activePanel: "1",
    showMore: {
        open: false,
        index: "1"
    },
    showMoreRight: {
        open: false,
        index: "1"
    }
}

const answerSlice = createSlice({
    initialState: initialState,
    name: "ProjectListById",
    reducers: {
        setAnswer: (state, { payload }) => {
            state.answer = payload
        },
        setQuestion: (state, { payload }) => {
            state.question = payload
        },
        setThreadQuestion: (state, { payload }) => {
            state.threadQuestion = payload
        },
        setOpenCompanyTranscriptModal: (state, { payload }) => {
            state.openCompanyTranscriptModal = payload
        },
        setIsLoadingCompanyTranscript: (state, { payload }) => {
            state.isLoadingCompanyTranscript = payload
        },
        setSelectedCompanyTranscript: (state, { payload }) => {
            state.selectedCompanyTranscript = payload
        },
        setSelectedCompanyTranscriptData: (state, { payload }) => {
            state.selectedCompanyTranscriptData = payload
        },
        setCompanyTranscriptList: (state, { payload }) => {
            state.companyTranscriptList = payload
        },
        setThreadId: (state, { payload }) => {
            state.thread_id = payload
        },
        setShowMore: (state, { payload }) => {
            state.showMore = payload
        },
        setShowMoreRight: (state, { payload }) => {
            state.showMoreRight = payload
        },
        setActivePanel: (state, { payload }) => {
            state.activePanel = payload
        },
        setSecReportSummary: (state, { payload }) => {
            state.sec_report_summary = payload
        },
        setSecReportLoading: (state, { payload }) => {
            state.sec_report_loading = payload
        },
        setTranscriptAnswer: (state, { payload }) => {
            state.answerTranscript = payload
        },
        setSecReportAnswer: (state, { payload }) => {
            state.answerSecReport = payload
        },
        setExpertAnswer: (state, { payload }) => {
            state.answerExpert = payload
        },
        setNewsAnswer: (state, { payload }) => {
            state.answerNews = payload
        },
        setCompaniesAnswer: (state, { payload }) => {
            state.answerCompanies = payload
        },
        setCurrent: (state, { payload }) => {
            state.current = payload
        },
        resetAnswer: (state) => {
            state.answer = {}
            state.answerTranscript = {}
            state.answerSecReport = {}
            state.answerExpert = {}
            state.answerNews = {}
            state.answerCompanies = {}
            state.current = 0
            state.activePanel = "1"
            state.showMore = {
                open: false,
                index: "1"
            }
            state.showMoreRight = {
                open: false,
                index: "1"
            }
        }
    }
})
export const answerReducer = answerSlice.reducer
export const { setAnswer, setThreadQuestion, setCompaniesAnswer, setQuestion, setTranscriptAnswer, setExpertAnswer, setNewsAnswer, resetAnswer, setCurrent, setSecReportAnswer, setSecReportLoading, setSecReportSummary, setActivePanel, setShowMore, setShowMoreRight, setThreadId, setIsLoadingCompanyTranscript, setCompanyTranscriptList, setOpenCompanyTranscriptModal, setSelectedCompanyTranscript, setSelectedCompanyTranscriptData } = answerSlice.actions