import { Button, Card, Form, Input, Skeleton, Spin } from "antd";
import React, { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import "./Dashboard.css";
import "./DashboardAi.css";
import "./AnswerPage.css";
import PrimaryTranscript from "../../../Components/PrimaryTranscript";
import useAnswers from "../../../Hooks/useAnswers";
import ExpertTable from "../../../Components/ExpertTable";
import ExpertDetailsModal from "../../../Components/ExpertDetailsModal";
import useUser from "../../../Hooks/useUser";
import NewsTable from "../../../Components/NewsTable";
import { BsSearch, BsStars } from "react-icons/bs";
import { setIsOpenCreatProject, setIsOpenProject } from "./Project.Slice";
import { useDispatch, useSelector } from "react-redux";
import { setExpertProfile, setSelectedExpert } from "../../ExpertPage/Expert.Slice";
import { CreateProjectModal } from "../../../../components/CreateProjectModal";
import useProject from "../../../Hooks/useProject";
import DummyTable from "../../../Components/DummyTable";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import AnswerAccordian from "../../../Components/AnswerAccordian";
import SourcePopover from "../../../Components/SourcePopover";
import SecondaryReport from "../../../Components/SecondaryReport";
import SuccessModalNew from "../../../../Utils/SuccessModalNew";
import { ProjectListModal } from "../../../../components/ProjectListModal";
import { setAnswer, setThreadQuestion } from "./Answer.slice";
import CompaniesTable from "../../../Components/CompaniesTable";
const AnswerPage = () => {
  const { spendCredits } = useUser();
  const {
    answerExpert,
    answerTranscript,
    answerNews,
    isLoadingNews,
    isLoading,
    isLoadingExpert,
    isLoadingTranscript,
    isLoadingSecReport, isLoadingCompanies,
    answerSecReport, answer, showMore, showMoreRight, threadQuestion, answerCompanies
  } = useAnswers();
  const { AddExpertProject, getProjectListById, mutateProjectList } =
    useProject();
  const navigate = useNavigate();
  const { projectListById } = useSelector((state) => state.project);
  const [form] = Form.useForm();
  const { question } = useParams();
  const dispatch = useDispatch();
  const [tempQuestion, setTempQuestion] = useState("");
  const endRef = useRef(null);

  const scrollToEnd = () => {
    endRef.current?.scrollTo({
      top: endRef.current.scrollHeight,
      behavior: 'smooth',
    });
  };
  return (
    <>
      <Helmet>
        <title>{"Nextyn IQ - Dashboard"}</title>
      </Helmet>
      <div
        className="content-wrapper content-wrapper-ai bg-light content-body"
        id="main-screen-dashboard"
      >
        {/* <div className="content-body"> */}
        <Row className="p-2">
          <Col md={6} className="answer-card-body p-0 m-0">
            <div className="answer_body_wrapper_left">
              <Card
                title={""}
                styles={{
                  body: {
                    padding: "15px 15px 15px 15px",
                  },
                }}
              >
                <div>
                  {isLoading && ["", null, undefined]?.includes(threadQuestion) ? (
                    <div className="qna_body d-flex flex-column gap-2 align-items-center justify-content-center">
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ fontSize: 48 }} spin />
                        }
                      />
                      <span>Initiating query processing and moderation.</span>
                    </div>
                  ) : (
                    <div ref={endRef} className={!["", null, undefined]?.includes(threadQuestion) && isLoading ? "qna_body_sec" : "qna_body"}>
                      {answer?.data?.map((conversation, index) => (<div key={index}>
                        <div className="question_body themeBlue">
                          <span>{conversation?.question}</span>
                        </div>
                        <div className="answer_body">

                          {!["", null, undefined]?.includes(conversation?.answer?.report?.report_nextyn_ai) && <AnswerAccordian
                            title={<h5>Secondary Reports</h5>}
                            contentText={
                              <>
                                <p>
                                  {conversation?.answer?.report?.report_nextyn_ai?.slice(0, 400)} {" "}
                                  <SourcePopover title={"Secondary Reports"} answer={conversation?.answer?.report?.sources} />
                                </p>

                              </>
                            }
                            contentTextShowMore={
                              <div>
                                {conversation?.answer?.report?.report_nextyn_ai} {" "}
                                <SourcePopover title={"Secondary Reports"} answer={conversation?.answer?.report?.sources} />
                              </div>
                            }
                            itemKey="1"
                            showMore={showMore}
                          />}
                          {/* {!["", null, undefined]?.includes(conversation?.answer?.expert?.expert_nextyn_ai) && <AnswerAccordian
                          title={"Experts"}
                          contentText={
                            <p>
                              {conversation?.answer?.expert?.expert_nextyn_ai?.slice(0, 300)}
                              <SourcePopover title={"Experts"} answer={conversation?.answer?.expert?.sources} />
                            </p>
                          }
                          contentTextShowMore={
                            <div>
                              <p>
                                {conversation?.answer?.expert?.expert_nextyn_ai}
                                <SourcePopover title={"Experts"} answer={conversation?.answer?.expert?.sources} />
                              </p>
                            </div>
                          }
                          itemKey="2"
                        />} */}
                          {!["", null, undefined]?.includes(conversation?.answer?.transcript?.transcript_nextyn_ai) && <AnswerAccordian
                            title={<h5>Primary Transcripts</h5>}
                            contentText={
                              <p>
                                {conversation?.answer?.transcript?.transcript_nextyn_ai?.slice(0, 400)}
                                <SourcePopover title={"Primary Research Transcripts"} answer={conversation?.answer?.transcript?.sources} />
                              </p>
                            }
                            contentTextShowMore={
                              <>
                                <p>
                                  {conversation?.answer?.transcript?.transcript_nextyn_ai}
                                  <SourcePopover title={"Primary Research Transcripts"} answer={conversation?.answer?.transcript?.sources} />
                                </p>
                              </>
                            }
                            itemKey="3"
                            showMore={showMore}
                          />}


                        </div>
                      </div>))}
                    </div>
                  )}
                </div>
                {!["", null, undefined]?.includes(threadQuestion) && isLoading && (
                  <div className="chat-bubble">
                    <div className="typing">
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                    </div>
                  </div>
                )}
                <div className="">
                  <div className="follow_up_container">
                    <Form
                      form={form}
                      layout="vertical"
                      name="question_answer_thread"
                      style={{
                        width: "100%",
                      }}
                      onFinish={() => {
                        scrollToEnd()
                        // dispatch(resetAnswer());
                        let newobj = {
                          ...answer,
                          data: [...answer?.data, { question: tempQuestion }]
                        }
                        dispatch(setAnswer(newobj))
                        dispatch(setThreadQuestion(tempQuestion))
                        dispatch(setExpertProfile({ data: [], count: 0 }));
                        dispatch(setSelectedExpert({}));
                        navigate(`/search/result/${question}`);
                        setTempQuestion("")
                        form.resetFields();
                      }}
                    >
                      <Input
                        placeholder="Date, just a click away..."
                        name="question_thread"
                        autoComplete="off"
                        value={tempQuestion}
                        prefix={<BsSearch />}
                        onChange={(e) => setTempQuestion(e.target.value)}
                        suffix={
                          <Button
                            htmlType="submit"
                            type="text"
                            icon={<BsStars style={{ fontSize: 18 }} />}
                            style={{ border: "none", boxShadow: "none" }}
                          />
                        }
                      />
                    </Form>
                  </div>
                </div>
              </Card>
            </div>
          </Col>
          <Col md={6} className="p-0 m-0">
            {/* {isLoading || isLoadingExpert || isLoadingNews || isLoadingTranscript ?
                <>
                  <div className="backdrop"></div>
                  <div className="step_loader">
                    <Steps
                      direction="vertical"
                      size="small"
                      className="custom_step_style"
                      current={current}
                      items={[
                        { title: 'Initiating query processing and moderation."', style: { color: "aqua" } },
                        { title: 'Conducting a semantic search and ranking.', style: { color: "aqua" } },
                        { title: 'LLM is generating your response.', style: { color: "aqua" } },
                        { title: 'Your personalized answer is ready!', style: { color: "aqua" } },
                      ]}
                    />
                  </div>
                </>
                : null} */}
            <div className="answer_body_wrapper">
              <div
                className={
                  isLoading ||
                    isLoadingExpert ||
                    isLoadingNews ||
                    isLoadingTranscript || isLoadingCompanies
                    ? "answer_body_sec_loading"
                    : "answer_body_sec"
                }
              >
                <PrimaryTranscript
                  cls="answer_table"
                  loading={isLoading || isLoadingTranscript}
                  title={
                    <Skeleton
                      loading={isLoadingTranscript}
                      active
                      title={true}
                      className="mt-2 mb-0"
                      paragraph={{ rows: 0 }}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="d-flex align-items-center g-2 gap-2 fw-bold themeBlue">
                          Primary Research Transcripts
                        </span>
                        <span className="result_override">
                          <Button
                            className="result_button themeButtons"
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                              justifyContent: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Request a custom transcript
                          </Button>
                        </span>
                      </div>
                    </Skeleton>
                  }
                  spendCredits={spendCredits}
                  data={
                    answerTranscript?.data
                      ? answerTranscript
                      : { data: [1, 2, 3, 4, 5, 6, 7, 8] }
                  }
                  type={"transcript"}
                  showMoreRight={showMoreRight}
                  itemkey={"1"}
                />
                {/* <PrimaryTranscript
                    loading={isLoading || loading}
                    cls="answer_table"
                    title={
                      <Skeleton loading={loading} active title={true} className="mt-2 mb-0" paragraph={{ rows: 0 }}>
                        <span>Secondary Research Reports</span>
                        <small className="text-muted ms-2">
                          (28 Secondary research reports found)
                        </small>
                      </Skeleton >
                    }
                    spendCredits={spendCredits}
                    type={"expert"}
                  /> */}
                <ExpertTable
                  loading={isLoading || isLoadingExpert}
                  data={
                    answerExpert?.data ? answerExpert : { data: [1, 2, 3, 4] }
                  }
                  cls="answer_table"
                  title={
                    <Skeleton
                      loading={isLoadingExpert}
                      active
                      title={true}
                      className="mt-2 mb-0"
                      paragraph={{ rows: 0 }}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="d-flex align-items-center g-2 gap-2 fw-bold themeBlue">
                          Experts
                        </span>
                        <span className="result_override">
                          <Button
                            className="result_button themeButtons"
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                              justifyContent: "center",
                              whiteSpace: "nowrap",
                            }}
                            onClick={() => {
                              dispatch(setIsOpenCreatProject(false));
                              dispatch(setIsOpenProject(true));
                              dispatch(setSelectedExpert({}));
                            }}
                          >
                            Find me Experts
                          </Button>
                        </span>
                      </div>
                    </Skeleton>
                  }
                  type={"experts"}
                  showMoreRight={showMoreRight}
                  itemkey={"2"}
                />
                <NewsTable
                  loading={isLoading || isLoadingNews}
                  data={
                    answerNews?.data
                      ? answerNews
                      : { data: [1, 2, 3, 4, 5, 6, 7, 8] }
                  }
                  title={
                    <Skeleton
                      loading={isLoadingNews}
                      active
                      title={true}
                      className="mt-2 mb-0"
                      paragraph={{ rows: 0 }}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="d-flex align-items-center g-2 gap-2 fw-bold themeBlue">
                          News
                        </span>
                        <span className="result_override">
                          {/* <Button className="result_button"
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                              justifyContent: "center",
                              whiteSpace: "nowrap",
                              cursor: "pointer",
                              fontSize: "12px",
                            }}

                          >
                            Find me News
                          </Button> */}
                        </span>
                      </div>
                    </Skeleton>
                  }
                  cls="answer_table"
                  type={"news"}
                  showMoreRight={showMoreRight}
                  itemkey={"3"}
                />

                {/* SECONDARY REPORTS */}
                <SecondaryReport
                  cls="answer_table"
                  loading={isLoading || isLoadingSecReport}
                  title={
                    <Skeleton
                      loading={isLoadingSecReport}
                      active
                      title={true}
                      className="mt-2 mb-0"
                      paragraph={{ rows: 0 }}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="d-flex align-items-center g-2 gap-2 fw-bold themeBlue">
                          Secondary Research Reports
                        </span>
                        <span className="result_override">
                          <Button
                            className="result_button themeButtons"
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                              justifyContent: "center",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Search Report
                          </Button>
                        </span>
                      </div>
                    </Skeleton>
                  }
                  spendCredits={spendCredits}
                  data={
                    answerSecReport?.data
                      ? answerSecReport
                      : { data: [1, 2, 3, 4, 5, 6, 7, 8] }
                  }
                  type={"transcript"}
                  showMoreRight={showMoreRight}
                  itemkey={"4"}
                />

                <CompaniesTable
                  loading={isLoading || isLoadingCompanies}
                  data={
                    answerCompanies?.data
                      ? answerCompanies
                      : { data: [1, 2, 3, 4, 5, 6, 7, 8] }
                  }
                  title={
                    <Skeleton
                      loading={isLoadingCompanies}
                      active
                      title={true}
                      className="mt-2 mb-0"
                      paragraph={{ rows: 0 }}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="d-flex align-items-center g-2 gap-2 fw-bold themeBlue">
                          Companies
                        </span>
                        <span className="result_override">
                          {/* <Button className="result_button"
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                              justifyContent: "center",
                              whiteSpace: "nowrap",
                              cursor: "pointer",
                              fontSize: "12px",
                            }}

                          >
                            Find me News
                          </Button> */}
                        </span>
                      </div>
                    </Skeleton>
                  }
                  cls="answer_table"
                  type={"companies"}
                  showMoreRight={showMoreRight}
                  itemkey={"5"}
                />



                {/* <ExpertAvatarCard title={"Expert Profiles"} loading={loading} /> */}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {/* </div> */}
      <ExpertDetailsModal />
      <CreateProjectModal
        buttonText="Add Project"
        mutateProjectList={mutateProjectList}
      />
      <ProjectListModal
        selectedExpertId={""}
        // selectedExpertId={selectedExpert?._id}
        country={""}
        // country={selectedExpert?.country}
        isLoading={isLoading}
        functions={{
          projectListById,
          AddExpertProject,
          getProjectListById,
          mutateProjectList
        }}
      />
      <SuccessModalNew />
    </>
  );
};

export default AnswerPage;
