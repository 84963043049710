import { useCallback, useMemo } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import useSWR from "swr";

import { setDataLibraryData, setUnlockedData, setLockedData, setCount, setDataLibraryPagination, setIsLoadingPagination } from "./DataLibrary.slice";
import API_URL from "../../../Constants/api-path";
import { useOnceCall } from "../../Hooks/useOnceCall";

function useDataLibrary(show_unlock = null) {
    const dispatch = useDispatch();
    const userData = JSON.parse(localStorage.getItem("userData"));
    const { count, dataLibraryPagination, data, searchVal } = useSelector((state) => state?.dataLibraryReducer);

    useOnceCall(() => {
        dispatch(setDataLibraryPagination({ page: 1, limit: 20 }))
    })
    const getDataLibraryData = useCallback(
        async (show_unlock = null) => {
            const res = await axios.get(
                API_URL.GET_DATA_LIBRARY_DATA +
                `?page=1&limit=20&user_id=${userData?.user?._id}${show_unlock !== null ? `&show_unlock=${show_unlock}` : ""
                }`, {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": userData.access_token,
                }
            }
            );
            return res.data;
        },
        [userData.access_token, userData?.user?._id]
    );



    const dataLibraryData = useSWR(
        ["data_library_data", show_unlock],
        () => getDataLibraryData(show_unlock),
        {
            revalidateOnMount: true,
            revalidateOnFocus: false,
            shouldRetryOnError: false,
            onSuccess: (data) => {
                console.log('data: ', data);

                if (show_unlock !== null && show_unlock) {
                    dispatch(setUnlockedData({ data: data?.data, count: data?.countData }));
                } else if (show_unlock !== null && !show_unlock) {
                    dispatch(setLockedData({ data: data?.data, count: data?.countData }));
                }
                dispatch(setDataLibraryData(data?.data));
                dispatch(setCount(data?.countData));
            },
            onError: (err) => {
                console.error("ERROR", err);
            },
        }
    );
    const { isLoading, isValidating, mutate } = dataLibraryData;

    const fetchDataLibraryData = useCallback(async ({ search = false, key = searchVal, paginate = false, page = dataLibraryPagination.page, limit = dataLibraryPagination.limit }) => {
        if (paginate) {
            dispatch(setIsLoadingPagination(true))
            dispatch(setDataLibraryPagination({ page: page, limit: limit }))
            await axios
                .get(
                    API_URL.GET_DATA_LIBRARY_DATA +
                    `?user_id=${userData?.user?._id}&search=${key}&page=${page}&limit=${limit}${show_unlock !== null ? `&show_unlock=${show_unlock}` : ""}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": userData.access_token,
                    }
                }
                )
                .then((res) => {
                    if (show_unlock !== null && show_unlock) {
                        dispatch(setUnlockedData({ data: res?.data?.data, count: res?.data?.countData }));
                    } else if (show_unlock !== null && !show_unlock) {
                        dispatch(setLockedData({ data: res?.data?.data, count: res?.data?.countData }));
                    }
                    dispatch(setDataLibraryData(res?.data?.data));
                    dispatch(setCount(res?.data?.countData));
                    dispatch(setIsLoadingPagination(false))
                })
                .catch((err) => {
                    dispatch(setIsLoadingPagination(false))
                    console.error("ERROR", err);
                });
        }

        if (search) {
            dispatch(setDataLibraryPagination({ page: 1, limit: 20 }))
            const res = await axios
                .get(
                    API_URL.GET_DATA_LIBRARY_DATA +
                    `?user_id=${userData?.user?._id}&page=${1}&limit=${20}&search=${key}${show_unlock !== null ? `&show_unlock=${show_unlock}` : ""}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": userData.access_token,
                    }
                }
                )
                .then((res) => {
                    if (show_unlock !== null && show_unlock) {
                        dispatch(setUnlockedData({ data: res?.data?.data, count: res?.data?.countData }));
                    } else if (show_unlock !== null && !show_unlock) {
                        dispatch(setLockedData({ data: res?.data?.data, count: res?.data?.countData }));
                    }
                    dispatch(setDataLibraryData(res?.data?.data));
                    dispatch(setCount(res?.data?.countData));
                })
                .catch((err) => {
                    console.error("ERROR", err);
                });
            return res?.data;
        }

    }, [dataLibraryPagination.limit, dataLibraryPagination.page, dispatch, searchVal, show_unlock, userData.access_token, userData?.user?._id]);

    const values = useMemo(
        () => ({
            fetchDataLibraryData: fetchDataLibraryData,
            isLoading: isLoading,
            isValidating: isValidating,
            data: data,
            count: count
            , mutate
        }),
        [
            fetchDataLibraryData,
            isLoading,
            data,
            count,
            isValidating, mutate
        ]
    );

    return values;
}
export default useDataLibrary;
