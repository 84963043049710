import {
    Tag,
    Tooltip,
    List,
    Pagination,
    Modal,
    Button,
} from "antd";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { PiCoinVerticalDuotone } from "react-icons/pi";
import Loader from "../../Components/Loader";
import EmptyStatesData from "../../Components/EmptyStates";
import axios from "axios";
import API_URL from "../../../Constants/api-path";
import { toast } from "react-toastify";
import useUser from "../../Hooks/useUser";

function LockedCompanyData({ functions, data, count, isLoading, isValidating }) {
    const {
        fetchDataLibraryData,
    } = functions;
    const { dataLibraryPagination, isLoadingPagination, searchVal } = useSelector(
        (state) => state.dataLibraryReducer
    );
    const userData = JSON.parse(localStorage.getItem("userData"));
    const permission = React.useMemo(
        () => userData?.user?.role_id,
        [userData?.user?.role_id]
    );
    const [hoveredItemId, setHoveredItemId] = React.useState(null);
    const [selectedCompany, setSelectedCompany] = React.useState({});
    const { getAvailableCredits } = useUser();


    const creditsData = useSelector((state) => state.user.all_credits);
    const [isOpen, setIsOpen] = React.useState(false);


    const capitalizeFirstLetter = React.useCallback((string) => {
        if (!string) return "";
        return string.charAt(0).toUpperCase() + string.slice(1);
    }, []);


    const headers = React.useMemo(
        () => ({
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.access_token}`,
        }),
        [userData?.access_token]
    );
    const getUnlockingStatus = React.useCallback(async () => {
        const res = await axios.get(
            API_URL.CHECK_UNLOCKING_STATUS +
            `?cloud_company_id=${selectedCompany?._id}&user_id=${userData?.user?._id}&cin=${selectedCompany?.cin}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userData?.access_token}`,
            },

        }
        );
        return res?.data;
    }, [selectedCompany?._id, selectedCompany?.cin, userData?.access_token, userData?.user?._id]);

    const handleUnlockFinancials = React.useCallback(async () => {
        try {
            const res = await axios.post(
                `${API_URL.REQUEST_FINANCIALS}`,
                {
                    cloud_company_id: selectedCompany?._id,
                    user_id: userData?.user?._id,
                    cin: selectedCompany?.cin,
                },
                { headers }
            );
            await getUnlockingStatus();
            fetchDataLibraryData({
                paginate: true,
                page: dataLibraryPagination?.page,
                limit: dataLibraryPagination?.limit,
                key: searchVal,
            })
            if (res?.status) {
                toast.success("Request granted!", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
                return res.status;
            }
        } catch (error) {
            return toast.error(error?.response?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
    }, [dataLibraryPagination?.limit, dataLibraryPagination?.page, fetchDataLibraryData, getUnlockingStatus, headers, searchVal, selectedCompany?._id, selectedCompany?.cin, userData?.user?._id]);
    const dataTrans = React.useMemo(() => EmptyStatesData?.companyTranscript, []);
    if (!dataTrans) return null;



    return (
        <>
            {isLoading || isValidating || isLoadingPagination ? (
                <>
                    <div
                        className="d-flex justify-content-center align-items-center text-center"
                        style={{ minHeight: "50vh" }}
                    >
                        <Loader />
                    </div>
                </>
            ) : data?.length > 0 && !isLoading && !isValidating ? (
                <div className="">
                    <List
                        size="small"
                        header={
                            <Row className="w-100 mt-1">
                                <Col lg={10}>
                                    <Row md={12}>
                                        <Col md={6}>
                                            <strong className="fw-bolder">Company Name</strong>
                                        </Col>
                                        <Col md={3}>
                                            <strong className="fw-bolder">Ticker Symbol</strong>
                                        </Col>
                                        <Col md={3}>
                                            <strong className="fw-bolder">Category</strong>
                                        </Col>

                                    </Row>
                                </Col>

                                <Col lg={2}>
                                    <Row md={12}>
                                        <Col md={6}>
                                            <strong className="fw-bolder">Listing Status</strong>
                                        </Col>
                                        <Col md={6}>
                                            <strong className="fw-bolder">Credits</strong>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        }
                        bordered
                        pagination={false}
                        dataSource={data}
                        renderItem={(curElem, index) => (
                            <Col xl={12} key={index}>
                                <List.Item className="border-bottom">
                                    <Row className="w-100">
                                        <Col xl={10} lg={9}>
                                            <Row md={12}>
                                                <Col md={6}>

                                                    <Tooltip
                                                        title={capitalizeFirstLetter(curElem?.name)}
                                                    >
                                                        <p
                                                            className="fw-bolder truncate-text mb-0"
                                                            style={{
                                                                fontSize: "12px",
                                                                cursor: permission?.transcript?.unlock
                                                                    ? "pointer"
                                                                    : "not-allowed",
                                                            }}

                                                        >
                                                            {" "}
                                                            {capitalizeFirstLetter(curElem?.name)}
                                                        </p>
                                                    </Tooltip>
                                                </Col>
                                                <Col md={3}>

                                                    <small className="fw-normal truncate-text pointer" >
                                                        {curElem?.expertData?.[0]?.current_designation ||
                                                            "NA"}
                                                    </small>
                                                </Col>
                                                <Col md={3}>

                                                    <small className="fw-normal truncate-text pointer" >
                                                        {curElem?.category ||
                                                            "NA"}
                                                    </small>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col xl={2} lg={3}>
                                            <Row md={12}>
                                                <Col md={6}>
                                                    <small className="fw-normal">
                                                        {curElem?.listing_status}
                                                    </small>
                                                </Col>

                                                <Col
                                                    className="d-flex justify-content-start align-items-center"
                                                    md={6}
                                                    onMouseEnter={() => setHoveredItemId(index)}
                                                    onMouseLeave={() => setHoveredItemId(null)}
                                                >
                                                    <>
                                                        {hoveredItemId === index &&
                                                            permission?.transcript?.unlock ? (
                                                            <Tag
                                                                bordered={false}
                                                                className="tagContainer unlock"
                                                                onClick={() => {
                                                                    setIsOpen(curElem);
                                                                    setSelectedCompany(curElem)
                                                                }}
                                                            >
                                                                Unlock
                                                            </Tag>
                                                        ) : (
                                                            <Tag
                                                                bordered={false}
                                                                className="tagContainer"
                                                                style={{
                                                                    borderColor: "rgb(76, 187, 23)",
                                                                    color: "rgb(76, 187, 23)",
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <PiCoinVerticalDuotone
                                                                        style={{ marginRight: "4px" }}
                                                                    />
                                                                    {creditsData?.credits_for_company}
                                                                </span>
                                                            </Tag>
                                                        )}
                                                    </>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </List.Item>
                            </Col>
                        )}
                        style={{ background: "#ffff" }}
                    ></List>
                    <Pagination
                        className="text-end mt-2"
                        size="small"
                        total={count}
                        current={dataLibraryPagination?.page}
                        pageSize={dataLibraryPagination?.limit}
                        onChange={(page, pageSize) => {
                            fetchDataLibraryData({
                                paginate: true,
                                page: page,
                                limit: pageSize,
                                key: searchVal,
                            });
                        }}
                        showSizeChanger
                    />

                    <Modal
                        centered
                        title={`Unlock ${selectedCompany?.name}`}
                        open={isOpen}
                        onCancel={() => setIsOpen(false)}
                        className={`${selectedCompany?.company_class === "Public" ? "unlock_modal" : ""
                            }`}
                        footer={[
                            <div
                                key="footer"
                                style={
                                    selectedCompany?.company_class === "Public"
                                        ? {
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            width: "100%",
                                        }
                                        : {}
                                }
                            >
                                {selectedCompany?.company_class === "Public" && (
                                    <>
                                        <p
                                            className="m-0 p-0 fw-normal text-muted"
                                            style={{ fontSize: "12px", flex: "1" }}
                                        >
                                            Data for publicly listed companies is free of charge
                                        </p>
                                    </>
                                )}
                                <div className="d-flex align-items-center justify-content-end">
                                    <Button
                                        key="back"
                                        onClick={() => setIsOpen(false)}
                                        style={{ marginRight: "10px" }}
                                    >
                                        Cancel
                                    </Button>
                                    <button
                                        key="submit"
                                        style={{
                                            backgroundColor: "rgb(132, 183, 64)",
                                            borderColor: "rgb(132, 183, 64)",
                                            color: "#fff",
                                            fontSize: "14px",
                                        }}
                                        className="d-flex align-items-center btn"
                                        onClick={async () => {
                                            await handleUnlockFinancials();
                                            setIsOpen(false);
                                            getAvailableCredits();
                                        }}
                                    >
                                        Unlock
                                        {selectedCompany?.company_class !== "Public" && (
                                            <>
                                                <PiCoinVerticalDuotone className="tag_icon" />
                                                0.5
                                            </>
                                        )}
                                    </button>
                                </div>
                            </div>,
                        ]}
                    >
                        {selectedCompany?.company_class !== "Public" && (
                            <p>
                                Unlocking the data will utilize{" "}
                                <span className="fw-bolder">0.5 Credits.</span>
                            </p>
                        )}
                        <p>
                            Once unlocked, you will have access to the updated data for{" "}
                            <span className="fw-bolder">1 year,</span> starting today.
                        </p>
                        <p>You will get access to all the tabs in the company pages.</p>
                    </Modal>


                </div>
            ) : (
                <>
                    <Row>
                        <Col md={12}>
                            <div
                                className="empty-state-container"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100%",
                                }}
                            >
                                <div
                                    className="image-container"
                                    style={{ flex: 1, textAlign: "center" }}
                                >
                                    <img
                                        src={dataTrans?.image}
                                        alt={dataTrans?.feature}
                                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                                    />
                                </div>
                                <div
                                    className="content-container"
                                    style={{ flex: 1, textAlign: "left" }}
                                >
                                    <p
                                        className="p-0 mt-0 pt-0 mb-3"
                                        style={{ lineHeight: "28px", fontWeight: "300" }}
                                    >
                                        {dataTrans?.content}
                                    </p>
                                    <button
                                        className="btn viewButton findme-btn"
                                        style={{ fontSize: "12px" }}

                                    >
                                        {dataTrans?.cta}
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
}

export default LockedCompanyData;
