import Aos from "aos";
import "aos/dist/aos.css";
import React from "react";
import { NavLink } from "react-router-dom";
import "./Login.css";
import LoginForm from "./LoginForm";
import nextyn_logo from "../assests/images/Nextyn_logo.png";
import Nextyn_IQ_Black from "../assests/images/Nextyn_IQ_Black.png";
// import nextyn_logo_24c from "../assests/images/nextyn_logo_24c.png";
// import nextyn_logo_24b from "../assests/images/nextyn_logo_24b.png";
// import nextyn_logo2 from "../assests/images/Nextyn_logo.png";
import sliderImg1 from "../assests/images/cmpny.png";
import sliderImg2 from "../assests/images/transcript.png";
import sliderImg3 from "../assests/images/en.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
function Login() {
  Aos.init({
    offset: 100,
    easing: "ease",
    delay: 0,
    once: true,
    duration: 800,
  });

  document.title = "Nextyn IQ - Login";
  return (
    <>
      <div className="login-page">
        <div className="container-fluid">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col d-none d-lg-block">
              <div className="login-left-part">
                <NavLink to="/" className="logo">
                  <img src={nextyn_logo} alt="logo" />
                  {/* <img src={nextyn_logo_24c} alt="logo" /> */}
                </NavLink>
                <div className="logo-in-div col-lg-8 mx-auto">
                  <h1
                    className="text-left text-white loginText"
                    data-aos="fade-down"
                  >
                    The easiest way for businesses to collaborate with India's
                    leading experts, access a library of expert call transcripts
                    and private & public company data.
                  </h1>
                </div>

                <div className="col-lg-8 mx-auto">
                  {/* <div className="img-box-1 mt-4">
                    <img src="images/Login.gif"></img>
                  </div> */}
                  <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    className="authenticationSlider"
                    loop={true}
                    scrollbar={true}
                    pagination={{
                      clickable: true,
                    }}
                    autoplay={{
                      delay: 3000,
                      disableOnInteraction: false,
                    }}
                    // onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                    modules={[Pagination, Autoplay]}
                  >
                    <SwiperSlide>
                      <img src={sliderImg1} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={sliderImg2} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={sliderImg3} />
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>

            <div
              className="position-relative d-flex align-items-center justify-content-center"
              style={{ overflowY: "scroll" }}
            >
              <div className="comon-fild col-lg-8">
                <div className="inside-login">
                  <NavLink to="/" className="logo d-block d-lg-none mb-5">
                    {/* <img src={nextyn_logo2} alt="logo" /> */}
                    <img src={Nextyn_IQ_Black} alt="logo" />
                  </NavLink>
                  <h3>Login</h3>
                  <p style={{ color: "#5746ec" }}>You're just one step away!</p>
                  <LoginForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Login;
