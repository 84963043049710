import { Button, Divider, Tag, Tooltip } from 'antd';
import React, { memo } from 'react'
import { convertToTitleCase } from '../../Utils/Util';
import ConditionalRender from '../../Utils/ConditionalRender';
import { LoadingOutlined } from '@ant-design/icons';

const SecReportPopOver = ({ curElem, summary, loadingsummary }) => {
    const [showAll, setShowAll] = React.useState(false);
    const toggleShowAll = React.useCallback((id) => {
        setShowAll((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    }, []);
    const capitalizeFirstLetter = React.useCallback((string) => {
        if (!string) return "";
        return string.charAt(0).toUpperCase() + string.slice(1);
    }, []);
    return (
        <>
            <div className="trans_popup">
                <span
                    className="truncate-text fw-bolder"
                    style={{
                        fontSize: "14px",
                        color: "#5746ec",
                    }}
                >
                    <Tooltip title={capitalizeFirstLetter(curElem?.title)} color="#5746EB">
                        {capitalizeFirstLetter(curElem?.title)}
                    </Tooltip>
                </span>
                <Divider></Divider>

                <div className="d-flex cstmTg">
                    <div
                        style={{
                            flexBasis: "70px",
                            flexGrow: 0,
                            flexShrink: 0,
                        }}
                    >
                        {curElem?.keyword?.length > 1 && (
                            <>
                                <small className="fw-bolder">Keywords</small>
                            </>
                        )}
                    </div>
                    <div>
                        {curElem?.keyword
                            ?.slice(0, 6)
                            ?.map((tag) => (
                                <>
                                    {tag && (
                                        <Tag className="me-1 mb-1" size="large" key={tag}>
                                            {tag}
                                        </Tag>
                                    )}
                                </>
                            ))}
                        {curElem?.keyword &&
                            curElem?.keyword?.slice(6)?.length > 0 && (
                                <Tooltip
                                    title={curElem?.keyword?.slice(6)?.join(", ")}
                                    color="#5746EB"
                                >
                                    <Tag className="me-1 mb-1" size="large">
                                        {`+ ${curElem?.keyword?.slice(6)?.length}`}
                                    </Tag>
                                </Tooltip>
                                // </Popover>
                            )}
                    </div>
                </div>


                <Divider></Divider>
                <strong style={{ fontWeight: "500", fontSize: "12px" }}>
                    Report Summary
                </strong>
                {loadingsummary ? <div className='d-flex align-items-center justify-content-center' style={{ height: 100 }}>
                    <LoadingOutlined className='me-2' /> Loading Summary..
                </div> : <>
                    <ul className="listType mt-2 mb-0">
                        {summary?.slice(0, showAll[curElem?._id] ? undefined : 5)
                            ?.map((question, index) => (
                                <li className="fw-normal mb-0" key={index}>
                                    {question?.replace(/-/g, "")}
                                </li>
                            ))}
                    </ul>
                    {summary?.length > 5 && (
                        <Button
                            className="text-start p-0"
                            type="link"
                            block
                            onClick={() => toggleShowAll(curElem?._id)} // Assuming curElem.id is the correct identifier for each item
                            style={{ fontSize: "12px", color: "rgb(87, 70, 236)" }}
                        >
                            {showAll[curElem?._id] ? "View Less" : "View More"}
                        </Button>
                    )}
                </>}



                <Divider className="mt-1"></Divider>

                <div className="mt-1 d-flex cstmTg">
                    <div
                        style={{
                            flexBasis: "70px",
                            flexGrow: 0,
                            flexShrink: 0,
                        }}
                    >
                        {curElem?.industry?.length > 0 && (
                            <>
                                <small className="fw-bolder">Industries</small>
                            </>
                        )}
                    </div>
                    <div>
                        {curElem?.industry
                            ?.slice(0, 6)
                            ?.map((indus) => (
                                <>
                                    {indus && (
                                        <Tag
                                            className="me-1 mb-1"
                                            size="large"
                                            key={indus}
                                        >
                                            {convertToTitleCase(indus)}
                                        </Tag>
                                    )}
                                </>
                            ))}
                        {curElem?.industry &&
                            curElem?.industry?.slice(6)?.length >
                            0 && (
                                <Tooltip
                                    title={curElem?.industry
                                        ?.slice(6)
                                        ?.map((comp) => comp)?.join(", ")}
                                    color="#5746EB"
                                >
                                    <Tag className="me-1 mb-1" size="large">
                                        {`+ ${curElem?.industry?.slice(6)
                                            ?.length
                                            }`}
                                    </Tag>
                                </Tooltip>
                            )}
                    </div>
                </div>
                <div className="mt-1 d-flex cstmTg">
                    <div
                        style={{
                            flexBasis: "70px",
                            flexGrow: 0,
                            flexShrink: 0,
                        }}
                    >
                        {curElem?.sub_industry?.length > 0 && (
                            <>
                                <small className="fw-bolder">Sub Industries</small>
                            </>
                        )}
                    </div>
                    <div>
                        {curElem?.sub_industry
                            ?.slice(0, 6)
                            ?.map((indus) => (
                                <>
                                    {indus && (
                                        <Tag
                                            className="me-1 mb-1"
                                            size="large"
                                            key={indus}
                                        >
                                            {convertToTitleCase(indus)}
                                        </Tag>
                                    )}
                                </>
                            ))}
                        {curElem?.sub_industry &&
                            curElem?.sub_industry?.slice(6)?.length >
                            0 && (
                                <Tooltip
                                    title={curElem?.sub_industry
                                        ?.slice(6)
                                        ?.map((comp) => comp)?.join(", ")}
                                    color="#5746EB"
                                >
                                    <Tag className="me-1 mb-1" size="large">
                                        {`+ ${curElem?.sub_industry?.slice(6)
                                            ?.length
                                            }`}
                                    </Tag>
                                </Tooltip>
                            )}
                    </div>
                </div>

                <Divider></Divider>
                <div className="d-flex justify-content-between align-items-center">
                    <ConditionalRender condition={curElem?.country && curElem?.country.length > 0}>
                        <small className="fw-normal">
                            <strong className="fw-bolder me-4">Countries</strong>{" "}
                            <span>
                                {curElem?.country?.map((cou) => <Tag className="me-1 mb-1" size="large" key={cou}>
                                    {cou}
                                </Tag>)}
                            </span>
                        </small>
                    </ConditionalRender>
                </div>

                <div className="d-flex justify-content-between align-items-center">
                    <ConditionalRender condition={curElem?.country && curElem?.country.length > 0}>
                        <small className="fw-normal">
                            <strong className="fw-bolder me-4">Regions</strong>{" "}
                            <span>
                                {curElem?.region?.map((reg) => <Tag className="me-1 mb-1" size="large" key={reg}>
                                    {reg}
                                </Tag>)}
                            </span>
                        </small>
                    </ConditionalRender>
                </div>
            </div>

        </>
    )
}
export default memo(SecReportPopOver) 