const ENV = {
  // BASE_URL: "http://localhost:8081/api",

  // Production
  // BASE_URL: "https://api.cloud.nextyn.com/api",
  // BASE_URL_PLAT: "https://api.platform.nextyn.com",

  // development
  BASE_URL: "https://dev.api.cloud.nextyn.com/api",
  BASE_URL_PLAT: "https://dev.api.platform.nextyn.com",
  CRYPTO_JS_KEY: "Key123!@C2024R18Y10",
  SERP_API_KEY: "d040d5407c2b1d6ef42be961361494a3abd7d57fc7226a86f6086088866200c7"

};

export default ENV;
