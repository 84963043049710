import {
  Dropdown,
  Input,
  Popover,
  Space,
  Menu,
  Modal,
  Tag,
  Button,
  Tooltip,
} from "antd";
import React, { useState, useMemo, useRef, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  clearState,
  setSelectedArticle as setSelectedArticleNews,
} from "../Pages/Dashboard/news.slice";
import { useDispatch, useSelector } from "react-redux";
import useUser from "../Hooks/useUser";
import { PiCoinVerticalDuotone } from "react-icons/pi";
import company_icon3 from "../../assests/images/company_notfound.png";
import "./Sidebar.css";
import useCompanySearch from "../Pages/V2/CompanyPage/useCompanySearch";
import _ from "lodash";
import DOMPurify from "dompurify";
import {
  resetAll,
  setExpertProfile,
  setInputValue,
  setSelectedArticle,
  setSelectedExpert,
  setSelectedItem,
} from "../Pages/V2/SearchPage/SearchPage.slice";
import {
  setChecked,
  setQuartarlyTabs,
  setSelectedNewsSearch,
  setSelectedTranscriptSearch,
  setTab,
} from "../Pages/V2/CompanyPage/companySearch.slice";
import { CreateProjectModal } from "../../components/CreateProjectModal";
import {
  setIsOpenCreatProject,
  setIsOpenProject,
} from "../Pages/V2/Dashboard/Project.Slice";
import { ProjectListModal } from "../../components/ProjectListModal";
import useProject from "../Hooks/useProject";
import { Col, Row } from "react-bootstrap";
import {
  resetAllCompanyData,
  setActiveTab,
} from "../Pages/V2/CompanyPage/CompanyTab.Slice";
import Loader from "../Components/Loader";
import Avatar from "@mui/material/Avatar";
import { blue } from "@mui/material/colors";
import { setSelectedExpert as setSelectedExpertProject } from "../Pages/ExpertPage/Expert.Slice";
import {
  HiOutlineCog8Tooth,
  HiOutlineCreditCard,
  HiOutlineLockClosed,
} from "react-icons/hi2";
import { RiLogoutCircleLine } from "react-icons/ri";
import moment from "moment";
import { authActions } from "../../Login/auth.slice";

function Header({ cls }) {
  const [arrow] = useState("Show");
  const [greeting, setGreeting] = useState("");
  const { searchedCompanyList } = useSelector((state) => state.companySearch);
  const { isLoadingSearchBox } = useSelector((state) => state.SearchPage);
  const { available_credits } = useSelector((state) => state.user);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const permission = useMemo(
    () => userData?.user?.role_id,
    [userData?.user?.role_id]
  );
  const dispatch = useDispatch();

  useUser();
  const { selectedExpert } = useSelector((state) => state.expert);
  const { projectListById } = useSelector((state) => state.project);

  const { AddExpertProject, getProjectListById, isLoading, mutateProjectList } =
    useProject();
  const { search } = useCompanySearch();
  const navigate = useNavigate();
  const mergedArrow = useMemo(() => {
    if (arrow === "Hide") {
      return false;
    }
    if (arrow === "Show") {
      return true;
    }
    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  const [visible, setVisible] = useState(false);

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const handleCancel = React.useCallback(() => {
    setIsModalOpen(false);
  }, []);

  useEffect(() => {
    const currentTime = moment().format("HH:mm");
    const currentHour = parseInt(currentTime.split(":")[0], 10);

    if (currentHour >= 0 && currentHour < 12) {
      setGreeting("Good morning");
    } else if (currentHour >= 12 && currentHour < 16) {
      setGreeting("Good afternoon");
    } else {
      setGreeting("Good evening");
    }
  }, []);

  const userInfo = (
    <>
      <div className="d-flex align-items-center popover-userInfo">
        {/* <Avatar
          style={{ backgroundColor: "#fff", color: "#5746ec", border: "1px solid #5746ec", cursor: "pointer" }}
          sx={{
            bgcolor: blue[500],
            fontSize: "15px",
            margin: "auto",
          }}
        >
          {userData?.user?.name
            ? userData?.user?.name.split(" ")[0]
              ?.split('')
              ?.map((char, index) => index < 2 ? char?.toUpperCase() : '')
              ?.join('')
            : ''}
        </Avatar> */}
        <div>
          <h5 className="mb-0">{userData?.user?.name}</h5>
          <small>{userData?.user?.email}</small>
        </div>
      </div>
    </>
  );
  const handleLogout = () => {
    // localStorage.removeItem("userData");
    // localStorage.removeItem("isAuth");
    // localStorage.removeItem("persist:root"); // remove persisted state
    // dispatch(clearState); // reset to initial state
    dispatch(authActions.logout());
    navigate("/");
  };

  const content = (
    <div className="popover-content">
      <div className="mt-2">
        <Link to="/profile" className="popover-link d-flex align-items-center">
          <Space>
            <HiOutlineCog8Tooth style={{ fontSize: "18px" }} />
            Account Setting
          </Space>
        </Link>
      </div>
      {/* <div className="mt-2">
        <Link to="/notification" className="popover-link">
          <Space>
            <i className="bx bx-bell nav_icon"></i>Notification
          </Space>
        </Link>
      </div> */}
      <div className="mt-2">
        <Link to="/change-password" className="popover-link">
          <Space>
            <HiOutlineLockClosed style={{ fontSize: "18px" }} />
            Change Password
          </Space>
        </Link>
      </div>
      {(permission?.credits?.transaction || permission?.credits?.history) && (
        <div className="mt-2">
          <Link to="/credits" className="popover-link">
            <Space>
              <HiOutlineCreditCard style={{ fontSize: "18px" }} />
              Credits
            </Space>
          </Link>
        </div>
      )}
      <div className="mt-2">
        <div
          onClick={() => setIsModalOpen(true)}
          className="popover-link"
          style={{ cursor: "pointer" }}
        >
          <Space>
            <RiLogoutCircleLine style={{ fontSize: "18px" }} />
            Log Out
          </Space>
        </div>
      </div>
    </div>
  );

  const { inputValue } = useSelector((state) => state?.SearchPage);

  const handleVisibilityChange = (flag) => {
    setVisible(flag);
    let clss = document.getElementById("backDropCls");
    let hdr = document.getElementById("header");
    let nvbr = document.getElementById("nav-bar");
    flag
      ? clss.classList.add("ant-modal-mask")
      : clss.classList.remove("ant-modal-mask");
    flag ? hdr.classList.add("active") : hdr.classList.remove("active");
    flag ? nvbr.classList.add("active") : nvbr.classList.remove("active");
  };

  const handleInputChange = useMemo(
    () =>
      _.debounce((e) => {
        const cleanInput = DOMPurify.sanitize(e.target.value);
        if (cleanInput?.trim()) {
          search({ searchString: cleanInput?.trim() });
        }
      }, 1000),
    [search]
  );

  const clearInput = () => {
    dispatch(setInputValue(""));
    search({ searchString: "" });
  };

  const suffixIcon = inputValue ? (
    <svg
      onClick={clearInput}
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: "pointer" }}
    >
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
    </svg>
  ) : (
    <svg
      stroke="currentColor"
      fill="currentColor"
      stroke-width="0"
      viewBox="0 0 512 512"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M256 228.719c-22.879 0-41.597 18.529-41.597 41.18 0 22.652 18.718 41.182 41.597 41.182 22.878 0 41.597-18.529 41.597-41.182 0-22.651-18.719-41.18-41.597-41.18zm124.8 41.179c0-67.946-56.163-123.539-124.8-123.539s-124.8 55.593-124.8 123.539c0 45.303 24.961 85.447 62.396 107.072l20.807-36.032c-24.972-14.417-41.604-40.153-41.604-71.04 0-45.295 37.433-82.358 83.201-82.358 45.771 0 83.201 37.063 83.201 82.358 0 30.887-16.633 56.623-41.604 71.04l20.807 36.032c37.433-21.624 62.396-61.769 62.396-107.072zM256 64C141.597 64 48 156.654 48 269.898 48 346.085 89.592 411.968 152 448l20.799-36.032c-49.919-28.824-83.207-81.324-83.207-142.069 0-90.593 74.891-164.718 166.408-164.718 91.517 0 166.406 74.125 166.406 164.718 0 60.745-33.284 114.271-83.205 142.069L360 448c62.406-36.032 104-101.915 104-178.102C464 156.654 370.403 64 256 64z"></path>
    </svg>
  );
  const highlightText = (text) => {
    const index = text.toLowerCase().indexOf(inputValue.toLowerCase());
    if (index !== -1) {
      const beforeHighlight = text.substring(0, index);
      const highlightedText = text.substring(index, index + inputValue.length);
      const afterHighlight = text.substring(index + inputValue.length);
      return (
        <>
          {beforeHighlight}
          <span className="highlight">{highlightedText}</span>
          {afterHighlight}
        </>
      );
    }
    return text;
  };

  const getClass = (comp_class) => {
    if (comp_class === "Private" || comp_class === "Private Company") {
      return "tag-private";
    } else {
      return "tag-public";
    }
  };

  const menu = (
    <Menu style={{ maxHeight: "300px", overflowY: "scroll" }}>
      <Menu.Item
        onClick={() => {
          // if (inputValue !== searchParams.get("keyword")) {
          //   dispatch(resetAll())
          // }
          console.log("inputValue: ", inputValue);
          if (inputValue?.trim()) {
            dispatch(setSelectedExpert({}));
            dispatch(setExpertProfile([]));
            dispatch(setSelectedItem({}));
            dispatch(setSelectedArticleNews({}));
            dispatch(setSelectedArticle({}));
            handleVisibilityChange(!visible);
            navigate(`/search?keyword=${inputValue}`);
            dispatch(setInputValue(""));
          }
        }}
      >
        <span className="fw-bolder">All results - {inputValue}</span>
      </Menu.Item>
      <Menu.Divider />
      {searchedCompanyList?.data?.length > 0 && !isLoadingSearchBox ? (
        searchedCompanyList?.data?.slice(0, 15)?.map((company, index) => (
          <Menu.Item
            key={index}
            onClick={() => {
              handleVisibilityChange(!visible);
              dispatch(resetAllCompanyData());
              dispatch(setActiveTab("About"));
              dispatch(setSelectedArticleNews({}));
              dispatch(setSelectedNewsSearch({}));
              dispatch(setSelectedTranscriptSearch({}));
              // dispatch(setInputValue("company?.name"));// will remove if not to store name  x
              dispatch(setInputValue("")); // will remove if not to store name  x
              dispatch(setTab("1"));
              dispatch(setChecked(false));
              dispatch(setQuartarlyTabs());

              navigate(`/company?company_id=${company?._id}`);
            }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <img
                  src={company?.logo_url || company_icon3}
                  alt="Company Icon"
                  style={{ width: "20px", height: "20px", marginRight: "8px" }}
                />
                <span className="company-name">
                  {highlightText(company?.name)}
                </span>
              </div>
              <div>
                {company?.bse_scrip_id && (
                  <span className="company-name me-3">
                    <Tag
                      rounded
                      className="fw-normal"
                      color={"#f8f8f8"}
                      style={{
                        borderRadius: "4px",
                        fontSize: "12px",
                        color: "#5746ec",
                        padding: "0 7px",
                      }}
                    >
                      {company?.bse_scrip_id}
                    </Tag>
                  </span>
                )}
                <span className="company-name">
                  <Tag
                    className={`me-1 ${getClass(company?.company_class)}`}
                    size="large"
                  >
                    {company?.company_class}
                  </Tag>
                </span>
              </div>
            </div>
          </Menu.Item>
        ))
      ) : isLoadingSearchBox ? (
        <Menu.Item key="999">
          <Loader />
        </Menu.Item>
      ) : (
        searchedCompanyList?.data?.length === 0 &&
        !isLoadingSearchBox && (
          <Menu.Item key="999">
            <span className="">No Company Found</span>
          </Menu.Item>
        )
      )}
    </Menu>
  );

  const [searchParams] = useSearchParams();
  const onceCalled = useRef(false);

  const searchBar = () => {
    return (
      <form
        autoComplete="off"
        name="autocomplete-off"
        onSubmit={(e) => {
          e.preventDefault();
          console.log("here");
          let trimValue = inputValue.trim();
          if (trimValue) {
            if (trimValue !== searchParams.get("keyword")) {
              dispatch(resetAll());
            }
            dispatch(setExpertProfile([]));
            dispatch(setSelectedExpert({}));
            dispatch(setExpertProfile([]));
            dispatch(setSelectedItem({}));
            dispatch(setSelectedArticleNews({}));
            dispatch(setSelectedArticle({}));
            handleVisibilityChange(!visible);
            dispatch(setInputValue(""));
            navigate(`/search?keyword=${trimValue}`);
          }
        }}
      >
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          autoFocus={false}
          open={visible} // Show dropdown only if there's input
          onVisibleChange={(open) => {
            setVisible(open);
          }}
        >
          <Input
            placeholder="Search company, ticker symbol or keyword"
            size="large"
            className="search-input w-100"
            // onPressEnter={(e) => {
            //   let trimValue = e.target.value.trim();
            //   if (trimValue) {
            //     if (trimValue !== searchParams.get("keyword")) {
            //       dispatch(resetAll());
            //     }
            //     dispatch(setExpertProfile([]));
            //     dispatch(setSelectedExpert({}));
            //     dispatch(setExpertProfile([]));
            //     dispatch(setSelectedItem({}));
            //     dispatch(setSelectedArticleNews({}));
            //     dispatch(setSelectedArticle({}));
            //     handleVisibilityChange(!visible);
            //     dispatch(setInputValue(""));
            //     navigate(`/search?keyword=${trimValue}`);
            //   }
            // }}
            onFocus={(e) => {
              if (!onceCalled.current) {
                onceCalled.current = true;
                search({ searchString: e.target.value });
              } else {
                if (inputValue) {
                  search({ searchString: inputValue });
                } else {
                  search({ searchString: "" });
                }
              }
            }}
            name="search-company"
            autoFocus={false}
            autoCorrect={false}
            type="text"
            autoComplete="off"
            value={inputValue}
            onChange={(e) => {
              if (!visible) {
                setVisible(true);
              }
              const cleanInput = DOMPurify.sanitize(e.target.value);
              dispatch(setInputValue(cleanInput));
              handleInputChange(e);
            }}
            suffix={suffixIcon}
          />
        </Dropdown>
        <button
          style={{
            display: "none",
          }}
          type="submit"
        ></button>
      </form>
    );
  };

  const findMeExpert = () => {
    return (
      <div
        className="me-3 credits-info-filled text-center"
        style={{
          borderWidth: "1px",
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
          whiteSpace: "nowrap",
          cursor: "pointer",
          color: "white",
          visibility: permission?.projects.add ? "" : "hidden",
        }}
        onClick={() => {
          dispatch(setIsOpenCreatProject(true));
          dispatch(setIsOpenProject(false));
          dispatch(setSelectedExpertProject({}));
          // closeProjectModal(false)
        }}
      >
        Find me Experts
      </div>
    );
  };

  return (
    <>
      <header className={cls ? "header headerExtraCls" : "header"} id="header">
        <Row className="w-100 g-1 align-items-center">
          <Col lg={8}>
            {!cls ? (
              <>
                {
                  // searchBar()
                }
              </>
            ) : (
              <p className="fs-5" style={{ color: "#fff" }}>
                {greeting}, {userData?.user?.name?.split(" ")[0]}
              </p>
            )}
          </Col>
          <Col lg={4}>
            <div>
              {/* <div
              className="me-2 credits-info text-center"
              style={{
                borderWidth: "1px",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(setSelectedExpert({}));
                dispatch(setExpertProfile([]));
                dispatch(setSelectedItem({}));
                dispatch(setSelectedArticleNews({}));
                dispatch(setSelectedArticle({}));
                navigate(`/search?keyword=${inputValue}`);
              }}
            >
              Search
            </div> */}
              <div className="d-flex justify-content-end">
                {findMeExpert()}
                <div
                  className="me-3 credits-info text-center"
                  style={{
                    borderWidth: "1px",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    permission?.credits?.transaction && navigate(`/credits`)
                  }
                >
                  <Tooltip title="Available Credits">
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <PiCoinVerticalDuotone style={{ marginRight: "4px" }} />
                      {available_credits !== null &&
                      available_credits !== undefined
                        ? Number.isInteger(available_credits)
                          ? available_credits
                          : available_credits.toFixed(1)
                        : "0"}
                    </span>
                  </Tooltip>
                </div>
                <div className="header_img">
                  <Popover
                    placement="bottomRight"
                    overlayClass={"sdff"}
                    title={userInfo}
                    content={content}
                    arrow={mergedArrow}
                    trigger={"click"}
                  >
                    <Avatar
                      style={{
                        backgroundColor: "#fff",
                        color: "#5746ec",
                        border: "1px solid #5746ec",
                        cursor: "pointer",
                      }}
                      sx={{
                        bgcolor: blue[500],
                        fontSize: "15px",
                        margin: "auto",
                      }}
                    >
                      {userData?.user?.name
                        ? userData?.user?.name
                            .split(" ")[0]
                            ?.split("")
                            ?.map((char, index) =>
                              index < 2 ? char?.toUpperCase() : ""
                            )
                            ?.join("")
                        : ""}
                    </Avatar>
                  </Popover>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </header>
      <ProjectListModal
        selectedExpertId={selectedExpert?._id}
        country={selectedExpert?.country}
        isLoading={isLoading}
        functions={{
          projectListById,
          AddExpertProject,
          getProjectListById,
          mutateProjectList,
        }}
      />
      <CreateProjectModal
        buttonText="Add Project"
        mutateProjectList={mutateProjectList}
      />
      <div id="backDropCls"></div>
      <Modal
        centered
        title="Log Out"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            style={{
              backgroundColor: "rgb(76, 187, 23)",
              borderColor: "rgb(76, 187, 23)",
              color: "#fff",
            }}
            onClick={async () => {
              handleLogout();
            }}
          >
            Log Out
          </Button>,
        ]}
      >
        <p>Are you sure you want to logout from the current session</p>
      </Modal>

      {cls && (
        <div class="dashboardSearchBar d-flex align-items-center">
          <div style={{ width: "100%", marginRight: "10px" }}>
            {/* {searchBar()} */}
          </div>
          {/* <div> {findMeExpert()}</div> */}
        </div>
      )}
    </>
  );
}

export default Header;
