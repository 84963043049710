import axios from 'axios'
import { useCallback, useMemo } from 'react'
import API_URL from '../../Constants/api-path'
import { useDispatch, useSelector } from 'react-redux'
import { setIsLoadingDescription, setLimit, setPage, setSelectedArticle, updateNewsData } from '../Pages/Dashboard/news.slice'
import useSWR from 'swr'

function useNews(cstm_limit = 10) {
    const dispatch = useDispatch()
    const { news_data, limit, page } = useSelector((state) => state.news);

    const userData = JSON.parse(localStorage.getItem("userData"));
    const getNewsData = useCallback(async ({ page_no, page_limit }) => {
        const response = await axios.get(API_URL.GET_FINANCE_NEWS + `?page=${page_no}&limit=${page_limit}&country=${userData?.user?.country?.toLowerCase()}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userData?.access_token}`,
            },
        })
        // .then((res) => {
        //     let obj = { data: res?.data?.data, count: res?.data?.count }
        //     dispatch(updateNewsData(obj))
        // }).catch((err) => {
        //     console.error("ERROR", err)
        // })
        return response.data
    }, [userData?.access_token, userData?.user?.country])


    const newsData = useSWR("get_news_data", () => getNewsData({ page_no: 1, page_limit: cstm_limit }), {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (data) => {
            if (data?.data?.length > 0) {
                dispatch(setSelectedArticle(data?.data?.[0]));
            }
            dispatch(setLimit(cstm_limit))
            let obj = { data: data?.data, count: data?.count }
            dispatch(updateNewsData(obj))//for selecting the default one
        }
    })
    const { isLoading, isValidating } = newsData
    const getNews = useCallback(async ({ page_no, page_limit, should_paginate = false }) => {
        if (should_paginate) {
            dispatch(setPage(page_no))
            dispatch(setLimit(page_limit))
        }
        await axios.get(API_URL.GET_FINANCE_NEWS + `?page=${page_no}&limit=${page_limit}&country=${userData?.user?.country?.toLowerCase()}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userData?.access_token}`,
            },
        })
            .then((res) => {
                let obj = { data: res?.data?.data, count: res?.data?.count }
                dispatch(updateNewsData(obj))
            }).catch((err) => {
                console.error("ERROR", err)
            })
    }, [dispatch, userData?.access_token, userData?.user?.country])


    const getNewsAiSummary = useCallback(async ({ news = {} }) => {
        dispatch(setIsLoadingDescription(true))
        await axios.get(API_URL.GET_FINANCE_NEWS_AI_SUMMARY + `?news_id=${news?._id}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.access_token}`,
                },
            })
            .then((res) => {
                let article = { ...news, chatgpt_desc: res?.data?.data }
                dispatch(setSelectedArticle(article))
                dispatch(setIsLoadingDescription(false))
            }).catch((err) => {
                dispatch(setIsLoadingDescription(false))
                console.error("ERROR", err)
            })
    }, [dispatch, userData?.access_token])


    const getNewsInfinite = useCallback(async ({ page_no = page, page_limit = limit, should_paginate = false }) => {
        if (should_paginate) {
            dispatch(setPage(page_no + 1))
            dispatch(setLimit(page_limit))
        }
        let index = page_no + 1
        await axios.get(API_URL.GET_FINANCE_NEWS + `?page=${index}&limit=${page_limit}&country=${userData?.user?.country?.toLowerCase()}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userData?.access_token}`,
            },
        })
            .then((res) => {
                let obj = { data: [...news_data?.data, ...res?.data?.data], count: res?.data?.count }
                dispatch(updateNewsData(obj))
            }).catch((err) => {
                console.error("ERROR", err)
            })
    }, [dispatch, limit, news_data?.data, page, userData?.access_token, userData?.user?.country])

    const loadMore = useCallback(() => {
        setTimeout(async () => await getNewsInfinite({ should_paginate: true })
            , 2000);

    }, [getNewsInfinite]);


    const hasMore = useMemo(() => {
        return news_data?.data?.length < news_data?.count;
    }, [news_data?.count, news_data?.data?.length]);


    const handleItemClick = useCallback((data) => {
        dispatch(setSelectedArticle(data));
        getNewsAiSummary({ news: data })
    }, [dispatch, getNewsAiSummary]);




    const values = useMemo(() => ({
        getNews, getNewsAiSummary, isLoading, handleItemClick, news_data, hasMore, loadMore, isValidating
    }), [getNews, getNewsAiSummary, isLoading, handleItemClick, news_data, hasMore, loadMore, isValidating])

    return values
}

export default useNews