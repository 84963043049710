import axios from 'axios'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useSWR from 'swr'
import API_URL from '../../Constants/api-path'
import { useSearchParams } from 'react-router-dom'
import { setExpertList, setExpertPagination, setExpertProfile, setIsLoadingContent, setIsLoadingDescription, setIsLoadingExpertSummary, setLimit, setNewsPagination, setSearchKeyworkdData, setSelectedArticle, setSelectedCompanyType, setSelectedExpert, setSelectedItem, setSelectedPostDate, setTranscriptData, setTranscriptPagination, updateNewsData } from '../Pages/V2/SearchPage/SearchPage.slice'
import { useOnceCall } from './useOnceCall'
import API_PATH from '../../Constants/api-path'
import { toast } from 'react-toastify'
import { setToggle } from '../Pages/V2/Dashboard/dashboard.slice'
function useSearchPage(activeTab) {
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()
    const userData = JSON.parse(localStorage.getItem("userData"));
    const permission = React.useMemo(() => userData?.user?.role_id, [userData?.user?.role_id])
    const { selectedPostDate, selectedCompanyType, expertprofile, transcriptPagination, expertPagination, newsPagination, transcriptData, expertList } = useSelector((state) => state.SearchPage)
    const cstm_limit = 20
    useOnceCall(() => {
        dispatch(setSelectedPostDate('all'));
        dispatch(setSelectedCompanyType(undefined));
        dispatch(setSelectedItem({}))
    });
    const getNewsAiSummary = useCallback(async ({ news = {} }) => {
        dispatch(setIsLoadingDescription(true))
        await axios.get(API_URL.GET_FINANCE_NEWS_AI_SUMMARY + `?news_id=${news?._id}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.access_token}`,
                },
            })
            .then((res) => {
                let article = { ...news, chatgpt_desc: res?.data?.data }
                dispatch(setSelectedArticle(article))
                dispatch(setIsLoadingDescription(false))
            }).catch((err) => {
                dispatch(setIsLoadingDescription(false))
                console.error("ERROR", err)
            })
    }, [dispatch, userData?.access_token])
    const getTranscriptData = useCallback(async () => {
        const res = await axios.get(API_URL.KEYWORD_SEARCH_TRANSCRIPT + `?user_id=${userData?.user?._id}&page=1&limit=20&searchstring=${searchParams.get("keyword")}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userData?.access_token}`,
            },
        })
        return res.data
    }, [searchParams, userData?.access_token, userData?.user?._id])

    const getAllExperts = useCallback(async () => {
        const res = await axios.get(API_URL.KEYWORD_SEARCH_EXPERT + `?searchstring=${searchParams.get("keyword")}&page=1&limit=20&user_id=${userData?.user?._id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userData?.access_token}`,
            },
        })
        return res.data
    }, [searchParams, userData?.access_token, userData?.user?._id])

    const getNewsData = useCallback(async () => {
        const response = await axios.get(API_URL.KEYWORD_SEARCH_NEWS + `?page=${1}&limit=${cstm_limit}&searchstring=${searchParams.get("keyword")}&days=all`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userData?.access_token}`,
            },
        })

        return response.data
    }, [searchParams, userData?.access_token])

    const getAddedToWatchlist = useCallback(async () => {
        const response = await axios.get(API_URL.CHECK_KEYWORD_WATCHLISTED + `?user_id=${userData?.user?._id}&keyword=${searchParams.get("keyword")}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${userData?.access_token}`,
            },
        })

        return response.data
    }, [searchParams, userData?.access_token, userData?.user?._id])

    const getExpertById = useCallback(async (id) => {
        try {
            dispatch(setIsLoadingExpertSummary(true))
            const response = await axios.get(API_URL.GET_EXPERT_DATA_BY_ID + `?expert_id=${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.access_token}`,
                },
            });
            dispatch(setExpertProfile(response?.data?.data));
            dispatch(setIsLoadingExpertSummary(false))
        } catch (error) {
            dispatch(setIsLoadingExpertSummary(false))
            console.error("ERROR", error);
        }
    }, [dispatch, userData?.access_token]);

    const { mutate } = useSWR(["check_added_to_watchlist", searchParams.get("keyword"), activeTab], () => searchParams.get("keyword") ? getAddedToWatchlist() : null, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (data) => {
            dispatch(setSearchKeyworkdData(data)) //for selecting the default one
        },
        onError: (err) => {
            console.error("ERROR", err)
        }
    })
    const { isLoading: isLoadingTrans, isValidating: isValidatingTrans } = useSWR(["transcript_search_keyword", searchParams.get("keyword"), activeTab], () => activeTab === "transcript" && searchParams.get("keyword") ? getTranscriptData() : null, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (data) => {
            if (data?.data?.length > 0) {
                dispatch(setSelectedItem(data?.data[0])) //for selecting the default one
            }
            dispatch(setTranscriptData({ data: data?.data || [], count: data?.countData || 0 }))
        },
        onError: (err) => {
            console.error("ERROR", err)
        }
    })
    const { isLoading: isLoadingExp, isValidating: isValidatingExp } = useSWR(["expert_search_keyword", searchParams.get("keyword"), activeTab], () => activeTab === "expert" && searchParams.get("keyword") ? getAllExperts() : null, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (data) => {
            if (data?.data?.length > 0 && permission?.experts?.view) {
                dispatch(setSelectedExpert(data?.data?.[0]));
                getExpertById(data?.data?.[0]?._id) //TO GET FIRST EXPERT DETAILS
            }
            dispatch(setExpertList({ data: data?.data, count: data?.countData }));//for selecting the default one
        },
        onError: (err) => {
            console.error("ERROR", err)
        }
    })
    const { isLoading: isLoadingNews, isValidating: isValidatingNews } = useSWR(["news_search_keyword", searchParams.get("keyword"), activeTab], () => searchParams.get("keyword") && activeTab === "news" ? getNewsData() : null, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (data) => {
            // if (data?.data?.length > 0) {
            //     dispatch(setSelectedArticle(data?.data?.[0]));
            //     getNewsAiSummary({ news: data?.data?.[0] })
            // }
            dispatch(setLimit(cstm_limit))
            let obj = { data: data?.data, count: data?.count }
            dispatch(updateNewsData(obj))//for selecting the default one
        }
    })

    const fetchTranscriptData = useCallback(async ({ paginate = false, selectedDate = selectedPostDate, selectedType = selectedCompanyType, page_no = transcriptPagination.page, page_limit = transcriptPagination.limit }) => {
        try {
            dispatch(setTranscriptPagination({ page: page_no, limit: page_limit }))
            dispatch(setSelectedItem({}))
            dispatch(setIsLoadingContent(true))
            const data = await axios.get(API_URL.KEYWORD_SEARCH_TRANSCRIPT + `?user_id=${userData?.user?._id}&page=${page_no}&limit=${page_limit}&searchstring=${searchParams.get("keyword")}&days=${selectedDate} ${selectedType ? `&company_type=${selectedType}` : ``}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.access_token}`,
                },
            })
            if (data?.data?.data?.length > 0) {
                dispatch(setSelectedItem(data?.data?.data[0])) //for selecting the default one
            }
            if (data?.data?.data?.length === 0) {
                dispatch(setSelectedItem({}))
            }
            dispatch(setIsLoadingContent(false))
            if (paginate) {
                dispatch(setTranscriptData({ data: [...transcriptData?.data, ...data?.data?.data], count: data?.data?.countData }))
            } else {
                dispatch(setTranscriptData({ data: data?.data?.data, count: data?.data?.countData }))

            }
        } catch (error) {
            dispatch(setIsLoadingContent(false))

            console.error('error: ', error);
        }

    }, [selectedPostDate, selectedCompanyType, transcriptPagination.page, transcriptPagination.limit, dispatch, userData?.user?._id, userData?.access_token, searchParams, transcriptData?.data])

    const fetchNewsData = useCallback(async ({ selectedDate = selectedPostDate, page_no = newsPagination.page, page_limit = newsPagination.limit }) => {
        try {
            dispatch(setNewsPagination({ page: page_no, limit: page_limit }))
            dispatch(setSelectedItem({}))
            dispatch(setIsLoadingContent(true))
            const data = await axios.get(API_URL.KEYWORD_SEARCH_NEWS + `?page=${page_no}&limit=${page_limit}&searchstring=${searchParams.get("keyword")}&days=${selectedDate}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.access_token}`,
                },
            })
            if (data?.data?.data?.length > 0) {
                dispatch(setSelectedArticle(data?.data?.data?.[0]));
                // getNewsAiSummary({ news: data?.data?.data?.[0] })
            }
            if (data?.data?.data?.length === 0) {
                dispatch(setSelectedArticle({}));
            }
            dispatch(setIsLoadingContent(false))
            dispatch(setLimit(cstm_limit))
            let obj = { data: data?.data?.data, count: data?.data?.count }
            dispatch(updateNewsData(obj))//for selecting the default one
        } catch (error) {
            dispatch(setIsLoadingContent(false))
            console.error('error: ', error);
        }

    }, [selectedPostDate, newsPagination.page, newsPagination.limit, dispatch, searchParams, userData?.access_token])

    const fetchExpertData = useCallback(async ({ page_no = expertPagination.page, page_limit = expertPagination.limit }) => {
        try {
            dispatch(setExpertPagination({ page: page_no, limit: page_limit }))
            dispatch(setIsLoadingContent(true))
            const data = await axios.get(API_URL.KEYWORD_SEARCH_EXPERT + `?searchstring=${searchParams.get("keyword")}&page=${page_no}&limit=${page_limit}&user_id=${userData?.user?._id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userData?.access_token}`,
                },
            })
            dispatch(setExpertList({ data: [...expertList?.data, ...data?.data?.data], count: data?.data?.countData }));

            if (data?.data?.data?.length > 0) {
                dispatch(setSelectedExpert(data?.data?.data?.[0]));
                dispatch(setToggle(true))
                getExpertById(data?.data?.data?.[0]?._id) //TO GET FIRST EXPERT DETAILS
            }
            dispatch(setIsLoadingContent(false))
        } catch (error) {
            dispatch(setIsLoadingContent(false))
            console.error('error: ', error);
        }
    }, [expertPagination.page, expertPagination.limit, dispatch, searchParams, userData?.user?._id, userData?.access_token, expertList?.data, getExpertById])

    const headers = useMemo(() => ({
        "Content-Type": "application/json",
        Authorization: `Bearer ${userData?.access_token}`,
    }), [userData?.access_token]);

    const addToWatchListKeyword = useCallback(
        async (keyword) => {
            try {
                const res = await axios.post(`${API_PATH.ADD_KEYWORD_TO_WATCHLIST}`, {
                    "keyword": keyword,
                    "userId": userData?.user?._id
                }, { headers }
                )
                mutate("check_added_to_watchlist")
                // mutateCompanyDetails("searched_company_details")
                return toast.success("Keyword Added To Watchlist", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            } catch (error) {
                return toast.error("Error While Adding to Keyword", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            }

        },
        [headers, mutate, userData?.user?._id],
    )
    const removeFromWatchListKeyword = useCallback(
        async (keyword) => {
            try {
                const res = await axios.delete(API_PATH.REMOVE_KEYWORD_FROM_WATCHLIST + `?keyword=${keyword}&userId=${userData?.user?._id}`, { headers }
                )
                // mutateCompanyDetails("searched_company_details")
                return toast.success("Company Removed From Watchlist", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            } catch (error) {
                return toast.error("Error While Adding From Watchlist", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            }

        },
        [headers, userData?.user?._id],
    )

    const addDefaultSrc = useCallback((ev) => {
        ev.target.src = "https://platform.nextyn.com/static/media/company_icons3.c720b9bb86fbb87c1eb6.png";
    }, []);
    const expertSummery = useCallback((data) => {
        if (data !== null && data !== "" && data !== undefined) {
            // Split the input data into an array of paragraphs based on numeric indicators
            const paragraphs = data?.split(/\d+\.\s+/);
            return (
                <div className="" style={{ width: "100%", paddingRight: "20px" }}>
                    <div className="inline-show-more-text px-2">
                        <ol>
                            {paragraphs?.map((paragraph, index) => (
                                paragraph !== "" && <li key={index} style={{ fontSize: "12px" }}>
                                    {paragraph?.trim()}
                                </li>
                            ))}
                        </ol>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }, [])

    const replaceTxt = useCallback((txt, para) => {
        let text = txt;
        for (var i = 0; i < para.length; i++) {
            text = text?.replace(new RegExp(para[i], 'g'), "");
        }
        text = text?.trimStart()
        // .replace(/^[^A-Z]+/, '');
        return text;
    }, [])

    const formatDuration = useCallback((duration) => {
        const hours = Math.floor(duration / 60);
        const minutes = Math.floor(duration % 60);

        if (hours > 0) {
            return `${hours < 10 ? `0${hours}` : hours} hr ${minutes < 10 ? `0${minutes}` : minutes} min`;
        } else {
            return `${minutes < 10 ? `0${minutes}` : minutes} min`;
        }
    }, []);

    const formatDate = useCallback((isoDate) => {
        const dateOptions = { day: '2-digit', month: '2-digit', year: '2-digit' };
        const formattedDate = new Date(isoDate).toLocaleDateString('en-GB', dateOptions);
        return formattedDate;
    }, []);



    const handleItemClick = useCallback((data) => {
        if (permission?.experts?.view) {
            getExpertById(data?._id)
            dispatch(setSelectedExpert(data));
        }
    }, [dispatch, getExpertById, permission?.experts?.view]);



    const handleItemClickNews = useCallback((data) => {
        dispatch(setSelectedArticle(data));
        getNewsAiSummary({ news: data })
    }, [dispatch, getNewsAiSummary]);

    let experienceDetails = useMemo(() => {
        let currentExperience = expertprofile?.[0]?.current_experience || [];
        let previousExperience = expertprofile?.[0]?.previous_experience || [];
        return [
            ...currentExperience,
            ...previousExperience
        ]
    }, [expertprofile]);

    const getStore = useCallback(() => {
        var experience = [];
        for (let i = 0; i < experienceDetails?.length; i++) {
            experience?.push(experienceDetails[i]);
        }
        var CN = [];
        // function uniqueData(data) {
        // var companyName = [];
        for (let i = 0; i < experience.length; i++) {
            if (CN.indexOf(experience[i].company_name) == -1) {
                CN.push(experience[i].company_name);
            }
        }
        // CN.push(...companyName);
        var store = [];

        for (let i = 0; i < CN.length; i++) {
            let local = [];
            let print = [];
            for (let j = 0; j < experience.length; j++) {
                if (CN[i] === experience[j].company_name) {
                    let obj = {};
                    obj.title = experience[j].company_name;
                    obj.dateFrom = experience[j].date_from;
                    obj.dateTill = experience[j].date_till;
                    obj.whatWork = experience[j].what_work;
                    obj.location = experience[j].location;
                    obj.tillPresent = experience[j].till_present;
                    obj.logoUrl = experience[j].logo_url;
                    obj.designation = experience[j].career_title;
                    local.push(obj);
                }
            }
            print.push(CN[i], local);
            store.push(print);
        }
        return store
        // }
        // uniqueData(experience);
    }, [experienceDetails])


    const values = useMemo(() => ({
        expertSummery, replaceTxt, formatDuration, getExpertById,
        formatDate, isLoadingTrans, handleItemClick, isLoadingExp, isLoadingNews, addDefaultSrc, handleItemClickNews, fetchTranscriptData, addToWatchListKeyword, fetchExpertData,
        removeFromWatchListKeyword, getStore, fetchNewsData, isValidatingExp, isValidatingTrans, isValidatingNews
    }), [expertSummery, replaceTxt, formatDuration, getExpertById, formatDate, isLoadingTrans, handleItemClick, isLoadingExp, isLoadingNews, isValidatingNews, addDefaultSrc, handleItemClickNews, fetchTranscriptData, addToWatchListKeyword, removeFromWatchListKeyword, getStore, fetchNewsData, isValidatingExp, isValidatingTrans, fetchExpertData])
    return values
}

export default useSearchPage


