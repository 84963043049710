import React, { useState, useEffect } from "react";
import axios from "axios";
import API_PATH from "../../../Constants/api-path";
import { useLocation } from "react-router-dom";
import { Markup } from "interweave";
// import { Modal } from "react-bootstrap";
import { Button, Popover, Drawer, notification, Tag, Modal } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Spinner } from "reactstrap";
import useExpert from "../../Hooks/useExpert";
import ExpertDetails from "../V2/Dashboard/ExpertDetails";

const ExpertDetailsDrawer = ({
  toggleDrawer,
  isDrawerVisible,
  expertID,
  tabStatus,
  getExperts,
}) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [submitPop, setSubmitPop] = useState(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [modalToggle, setModalToggle] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const { getStore } = useExpert();


  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userData?.access_token}`,
  };

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();

  let project_id = query.get("project_id");

  const fetchData = (Id) => {
    if (Id) {
      axios
        .get(
          `${API_PATH.PROJECT_EXPERT_GET}/${Id}`,
          // { email: UserData.user.email },
          { headers }
        )
        .then((res) => {
          setLoading(true);
          setSubmitPop(res?.data?.data[0]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getFormattedDate = (date) => {
    if (!date || typeof date !== "string") {
      return null;
    }

    const dates = date?.split(" - ");
    if (dates?.length < 2) {
      return null;
    }

    const startDate = convertDate(dates[0].trim(), "month");
    const endDate = dates[1] ? convertDate(dates[1].trim(), "month") : "";

    return `${startDate} - ${endDate}`;
  };

  const convertDate = (date) => {
    if (date !== null && date !== "") {
      // Check if the date is in the format "08, 2023 - 09, 2023"
      if (/^\d{2}, \d{4} - \d{2}, \d{4}$/.test(date)) {
        const [startDate, endDate] = date?.split(" - ");
        const [startMonthNum, startYear] = startDate.trim().split(", ");
        const startMonth = getMonthName(startMonthNum);
        return `${startMonth}, ${startYear}`;
      }

      // Check if the date is in the format "08, 2023 - "
      if (/^\d{2}, \d{4} - $/.test(date)) {
        const [startDate] = date?.split(" - ");
        const [startMonthNum, startYear] = startDate.trim().split(", ");
        const startMonth = getMonthName(startMonthNum);
        return `${startMonth}, ${startYear}`;
      }

      // Check if the date is in the format "08, 2023 "
      if (/^\d{2}, \d{4}$/.test(date)) {
        const [startMonthNum, startYear] = date.trim().split(", ");
        const startMonth = getMonthName(startMonthNum);
        return `${startMonth}, ${startYear}`;
      }

      // Check if the date is in the format "2018-04"
      if (/^\d{4}-\d{2}$/.test(date)) {
        const [year, monthNum] = date?.split("-");
        const month = getMonthName(monthNum);
        return `${month}, ${year}`;
      }

      // Check if the date is in the format "10, 2014 - 01, 2020"
      if (/^\d{2}, \d{4}\s*-\s*\d{2}, \d{4}$/.test(date)) {
        const [startDate, endDate] = date?.split(" - ");
        const [startMonthNum, startYear] = startDate.trim().split(", ");
        const startMonth = getMonthName(startMonthNum);
        return `${startMonth}, ${startYear}`;
      }

      // Check if the date is in the format "02, 2018   -    "
      if (/^\d{2}, \d{4}\s*-\s*$/.test(date)) {
        const [startDate] = date?.split(" - ");
        const [startMonthNum, startYear] = startDate.trim().split(", ");
        const startMonth = getMonthName(startMonthNum);
        return `${startMonth}, ${startYear}`;
      }

      // For other formats, convert the date to the desired format
      var d = new Date(date);
      var day = d.getDate();
      var monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var month = monthNames[d.getMonth()];
      var year = d.getFullYear();
      let formattedDate = `${day} ${month}, ${year}`;
      return formattedDate;
    } else {
      return "---------";
    }
  };

  const getMonthName = (monthNum) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const index = parseInt(monthNum, 10) - 1;
    return monthNames[index];
  };

  const getExperienceList = (data) => {
    if (!data?.expert_experience || data.expert_experience.length === 0) {
      return [];
    }

    const experienceList = data.expert_experience.map((experience) => {
      const obj = {};
      obj.previous_designation = experience.previous_designation || "";
      obj.previous_compny = experience.previous_compny || "";
      obj.previous_date = getFormattedDate(
        experience?.previous_designation_date
      );

      // Filter out entries with invalid or empty previous_date
      if (!obj.previous_date || obj.previous_date === "NaN undefined, NaN") {
        return null;
      }

      return obj;
    });

    // Filter out null entries to remove the invalid data
    const filteredExperienceList = experienceList.filter(
      (item) => item !== null
    );

    return filteredExperienceList;
  };

  const expertPopupStatus = (statuses) => {
    return statuses.map((status, index) => {
      if (status === "Edit") {
        return (
          <div className="statusBtnOffCanvas">
            {/* <span
                style={{ color: "#2a55e", cursor: "pointer", fontSize: "14px" }}
                onClick={() => {
                  expertStatusChanger(status);
                }}
                key={index}
              >
                Edit
              </span> */}
          </div>
        );
      } else if (status === "Request") {
        return (
          <div className="statusBtnOffCanvas">
            <span
              style={{ color: "#2ca8ff", cursor: "pointer", fontSize: "14px" }}
              onClick={() => {
                expertStatusChanger(status);
              }}
              key={index}
            >
              Request
            </span>
          </div>
        );
      } else if (status === "Schedule") {
        return (
          <div className="statusBtnOffCanvas">
            <span
              style={{ color: "#f96332", cursor: "pointer", fontSize: "14px" }}
              onClick={() => {
                expertStatusChanger(status);
              }}
              key={index}
            >
              Schedule
            </span>
          </div>
        );
      } else if (status === "Complete") {
        return (
          <div className="statusBtnOffCanvas">
            <span
              style={{ color: "#39c4a3", cursor: "pointer", fontSize: "14px" }}
              onClick={() => {
                expertStatusChanger(status);
              }}
              key={index}
            >
              Complete
            </span>
          </div>
        );
      } else if (status === "Decline") {
        return (
          <div className="statusBtnOffCanvas">
            <span
              style={{ color: "maroon", cursor: "pointer", fontSize: "14px" }}
              onClick={() => {
                expertStatusChanger(status);
              }}
              key={index}
            >
              Decline
            </span>
          </div>
        );
      } else if (status === "Delete") {
        return (
          <div className="statusBtnOffCanvas">
            <span
              style={{ color: "red", cursor: "pointer", fontSize: "14px" }}
              onClick={() => {
                expertStatusChanger(status);
              }}
              key={index}
            >
              Delete
            </span>
          </div>
        );
      }
    });
  };

  const expertStatusChanger = (status) => {
    setStatus(status);
    setModalToggle(true);
    //console.log(status);
  };

  const statusData =
    tabStatus === "submit"
      ? expertPopupStatus(["Request", "Schedule", "Decline", "Delete"])
      : tabStatus === "request"
        ? expertPopupStatus(["Schedule", "Complete", "Decline", "Delete"])
        : tabStatus === "schedule"
          ? expertPopupStatus([
            "Submit",
            "Request",
            "Complete",
            "Decline",
            "Delete",
          ])
          : tabStatus === "complete"
            ? expertPopupStatus(["Request", "Schedule", "Decline"])
            : tabStatus === "decline"
              ? expertPopupStatus([])
              : null;

  const first_charc_uppercase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleClose = () => setModalToggle(false);
  const handleClose1 = () => setModalToggle(false);

  const statusSetter = (status) => {
    if (status === "Edit") {
      return (
        <p>
          Are you sure you want to schedule a call with this expert? Once you
          confirm, our team will get in touch with you to lock in a convenient
          day and time for the same.{" "}
        </p>
      );
    } else if (status === "Request") {
      return <p>Are you sure, you want to mark this call as Requested?</p>;
    } else if (status === "Schedule") {
      return <p>Are you sure, you want to mark this call as Scheduled?</p>;
    } else if (status === "Complete") {
      return <p>Are you sure, you want to mark this call as completed?</p>;
    } else if (status === "Decline") {
      return <p>Are you sure, you want to mark this call as Declined?</p>;
    } else if (status === "Delete") {
      return <p>Are you sure, you want to delete this expert?</p>;
    }
  };

  const statusButton = (status) => {
    if (status === "Edit" || status === "Edit") {
      return "Edit";
    } else if (status === "Request") {
      return "Request";
    } else if (status === "Schedule") {
      return "Schedule";
    } else if (status === "Complete") {
      return "Complete";
    } else if (status === "Decline") {
      return "Decline";
    } else if (status === "Delete") {
      return "Delete";
    }
  };

  const handleSubmit = async (status) => {
    setSpinner(true);

    let valueToAdd;
    if (status === "Request") {
      valueToAdd = 3;
      axios
        .post(
          `${API_PATH.PROJECT_STATUS}`,
          {
            experts_detail_id: expertID,
            project_id: project_id,
            user_id: userData?.user?._id,
            status: valueToAdd,
          },
          {
            headers,
          }
        )
        .then((res) => {
          setSpinner(false);
          setModalToggle(false);
          getExperts();
          toggleDrawer(false)

          notification.success({
            message: "Status has been changed",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (status === "Schedule") {
      valueToAdd = 4;
      axios
        .post(
          `${API_PATH.PROJECT_STATUS}`,
          {
            experts_detail_id: expertID,
            project_id: project_id,
            user_id: userData?.user?._id,
            status: valueToAdd,
          },
          {
            headers,
          }
        )
        .then((res) => {
          setSpinner(false);
          setModalToggle(false);
          getExperts();
          toggleDrawer(false)
          notification.success({
            message: res.data.message,
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (status === "Complete") {
      valueToAdd = 5;
      axios
        .post(
          `${API_PATH.PROJECT_STATUS}`,
          {
            experts_detail_id: expertID,
            project_id: project_id,
            user_id: userData?.user?._id,
            status: valueToAdd,
          },
          {
            headers,
          }
        )
        .then((res) => {
          setSpinner(false);
          setModalToggle(false);
          toggleDrawer(false)
          getExperts();

          notification.success({
            message: res.data.message,
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (status === "Decline") {
      valueToAdd = 6;
      axios
        .post(
          `${API_PATH.PROJECT_STATUS}`,
          {
            experts_detail_id: expertID,
            project_id: project_id,
            user_id: userData?.user?._id,
            status: valueToAdd,
          },
          {
            headers,
          }
        )
        .then((res) => {
          setSpinner(false);
          setModalToggle(false);
          toggleDrawer(false)
          getExperts();
          notification.success({
            message: res.data.message,
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (status === "Delete") {
      axios
        .post(
          `${API_PATH.PROJECT_EXPERT_DELETE}`,
          {
            experts_detail_id: expertID,
            project_id: project_id,
          },
          {
            headers,
          }
        )
        .then((res) => {
          setSpinner(false);
          setModalToggle(false);
          toggleDrawer(false)
          getExperts();
          notification.success({
            message: res.data.message,
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // if (
    //   status === "Schedule" ||
    //   status === "Reschedule" ||
    //   status === "Scheduled" ||
    //   status === "Rescheduled"
    // ) {
    //   axios
    //     .post(
    //       `${API_PATH.CLIENT_SCHEDULE}/${project_id}/${expertID}`,
    //       { headers }
    //     )
    //     .then((res) => {
    //       //console.log(res);
    //       setSpinner(false);
    //       setModalToggle(false);
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 1000);
    //       return toast.success(res.data.message, {
    //         position: toast.POSITION.TOP_RIGHT,
    //         autoClose: 1000,
    //       });
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // } else if (status === "Decline") {
    //   axios
    //     .post(
    //       `${API_PATH.CLIENT_DECLINE}/${project_id}/${expertID}`,
    //       { headers }
    //     )
    //     .then((res) => {
    //       // console.log(res);
    //       setSpinner(false);
    //       setModalToggle(false);
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 1000);
    //       return toast.success(res.data.message, {
    //         position: toast.POSITION.TOP_RIGHT,
    //         autoClose: 1000,
    //       });
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // } else if (status === "Cancel") {
    //   axios
    //     .post(
    //       `${API_PATH.CLIENT_SCHEDULECANCEL}/${project_id}/${expertID}`,
    //       { headers }
    //     )
    //     .then((res) => {
    //       // console.log(res);
    //       setSpinner(false);
    //       setModalToggle(false);
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 1000);
    //       return toast.success(res.data.message, {
    //         position: toast.POSITION.TOP_RIGHT,
    //         autoClose: 1000,
    //       });
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }
  };

  useEffect(() => {
    if (expertID) {
      fetchData(expertID);
    }
  }, [expertID]);

  const contentTags = (selectedExpert) => (
    <>
      <div className="trans_popup cstmTg">
        {selectedExpert?.industry_tags?.slice(1)?.map((brand) => (
          <>
            {brand && (
              <Tag className="me-1 mb-1" size="large" key={brand.trim()}>
                {brand.trim()}
              </Tag>
            )}
          </>
        ))}
      </div>
    </>
  );


  return (
    <>
      <Drawer
        title={
          <>
            <div style={{ float: "left" }}>Expert Profile</div>
            {/* <div style={{ float: "right", fontWeight: 400 }}>
              {submitPop?.expert_id}
            </div> */}
          </>
        }
        placement="right"
        // closable={true}
        onClose={toggleDrawer}
        open={isDrawerVisible}
        width={550}
      >
        {!submitPop?.added_by_team ? (
          <ExpertDetails getStore={getStore} request={false} />
        ) : (
          <div>
            {submitPop && (
              <>
                <div>
                  <h2
                    style={{
                      fontWeight: "400",
                      fontSize: "15px",
                      color: "#333",
                      marginBottom: "10px",
                    }}
                  >
                    {submitPop?.expert_experience[0]?.current_designation}
                  </h2>
                  <div
                    className="nameAndCompany_M"
                    style={{
                      fontWeight: "400",
                      fontSize: "15px",
                      color: "#333",
                      marginTop: "-8px",
                    }}
                  >
                    <strong
                      id="nameAndCompany_M"
                      style={{
                        fontWeight: "600",
                        fontSize: "15px",
                        color: "#333",
                      }}
                    >
                      {submitPop?.expert_experience[0]?.current_compny}
                    </strong>
                    <span> {submitPop?.current_location}</span>
                  </div>
                </div>
                <div className="d-flex mt-2 gap-2">{statusData}</div>
                <div className="mt-2">
                  <div className="cstmTg">
                    {submitPop?.industry_tags
                      ?.slice(0, 1)
                      ?.map((tag, index) => (
                        <>
                          {tag && (
                            <Tag
                              rounded
                              className="fw-normal mt-2"
                              color={"rgba(0, 0, 0, 0.04)"}
                              style={{
                                borderRadius: "4px",
                                fontSize: "12px",
                                color: "#000",
                              }}
                            >
                              {tag}
                            </Tag>
                          )}
                        </>
                      ))}
                    {submitPop?.industry_tags &&
                      submitPop?.industry_tags?.length > 1 && (
                        <Popover
                          placement="right"
                          content={contentTags(submitPop)}
                          trigger="hover"
                        >
                          {/* <Tooltip title={data?.brand.split(',').slice(1).join(', ').trim()}> */}
                          <Tag className="me-1" size="large">
                            +{submitPop?.industry_tags.length - 1}
                          </Tag>
                          {/* </Tooltip> */}
                        </Popover>
                      )}
                  </div>
                </div>
                <div className="overview mt-2">
                  <h6
                    style={{
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    Overview
                  </h6>
                  <Markup
                    className="markup-para"
                    content={submitPop?.overview}
                  />
                </div>
                <div className="experien-list mt-4">
                  <h6
                    style={{
                      fontWeight: "500",
                      fontSize: "15px",
                    }}
                  >
                    Experience
                  </h6>
                  <div>
                    <ul className="nextyn_list_items mt-2">
                      <li className="nextyn_list_item p-0">
                        <div className="expertCompany_2UyI2">
                          <span className="title_1p_LH">
                            {
                              submitPop?.expert_experience[0]
                                ?.current_designation
                            }
                          </span>
                          <div className="companyName_1dSwA">
                            {" "}
                            {submitPop?.expert_experience[0]?.current_compny}
                          </div>
                        </div>
                        <span className="careerDates_3sJ2b">
                          {" "}
                          {convertDate(
                            submitPop?.expert_experience[0]
                              ?.current_designation_date,
                            "month"
                          )}
                          - Present
                        </span>
                      </li>
                      {getExperienceList(submitPop).map((curElem, i) => {
                        return (
                          <li className="nextyn_list_item p-0" key={i}>
                            <div className="expertCompany_2UyI2">
                              <span className="title_1p_LH">
                                {curElem?.previous_designation}
                              </span>
                              <div className="companyName_1dSwA">
                                {curElem?.previous_compny}
                              </div>
                            </div>
                            <span className="careerDates_3sJ2b">
                              {curElem?.previous_date}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </Drawer>
      <Modal
        centered
        title={
          <>
            <h4 className="modalHeader">
              {first_charc_uppercase(status)} a call with the expert
            </h4>
          </>
        }
        open={modalToggle}
        onCancel={handleClose1}
        footer={[
          <div className="d-flex justify-content-end align-items-center">
            {status === "Cancel" ? (
              <>
                <button
                  style={{ backgroundColor: "#5746ec", border: "none" }}
                  onClick={() => {
                    handleSubmit(status);
                  }}
                >
                  {spinner ? (
                    <>
                      <Spinner
                        size="sm"
                        style={{
                          width: "1rem",
                          height: "1rem",
                          marginRight: "5px",
                        }}
                      />
                      {statusButton(status)}
                    </>
                  ) : (
                    statusButton(status)
                  )}
                </button>
              </>
            ) : (
              <>
                <Button className="btn-light me-2" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  style={{
                    backgroundColor: "rgb(76, 187, 23)",
                    borderColor: "rgb(76, 187, 23)",
                    color: "#fff",
                  }}
                  onClick={() => {
                    handleSubmit(status);
                  }}
                >
                  {spinner ? (
                    <>
                      <Spinner
                        size="sm"
                        style={{
                          width: "1rem",
                          height: "1rem",
                          marginRight: "5px",
                        }}
                      />
                      {statusButton(status)}
                    </>
                  ) : (
                    statusButton(status)
                  )}
                </Button>
              </>
            )}
          </div>,
        ]}
      >
        {statusSetter(status)}
      </Modal>
    </>
  );
};

export default ExpertDetailsDrawer;
