import { Select } from "antd";
import axios from "axios";
import queryString from "query-string";
import React, { memo, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { toQueryString } from "../../../Utils/Util";
import API_URL from "../../../Constants/api-path";
const SelectionOption = ({
  expert_type = "",
  stateList = {},
  stateFunctionList = {},
  stateListFilter = {},
  stateFunctionListFilter = {},
  callOnce = { current: null },
  callFilter = { current: null },
  handleSearchExperts = () => { },
  getExpertsOptions = () => { },
  filterRef = { current: null },
  advanceSearchOption = false,
  setAdvanceSearchOpen = () => { }
}) => {
  const {
    allGeog,
    allIndustry,
    allCompany,
    allPreviousCompany,
    allDesig,
    allExperties,
  } = stateListFilter;

  const {
    current_company,
    previous_company,
    industry,
    country,
    designation,
    experties,
  } = stateList;
  const {
    setCompany,
    setPreviousCompany,
    setIndustry,
    setCountry,
    setDesignation,
    setExperties,
    setWordEntered,
    setSearchRgx,
    setCurrentPage,
  } = stateFunctionList;
  const {
    setAllGeog,
    setAllIndustry,
    setAllCompany,
    setAllPreviousCompany,
    setAllDesig,
    setAllExperties,
  } = stateFunctionListFilter;
  const catMenu = useRef(null);
  const [, setopenSlide] = useState(false);
  let history = useNavigate();

  const userData = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    let handler;
    handler = setTimeout(async () => {
      if (!callOnce?.current && !callFilter?.current) {
        callOnce.current = true;
        callFilter.current = true;
        getExpertsOptions();
      }
      setSelectedValues(current_company);
      setSelectedValuesPrevious(previous_company);
      setSelectedIndustry(industry);
      setSelectedGeog(country);
      setSelectedDesig(designation);
      setSelectedExperties(experties);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [
    callFilter,
    callOnce,
    current_company,
    designation,
    experties,
    country,
    getExpertsOptions,
    industry,
    previous_company,
  ]);

  useEffect(() => {
    function handleOutsideClick(event) {
      if (catMenu?.current && !catMenu?.current?.contains(event.target)) {
        setopenSlide(true);
      }
    }
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document?.removeEventListener("click", handleOutsideClick);
    };
  }, [])


  function filterDuplicates(array) {
    return Array.from(new Set(array));
  }

  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedValuesPrevious, setSelectedValuesPrevious] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [selectedGeog, setSelectedGeog] = useState([]);
  const [selectedDesig, setSelectedDesig] = useState([]);
  const [selectedExperties, setSelectedExperties] = useState([]);

  let queryParams = queryString.parse(window.location.search);

  const Replacepagechange = React.useCallback(
    (
      searchRgx,
      current_company,
      previous_company,
      industry,
      geography,
      designation,
      experties,
      page
    ) => {
      history({
        pathname: `/find-expert`,
        search: `?key=${searchRgx}&current_company=${current_company}&previous_company=${previous_company}&industry=${industry}&geography=${geography}&designation=${designation}&tags=${experties}&page=${page}`,
      });
    },
    [history]
  );

  const handleChange = (value) => {
    setCompany(value);
    setSelectedValues(value);
    Replacepagechange(
      queryParams.key || "",
      JSON.stringify(value),
      queryParams.previous_company || "",
      queryParams.industry || "",
      queryParams.geography || "",
      queryParams.designation || "",
      queryParams.tags || "",
      1
    );
  };
  const handleChangePrevious = (value) => {
    setPreviousCompany(value);
    setSelectedValuesPrevious(value);

    Replacepagechange(
      queryParams.key || "",
      queryParams.current_company || "",
      JSON.stringify(value),
      queryParams.industry || "",
      queryParams.geography || "",
      queryParams.designation || "",
      queryParams.tags || "",
      1
    );
  };


  const handleChangeIndustry = (value) => {
    setSelectedIndustry(value);
    setIndustry(value);
    Replacepagechange(
      queryParams.key || "",
      queryParams.current_company || "",
      queryParams.previous_company || "",
      JSON.stringify(value) || "",
      queryParams.geography || "",
      queryParams.designation || "",
      queryParams.tags || "",
      1
    );
  };

  const handleChangeGeog = (value) => {
    setSelectedGeog(value);
    setCountry(value);
    Replacepagechange(
      queryParams.key || "",
      queryParams.current_company || "",
      queryParams.previous_company || "",
      queryParams.industry || "",
      JSON.stringify(value),
      queryParams.designation || "",
      queryParams.tags || "",
      1
    );
  };

  const handleChangedesig = (value) => {
    setSelectedDesig(value);
    setDesignation(value);
    Replacepagechange(
      queryParams.key || "",
      queryParams.current_company || "",
      queryParams.previous_company || "",
      queryParams.industry || "",
      queryParams.geography || "",
      JSON.stringify(value),
      queryParams.tags || "",
      1
    );
  };
  const handleChangeExperties = (value) => {
    setSelectedExperties(value);
    setExperties(value);
    Replacepagechange(
      queryParams.key || "",
      queryParams.current_company || "",
      queryParams.previous_company || "",
      queryParams.industry || "",
      queryParams.geography || "",
      queryParams.designation || "",
      JSON.stringify(value),
      1
    );
  };

  const handleClearAll = React.useCallback(() => {
    filterRef.current.search = false;
    filterRef.current.count = 0;
    callOnce.current = false;
    callFilter.current = false;

    localStorage.removeItem("filterStates");
    setAdvanceSearchOpen(false)
    setCurrentPage(1);
    setWordEntered("");
    setSearchRgx("");
    setCompany("");
    setPreviousCompany("");
    setIndustry("");
    setCountry("");
    setDesignation("");
    setExperties("");
    setCurrentPage(1);
    setSelectedValues([]);
    setSelectedValuesPrevious([]);
    setSelectedIndustry([]);
    setSelectedGeog([]);
    setSelectedDesig([]);
    setSelectedExperties([]);
    Replacepagechange("", "", "", "", "", "", "", 1);
    handleSearchExperts(true);
  }, [filterRef, callOnce, callFilter, setAdvanceSearchOpen, setCurrentPage, setWordEntered, setSearchRgx, setCompany, setPreviousCompany, setIndustry, setCountry, setDesignation, setExperties, Replacepagechange, handleSearchExperts]);

  const expertFilterSearch = React.useCallback(
    async (key, value) => {
      const queryParamater = {
        search: value ? value : "",
        searchkey: key ? key : "",
        expert_type: expert_type === "consultant" ? "consultant" : "expert",
      };

      let paramsforQuery = new URLSearchParams([
        ...Object.entries({
          ...toQueryString({ ...queryParamater }),
        }),
      ]);
      await axios
        .get(`${API_URL?.EXPERT_FILTER_SEARCH}?${paramsforQuery.toString()}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.access_token}`,
          },
        })
        .then((res) => {
          if (key === "current_company") {
            setAllCompany(
              filterDuplicates([...allCompany, ...res?.data?.data])
            );
          } else if (key === "industry") {
            setAllIndustry(
              filterDuplicates([...allIndustry, ...res?.data?.data])
            );
          } else if (key === "country") {
            setAllGeog(filterDuplicates([...allGeog, ...res?.data?.data]));
          } else if (key === "designation") {
            setAllDesig(filterDuplicates([...allDesig, ...res?.data?.data]));
          } else if (key === "tags") {
            setAllExperties(
              filterDuplicates([...allExperties, ...res?.data?.data])
            );
          } else if (key === "previous_company") {
            setAllPreviousCompany(
              filterDuplicates([...allPreviousCompany, ...res?.data?.data])
            );
          }

          // setLoading(false);
        })
        .catch((error) => {
          console.log("error: ", error);
          // setLoading(false);
        });
    },
    [
      allCompany,
      allDesig,
      allExperties,
      allGeog,
      allIndustry,
      allPreviousCompany,
      expert_type,
      setAllCompany,
      setAllDesig,
      setAllExperties,
      setAllGeog,
      setAllIndustry,
      setAllPreviousCompany,
      userData?.access_token,
    ]
  );

  const [filterStates, setFilterStates] = useState({
    name: "",
    value: "",
  });
  useEffect(() => {
    let states = JSON.parse(localStorage.getItem("filterStates"));
    if (states && states.path === "/expert-profile") {
      callOnce.current = true;
      callFilter.current = true;
      // setFilterStates(states)
      localStorage.removeItem("filterStates");
    }
    localStorage.removeItem("filterStates");
  }, [callFilter, callOnce]);

  const handleSearch = React.useCallback((value, name) => {
    setFilterStates({
      name: name,
      value: value,
    });
    localStorage.setItem(
      "filterStates",
      JSON.stringify({
        name: name,
        value: value,
      })
    );
  }, []);

  const callSearchOnce = useRef(false);
  useEffect(() => {
    // ----------NEW COMMENTED-------------------
    let handler;
    if (
      filterStates.name !== "" &&
      filterStates.value !== "" &&
      !callSearchOnce.current
    ) {
      handler = setTimeout(async () => {
        callSearchOnce.current = true;
        expertFilterSearch(filterStates.name, filterStates.value);
      }, 1000);
      return () => {
        clearTimeout(handler);
      };
    }
    // ----------NEW COMMENTED-------------------
    // ----------OLD COMMENTED-------------------
    //  else {
    //   callOnce.current = true
    // }
    // else {
    //   if (callOnce.current) {
    //     handler = setTimeout(async () => {
    //       getExperts(queryParams.page);
    //     }, 1000);
    //   }
    // }
    // ----------OLD COMMENTED-------------------
    // ----------NEW COMMENTED-------------------
  }, [expertFilterSearch, filterStates.name, filterStates.value]);
  // ----------NEW COMMENTED-------------------
  return (
    <div className={`select-option customRes advanceSearch ${advanceSearchOption ? 'd-block' : 'd-none'}`} ref={catMenu}>
      {/* <div className="row row-cols-lg-2 row-cols-xl-5 g-2"> */}
      <Row className="g-2">
        <Col className="mb-2">
          <div className="location">
            <Select
              showSearch
              placeholder="Select Company"
              mode="multiple"
              size="large"
              className="w-100"
              optionFilterProp="children"
              onSearch={(value) => {
                callSearchOnce.current = false;
                handleSearch(value, "current_company");
              }}
              onSelect={() => {
                filterRef.current = {
                  ...filterRef.current,
                  count: filterRef.current.count + 1,
                };
              }}
              onDeselect={() => {
                if (filterRef.current.count === 1) {
                  handleClearAll();
                } else {
                  filterRef.current = {
                    ...filterRef.current,
                    count: filterRef.current.count - 1,
                  };
                }
              }}
              onDropdownVisibleChange={(open) => {
                if (
                  !open &&
                  filterStates.name !== "" &&
                  filterStates.value !== ""
                ) {
                  setFilterStates({
                    name: "",
                    value: "",
                  });
                }
                callSearchOnce.current = false;
              }}
              onChange={handleChange}
              // mode="multiple"
              value={
                Array.isArray(selectedValues)
                  ? selectedValues
                  : selectedValues
                    ? [selectedValues]
                    : []
              }
              filterOption={(input, option) =>
                option?.props?.children
                  ?.toLowerCase()
                  .indexOf(input?.toLowerCase()) !== -1
              }
            >
              {allCompany
                ?.filter((item) => !selectedValues?.includes(item))
                ?.map((item, index) => {
                  return (
                    <>
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    </>
                  );
                })}
            </Select>
          </div>
        </Col>
        <Col className="mb-2">
          <div className="location">
            <Select
              mode="multiple"
              showSearch
              className="w-100"
              placeholder="Select Previous Company"
              size="large"
              optionFilterProp="children"
              onSelect={() => {
                filterRef.current = {
                  ...filterRef.current,
                  count: filterRef.current.count + 1,
                };
              }}
              onDeselect={() => {
                if (filterRef.current.count === 1) {
                  handleClearAll();
                } else {
                  filterRef.current = {
                    ...filterRef.current,
                    count: filterRef.current.count - 1,
                  };
                }
              }}
              onDropdownVisibleChange={(open) => {
                if (
                  !open &&
                  filterStates.name !== "" &&
                  filterStates.value !== ""
                ) {
                  setFilterStates({
                    name: "",
                    value: "",
                  });
                }
                callSearchOnce.current = false;
              }}
              onSearch={(value) => {
                callSearchOnce.current = false;
                handleSearch(value, "previous_company");
              }}
              onChange={handleChangePrevious}
              // mode="multiple"
              value={
                Array.isArray(selectedValuesPrevious)
                  ? selectedValuesPrevious
                  : selectedValuesPrevious
                    ? [selectedValuesPrevious]
                    : []
              }
              filterOption={(input, option) =>
                option?.props?.children
                  ?.toLowerCase()
                  .indexOf(input?.toLowerCase()) !== -1
              }
            >
              {allPreviousCompany
                ?.filter((item) => !selectedValuesPrevious?.includes(item))
                ?.map((item, index) => {
                  return (
                    <>
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    </>
                  );
                })}
            </Select>
          </div>
        </Col>
        <Col className="mb-2">
          <div className="location">
            <Select
              mode="multiple"
              showSearch
              className="w-100"
              placeholder="Select Industries"
              size="large"
              optionFilterProp="children"
              onChange={handleChangeIndustry}
              onSelect={() => {
                filterRef.current = {
                  ...filterRef.current,
                  count: filterRef.current.count + 1,
                };
              }}
              onDeselect={() => {
                if (filterRef.current.count === 1) {
                  handleClearAll();
                } else {
                  filterRef.current = {
                    ...filterRef.current,
                    count: filterRef.current.count - 1,
                  };
                }
              }}
              onSearch={(value) => {
                callSearchOnce.current = false;
                handleSearch(value, "industry");
              }}
              onDropdownVisibleChange={(open) => {
                if (
                  !open &&
                  filterStates.name !== "" &&
                  filterStates.value !== ""
                ) {
                  setFilterStates({
                    name: "",
                    value: "",
                  });
                }
                callSearchOnce.current = false;
              }}
              filterOption={(input, option) => {
                const optionText = option?.props?.children;
                if (typeof optionText === "string") {
                  return (
                    optionText?.toLowerCase()?.indexOf(input?.toLowerCase()) !==
                    -1
                  );
                }
                return false;
              }}
              // mode="multiple"
              value={
                Array.isArray(selectedIndustry)
                  ? selectedIndustry
                  : selectedIndustry
                    ? [selectedIndustry]
                    : []
              }
            >
              {allIndustry
                ?.filter((item) => !selectedIndustry?.includes(item))
                ?.map((item, index) => (
                  <Select.Option key={item} value={item}>
                    {item?.charAt(0)?.toUpperCase() +
                      item?.slice(1)?.toLowerCase()}
                  </Select.Option>
                ))}
            </Select>
          </div>
        </Col>
      </Row>

      <Row className="g-2">
        <Col className="mb-2">
          <div className="location">
            <Select
              mode="multiple"
              showSearch
              className="w-100"
              placeholder="Select Geography"
              size="large"
              optionFilterProp="children"
              onChange={handleChangeGeog}
              onSearch={(value) => {
                callSearchOnce.current = false;
                handleSearch(value, "country");
              }}
              onSelect={() => {
                filterRef.current = {
                  ...filterRef.current,
                  count: filterRef.current.count + 1,
                };
              }}
              onDeselect={() => {
                if (filterRef.current.count === 1) {
                  handleClearAll();
                } else {
                  filterRef.current = {
                    ...filterRef.current,
                    count: filterRef.current.count - 1,
                  };
                }
              }}
              onDropdownVisibleChange={(open) => {
                if (
                  !open &&
                  filterStates.name !== "" &&
                  filterStates.value !== ""
                ) {
                  setFilterStates({
                    name: "",
                    value: "",
                  });
                }
                callSearchOnce.current = false;
              }}
              filterOption={(input, option) =>
                option?.props?.children
                  ?.toLowerCase()
                  .indexOf(input?.toLowerCase()) !== -1
              }
              value={
                Array.isArray(selectedGeog)
                  ? selectedGeog.map((item) => {
                    return (
                      item?.charAt(0)?.toUpperCase() +
                      item?.slice(1)?.toLowerCase()
                    );
                  })
                  : selectedGeog
                    ? [
                      selectedGeog?.charAt(0)?.toUpperCase() +
                      selectedGeog?.slice(1)?.toLowerCase(),
                    ]
                    : []
              }
            >
              {allGeog
                ?.filter((item) => !selectedGeog?.includes(item))
                ?.map((item, index) => {
                  const capitalizedItem =
                    item?.charAt(0)?.toUpperCase() +
                    item?.slice(1)?.toLowerCase();
                  return (
                    <Select.Option key={item} value={item}>
                      {capitalizedItem}
                    </Select.Option>
                  );
                })}
            </Select>
          </div>
        </Col>
        <Col className="mb-2">
          <div className="location">
            <Select
              className="w-100"
              mode="multiple"
              showSearch
              placeholder="Select Designation"
              size="large"
              onSelect={() => {
                filterRef.current = {
                  ...filterRef.current,
                  count: filterRef.current.count + 1,
                };
              }}
              onDeselect={() => {
                if (filterRef.current.count === 1) {
                  handleClearAll();
                } else {
                  filterRef.current = {
                    ...filterRef.current,
                    count: filterRef.current.count - 1,
                  };
                }
              }}
              onDropdownVisibleChange={(open) => {
                if (
                  !open &&
                  filterStates.name !== "" &&
                  filterStates.value !== ""
                ) {
                  setFilterStates({
                    name: "",
                    value: "",
                  });
                }
                callSearchOnce.current = false;
              }}
              optionFilterProp="children"
              onChange={handleChangedesig}
              onSearch={(value) => {
                callSearchOnce.current = false;
                handleSearch(value, "designation");
              }}
              filterOption={(input, option) =>
                option?.props?.children
                  ?.toLowerCase()
                  .indexOf(input?.toLowerCase()) !== -1
              }
              // mode="multiple"
              value={
                Array?.isArray(selectedDesig)
                  ? selectedDesig
                  : selectedDesig
                    ? [selectedDesig]
                    : []
              }
            >
              {allDesig
                ?.filter((item) => !selectedDesig?.includes(item))
                ?.map((item, index) => {
                  return (
                    <>
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    </>
                  );
                })}
            </Select>
          </div>
        </Col>
        <Col className="mb-2">
          <div className="location">
            <Select
              mode="multiple"
              className="w-100"
              showSearch
              placeholder="Select Expertise"
              size="large"
              optionFilterProp="children"
              onChange={handleChangeExperties}
              onSelect={() => {
                filterRef.current = {
                  ...filterRef.current,
                  count: filterRef.current.count + 1,
                };
              }}
              onDeselect={() => {
                if (filterRef.current.count === 1) {
                  handleClearAll();
                } else {
                  filterRef.current = {
                    ...filterRef.current,
                    count: filterRef.current.count - 1,
                  };
                }
              }}
              onSearch={(value) => {
                callSearchOnce.current = false;
                handleSearch(value, "tags");
              }}
              onDropdownVisibleChange={(open) => {
                if (
                  !open &&
                  filterStates.name !== "" &&
                  filterStates.value !== ""
                ) {
                  setFilterStates({
                    name: "",
                    value: "",
                  });
                }
                callSearchOnce.current = false;
              }}
              filterOption={(input, option) =>
                option?.props?.children
                  ?.toLowerCase()
                  .indexOf(input?.toLowerCase()) !== -1
              }
              // mode="multiple"
              value={
                Array?.isArray(selectedExperties)
                  ? selectedExperties
                  : selectedExperties
                    ? [selectedExperties]
                    : []
              }
            >
              {allExperties
                ?.filter((item) => !selectedExperties?.includes(item))
                ?.map((item, index) => {
                  return (
                    <>
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    </>
                  );
                })}
            </Select>
          </div>
        </Col>
      </Row>
      {filterRef.current?.count > 0 && (
        <div className="mb-4 mt-4 text-center">
          <button
            className="btn viewButton"
            onClick={() => handleSearchExperts()}
          >
            Search
          </button>

          <button className="btn viewButton ms-2" onClick={handleClearAll}>
            Reset Filter
          </button>
        </div>
      )}
    </div>
  );
};

export default memo(SelectionOption);
