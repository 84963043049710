import axios from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import API_URL from "../../Constants/api-path";
import { useDispatch, useSelector } from "react-redux";
import {
  setExpertList,
  setExpertPagination,
  setExpertProfile,
  setTopExpertList,
} from "../Pages/ExpertPage/Expert.Slice";
import {
  setCount,
  setTranscriptData,
  setTranscriptPagination,
} from "../Pages/Dashboard/Transcript.Slice";
import {
  setLimit,
  setPage,
  updateNewsData,
  setSelectedArticle,
  setIsLoadingDescription,
} from "../Pages/Dashboard/news.slice";
import { useOnceCall } from "./useOnceCall";
import { setToggle } from "../Pages/V2/Dashboard/dashboard.slice";

function useDashboard() {
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const { expertPagination } = useSelector((state) => state.expert)
  const [loading, setLoading] = useState(false);
  const [loadingExpert, setLoadingExpert] = useState(false);
  const [loadingTopExpert, setLoadingTopExpert] = useState(false);

  useOnceCall(() => {
    dispatch(setToggle(false))
  })

  const getAllExpertsDataDash = useCallback(async ({ pageE = expertPagination?.page, limitE = expertPagination?.limit, activeTab = false }) => {
    try {
      dispatch(setExpertPagination({ page: pageE, limit: limitE }))
      if (!activeTab) {
        setLoadingExpert(true);
      }

      const response = await axios.get(API_URL.GET_EXPERT_DATA + `?page=${pageE}&limit=${limitE}&user_id=${userData?.user?._id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.access_token}`,
        },
      });
      dispatch(
        setExpertList({
          data: response?.data?.data,
          count: response?.data?.countData,
        })
      ); //for selecting the default one
      setLoadingExpert(false);
    } catch (error) {
      console.error("error: ", error);
      setLoadingExpert(false);
    }
  }, [dispatch, expertPagination?.limit, expertPagination?.page, userData?.access_token, userData?.user?._id]);

  const getTopExpertsDataDash = useCallback(async () => {
    try {
      setLoadingTopExpert(true);
      const response = await axios.get(API_URL.GET_EXPERT_DATA + `?page=${1}&limit=${20}&user_id=${userData?.user?._id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.access_token}`,
        },
      });
      dispatch(
        setTopExpertList({
          data: response?.data?.data,
          count: response?.data?.countData,
        })
      ); //for selecting the default one
      setLoadingTopExpert(false);
    } catch (error) {
      console.error("error: ", error);
      setLoadingTopExpert(false);
    }
  }, [dispatch, userData?.access_token, userData?.user?._id]);

  const getAllTranscriptDataDash = useCallback(async ({ pageT = 1, limitT = 10 }) => {
    try {
      dispatch(setTranscriptPagination({ page: pageT, limit: limitT }))
      setLoading(true);
      const res = await axios.get(
        API_URL.GET_TRANSCRIPT_DATA +
        `?page=${pageT}&limit=${limitT}&user_id=${userData?.user?._id}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": userData.access_token,
          }
        }
      );
      dispatch(setTranscriptData(res?.data));
      dispatch(setCount(res?.countData));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("error: ", error);
    }
  }, [dispatch, userData.access_token, userData?.user?._id]);

  const getAllNewsDataDash = useCallback(
    async ({ page_no, page_limit, should_paginate = false }) => {
      try {
        setLoading(true);
        if (should_paginate) {
          dispatch(setPage(page_no));
          dispatch(setLimit(page_limit));
        }
        const res = await axios.get(
          API_URL.GET_FINANCE_NEWS +
          `?page=${page_no}&limit=${page_limit}&country=${userData?.user?.country?.toLowerCase()}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.access_token}`,
          },
        }
        );
        let obj = { data: res?.data?.data, count: res?.data?.count };
        dispatch(updateNewsData(obj));
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("error: ", error);
      }
    },
    [dispatch, userData?.access_token, userData?.user?.country]
  );

  const getExpertById = useCallback(
    async (id) => {
      try {
        const response = await axios.get(
          API_URL.GET_EXPERT_DATA_BY_ID + `?expert_id=${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.access_token}`,
          },
        }
        );
        dispatch(setExpertProfile(response?.data?.data));
      } catch (error) {
        console.error("ERROR", error);
      }
    },
    [dispatch, userData?.access_token]
  );

  const getNewsAiSummary = useCallback(
    async ({ news = {} }) => {
      dispatch(setIsLoadingDescription(true));
      await axios
        .get(API_URL.GET_FINANCE_NEWS_AI_SUMMARY + `?news_id=${news?._id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData?.access_token}`,
            },
          })
        .then((res) => {
          let article = { ...news, chatgpt_desc: res?.data?.data };
          dispatch(setSelectedArticle(article));
          dispatch(setIsLoadingDescription(false));
        })
        .catch((err) => {
          dispatch(setIsLoadingDescription(false));
          console.error("ERROR", err);
        });
    },
    [dispatch, userData?.access_token]
  );

  const addDefaultSrc = useCallback((ev) => {
    ev.target.src =
      "https://platform.nextyn.com/static/media/company_icons3.c720b9bb86fbb87c1eb6.png";
  }, []);

  const callOnceTopExp = useRef(false)
  useEffect(() => {
    if (!callOnceTopExp.current) {
      callOnceTopExp.current = true
      getTopExpertsDataDash()
    }
  }, [getTopExpertsDataDash])


  const values = useMemo(
    () => ({
      getAllExpertsDataDash,
      getAllTranscriptDataDash,
      getAllNewsDataDash,
      loading,
      loadingExpert,
      getExpertById,
      getNewsAiSummary,
      addDefaultSrc, getTopExpertsDataDash, loadingTopExpert
    }),
    [
      loadingExpert,
      getAllExpertsDataDash,
      getAllTranscriptDataDash,
      getAllNewsDataDash,
      loading,
      getExpertById,
      getNewsAiSummary,
      addDefaultSrc, getTopExpertsDataDash, loadingTopExpert
    ]
  );

  return values;
}

export default useDashboard;
