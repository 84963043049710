import { Divider, Popover, Tooltip } from 'antd'
import React, { useMemo, useState } from 'react'
import { FiBook, FiPhoneCall } from 'react-icons/fi';
import { GoDotFill } from "react-icons/go";
import { IoPeopleOutline } from 'react-icons/io5';
import _ from 'lodash';

const SourcePopover = ({ title, answer }) => {
    const [more, setMore] = useState(false)
    // const navigate = useNavigate()
    let filterData = useMemo(() => more ? answer : answer?.slice(0, 3), [answer, more])
    const content = filterData?.map((data) => (
        <div className='mt-0 mb-0'>
            <div className='source_content'>
                <div className='d-flex align-items-center'>
                    <span className='mb-0' style={{
                        lineBreak: "anywhere"
                    }}
                    // onClick={() => {
                    //     if (data?.topic) {
                    //         navigate(`/transcript-details/${data?._id}`)
                    //     }
                    // }}
                    >
                        <GoDotFill style={{
                            fontSize: 7
                        }} /> <Tooltip title={data?.title || data?.name || data?.topic} placement='right' color="#5746EB">{_?.truncate(data?.title || data?.name || data?.topic, {
                            length: 50,
                            omission: "..."
                        })}</Tooltip>
                    </span>
                </div>
            </div>
            <Divider className='m-1' />
        </div>
    ));
    return (
        <Popover trigger={["click"]} placement="right" overlayStyle={{
            width: 400
        }} title={
            <div className='d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center gap-1'>
                    <span> <IoPeopleOutline className='fs-5' style={{
                        color: "#407BFF"
                    }} /> </span>
                    <span>{title}</span>
                </div>
            </div>} content={<>
                <div>
                    {content}
                </div>
                <a className='d-flex align-items-center justify-content-center text-decoration-underline reset_anchor'
                    onClick={() => {
                        setMore(!more)
                    }} style={{
                        fontSize: 12
                    }}>{more ? "Show less" : "Show more"}</a></>}><a className='reset_anchor'><FiBook /></a></Popover>
    )
}

export default SourcePopover
