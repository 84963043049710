import axios from 'axios'
import { useCallback, useMemo } from 'react'
import useSWR from 'swr'
import API_URL from '../../Constants/api-path'
import { useDispatch, useSelector } from 'react-redux'
import { setAnswer, setCurrent, setSelectedCompanyTranscriptData } from '../Pages/V2/Dashboard/Answer.slice'
import { getQueryParams } from '../../Utils/Util'
import { useParams } from 'react-router-dom'

const useCompanyTranscript = () => {
    console.log("jkdadhkadhasi");
    const userData = JSON.parse(localStorage.getItem("userData"));
    const dispatch = useDispatch()
    const { selectedCompanyTranscript, selectedCompanyTranscriptData } = useSelector((state) => state.answer)
    console.log('selectedCompanyTranscriptData: ', selectedCompanyTranscriptData);
    let queryParams = getQueryParams(window.location.search);
    const { id } = useParams();
    const getCompanyTranscript = useCallback(async () => {
        const res = await axios.get(API_URL.GET_EARNING_CALL_TRANSCRIPT_DETAILS + `?symbol=${"TCS_3404550" || queryParams?.symbol}&company_id=${"65e805be40be4a3f48ed7e4f" || id}&cin=${"L22210MH1995PLC084781" || queryParams?.cin}`)
        return res.data
    }, [id, queryParams?.cin, queryParams?.symbol])


    const { isLoading } = useSWR(["get_company_transcript_details", queryParams?.symbol, queryParams?.cin, id], () => queryParams?.symbol && queryParams?.cin && id ? getCompanyTranscript() : null, {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (res) => {
            if (res) {
                dispatch(setSelectedCompanyTranscriptData(res?.data))
            }
        },
        onError: (err) => {
            console.error("ERROR", err)
        }
    })



    const values = useMemo(() => ({
        isLoading, selectedCompanyTranscriptData
    }), [isLoading, selectedCompanyTranscriptData])
    return values
}

export default useCompanyTranscript
