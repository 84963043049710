import API_URL from "../../Constants/api-path";
import { useCallback, useMemo } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  setCount,
  setIsLoadingPagination,
  setLockedData,
  setSelectedTranscriptData,
  setSumryList,
  setSumryListLoading,
  setTranscriptData,
  setTranscriptPagination,
  setUnlockedData,
} from "../Pages/Dashboard/Transcript.Slice";
import useSWR from "swr";
import { setSelectedItem } from "../Pages/V2/SearchPage/transcriptExpert.slice";
import { useParams } from "react-router-dom";
import { useOnceCall } from "./useOnceCall";

function useTranscript(show_unlock = null) {
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const { id } = useParams();
  const { count, transcriptPagination, data, searchVal } = useSelector((state) => state?.transcript);

  useOnceCall(() => {
    dispatch(setTranscriptPagination({ page: 1, limit: 20 }))
  })
  const getTranscriptData = useCallback(
    async (show_unlock = null) => {
      const res = await axios.get(
        API_URL.GET_TRANSCRIPT_DATA +
        `?page=1&limit=20&user_id=${userData?.user?._id}${show_unlock !== null ? `&show_unlock=${show_unlock}` : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": userData.access_token,
          }
        }
      );
      return res.data;
    },
    [userData.access_token, userData?.user?._id]
  );
  const getTranscriptDataById = useCallback(async () => {
    const res = await axios.get(
      API_URL.GET_TRANSCRIPT_DATA_BY_ID +
      `?id=${id}&user_id=${userData?.user?._id}`, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": userData.access_token,
      },
    }
    );
    return res.data;
  }, [id, userData.access_token, userData?.user?._id]);

  const GetAiSmrryList = useCallback(
    async (transcriptId) => {
      try {
        dispatch(setSumryListLoading(true));
        const res = await fetch(
          `${API_URL.GET_TRANSCRIPT_AI_SUMMARY}?transcriptId=${transcriptId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-access-token": userData.access_token,
            }

          }
        );

        const result = await res.json();
        if (result) {
          dispatch(setSumryList(result?.data));
        }
        dispatch(setSumryListLoading(false));
      } catch (error) {
        dispatch(setSumryListLoading(false));
        console.error(error, "error");
      }
    },
    [dispatch]
  );

  const { isLoading: isLoadingTransData, isValidating: isValidatingTransData } =
    useSWR(
      ["transcript_data_by_id", id],
      () => (id ? getTranscriptDataById() : null),
      {
        revalidateOnMount: true,
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        onSuccess: (data) => {
          dispatch(setSelectedTranscriptData(data?.data?.[0] || {}));
          if (data?.data?.[0])
            GetAiSmrryList(data?.data?.[0]?.assembly_response_id);
        },
        onError: (err) => {
          console.error("ERROR", err);
        },
      }
    );
  const transcriptData = useSWR(
    ["transcript_data", show_unlock, id],
    () => !id && getTranscriptData(show_unlock),
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      onSuccess: (data) => {
        if (data?.data?.length > 0) {
          dispatch(setSelectedItem(data?.data[0])); //for selecting the default one
        }
        if (show_unlock !== null && show_unlock) {
          dispatch(setUnlockedData({ data: data?.data, count: data?.countData }));
        } else if (show_unlock !== null && !show_unlock) {
          dispatch(setLockedData({ data: data?.data, count: data?.countData }));
        }
        dispatch(setTranscriptData(data?.data));
        dispatch(setCount(data?.countData));
      },
      onError: (err) => {
        console.error("ERROR", err);
      },
    }
  );
  const { isLoading, isValidating, mutate } = transcriptData;

  const fetchTranscriptData = useCallback(async ({ search = false, key = searchVal, paginate = false, page = transcriptPagination.page, limit = transcriptPagination.limit }) => {
    if (paginate) {
      dispatch(setIsLoadingPagination(true))
      dispatch(setTranscriptPagination({ page: page, limit: limit }))
      await axios
        .get(
          API_URL.GET_TRANSCRIPT_DATA +
          `?user_id=${userData?.user?._id}&search=${key}&page=${page}&limit=${limit}${show_unlock !== null ? `&show_unlock=${show_unlock}` : ""}`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": userData.access_token,
            }
          }
        )
        .then((res) => {
          if (show_unlock !== null && show_unlock) {
            dispatch(setUnlockedData({ data: res?.data?.data, count: res?.data?.countData }));
          } else if (show_unlock !== null && !show_unlock) {
            dispatch(setLockedData({ data: res?.data?.data, count: res?.data?.countData }));
          }
          dispatch(setTranscriptData(res?.data?.data));
          dispatch(setCount(res?.data?.countData));
          dispatch(setIsLoadingPagination(false))
        })
        .catch((err) => {
          dispatch(setIsLoadingPagination(false))
          console.error("ERROR", err);
        });
    }

    if (search) {
      dispatch(setTranscriptPagination({ page: 1, limit: 20 }))
      const res = await axios
        .get(
          API_URL.GET_TRANSCRIPT_DATA +
          `?user_id=${userData?.user?._id}&page=${1}&limit=${20}&search=${key}${show_unlock !== null ? `&show_unlock=${show_unlock}` : ""}`,
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-token": userData.access_token,
            }
          }
        )
        .then((res) => {
          if (show_unlock !== null && show_unlock) {
            dispatch(setUnlockedData({ data: res?.data?.data, count: res?.data?.countData }));
          } else if (show_unlock !== null && !show_unlock) {
            dispatch(setLockedData({ data: res?.data?.data, count: res?.data?.countData }));
          }
          dispatch(setTranscriptData(res?.data?.data));
          dispatch(setCount(res?.data?.countData));
        })
        .catch((err) => {
          console.error("ERROR", err);
        });
      return res?.data;
    }

  }, [dispatch, searchVal, show_unlock, transcriptPagination.limit, transcriptPagination.page, userData?.user?._id]);

  const values = useMemo(
    () => ({
      fetchTranscriptData: fetchTranscriptData,
      isLoading: isLoading,
      isValidating: isValidating,
      data: data,
      count: count,
      getTranscriptDataById,
      isLoadingTransData,
      isValidatingTransData, mutate
    }),
    [
      fetchTranscriptData,
      isLoading,
      data,
      count,
      isValidating,
      getTranscriptDataById,
      isLoadingTransData,
      isValidatingTransData, mutate
    ]
  );

  return values;
}
export default useTranscript;
