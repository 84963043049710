import { Popover, Skeleton, Spin, Tooltip } from "antd";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import AnswerAccordianRight from "./AnswerAccordianRight";
import SecReportPopOver from "./SecReportPopOver";
import { IoDocumentText } from "react-icons/io5";
import axios from "axios";
import API_URL from "../../Constants/api-path";
import { setSecReportLoading, setSecReportSummary, setShowMoreRight } from "../Pages/V2/Dashboard/Answer.slice";

function SecondaryReport({
    title,
    cls,
    loading,
    spendCredits, showMoreRight, itemkey,
    data = { data: [1, 2, 3, 4, 5, 6, 7, 8] },
}) {

    const { sec_report_loading, sec_report_summary } = useSelector((state) => state.answer)
    const dispatch = useDispatch()
    // const [showMore, setShowMore] = React.useState(false);
    const sliceData = React.useMemo(() => showMoreRight?.open && showMoreRight?.index === itemkey ? data?.data : data?.data?.slice(0, 4), [data?.data, itemkey, showMoreRight?.index, showMoreRight?.open])

    const capitalizeFirstLetter = React.useCallback((string) => {
        if (!string) return "";
        return string.charAt(0).toUpperCase() + string.slice(1);
    }, []);

    const handleClickSecReport = React.useCallback(
        async (id) => {
            dispatch(setSecReportLoading(true))
            try {
                const res = await axios.get(API_URL.GET_SEC_REPORT_SUMMARY + `?report_id=${id || "6748186e035285f9d5a81ccd"}`)
                dispatch(setSecReportLoading(false))
                let summarydetails = res?.data?.chatgpt_summary?.split("-")
                summarydetails?.shift(1)
                dispatch(setSecReportSummary(summarydetails))
            } catch (error) {
                dispatch(setSecReportLoading(false))
                console.error('error: ', error);
            }
        },
        [dispatch]
    );

    return (
        <div className={`${cls}`}>
            <AnswerAccordianRight
                title={title}
                content={
                    <div
                    // className="custom_card_right"
                    >
                        <div className="ctm_header my-2">
                            <Row>
                                <Col xs={10}>
                                    <Skeleton
                                        rootClassName="custom_skeleton"
                                        loading={loading}
                                        style={{ width: 1000, margin: 0 }}
                                        active
                                        title={true}
                                        className="mb-0"
                                        paragraph={{
                                            rows: 0,
                                            style: {
                                                margin: 0,
                                            },
                                        }}
                                    >
                                        {" "}
                                        <div>Title</div>
                                    </Skeleton>
                                </Col>

                                <Col xs={2}>
                                    <Skeleton
                                        loading={loading}
                                        style={{ width: 200 }}
                                        active
                                        title={true}
                                        className=" mb-0"
                                        paragraph={{
                                            rows: 0,
                                            style: {
                                                margin: 0,
                                            },
                                        }}
                                    >
                                        <div>Document</div>
                                    </Skeleton>
                                </Col>
                            </Row>
                        </div>
                        <div className="ctm_body">
                            {loading && (
                                <div
                                    className="d-flex flex-column justify-content-center align-items-center"
                                    style={{ height: 100 }}
                                >
                                    <Spin
                                        indicator={
                                            <LoadingOutlined style={{ fontSize: 30 }} spin />
                                        }
                                    />
                                    <span>Initiating query processing and moderation.</span>
                                </div>
                            )}
                            {!loading &&
                                sliceData?.map((curElem, index) => (
                                    <Row key={index}>
                                        <Col
                                            xs={10}
                                        // onClick={() => handleClickSecReport(curElem?._id)}
                                        >
                                            <Skeleton
                                                loading={loading}
                                                style={{ width: 1000 }}
                                                active
                                                title={true}
                                                className="mb-0"
                                                paragraph={{
                                                    rows: 0,
                                                    style: {
                                                        margin: 0,
                                                    },
                                                }}
                                            >
                                                {" "}
                                                <Popover
                                                    placement="left"
                                                    trigger={["click"]}
                                                    onOpenChange={(open) => {
                                                        if (open) {
                                                            handleClickSecReport(curElem?._id)
                                                        } else {
                                                            dispatch(setSecReportLoading(false))
                                                            dispatch(setSecReportSummary([]))
                                                        }

                                                    }}
                                                    content={
                                                        <SecReportPopOver
                                                            curElem={curElem}
                                                            summary={sec_report_summary}
                                                            loadingsummary={sec_report_loading}
                                                        />
                                                    }
                                                >
                                                    <Tooltip
                                                        title={capitalizeFirstLetter(curElem?.title)}
                                                        color="#5746EB"
                                                    >
                                                        <p
                                                            className="fw-bolder truncate-text mb-0"
                                                            style={{ fontSize: "12px", cursor: "pointer" }}
                                                        >
                                                            {capitalizeFirstLetter(curElem?.title)}
                                                        </p>
                                                    </Tooltip>
                                                </Popover>
                                            </Skeleton>
                                        </Col>

                                        <Col xs={2}>
                                            <Skeleton
                                                loading={loading}
                                                style={{ width: 300 }}
                                                active
                                                title={true}
                                                className="mb-0"
                                                paragraph={{
                                                    rows: 0,
                                                    style: {
                                                        margin: 0,
                                                    },
                                                }}
                                            >
                                                {" "}
                                                <small
                                                    className="fw-normal text-muted"
                                                    style={{ whiteSpace: "nowrap" }}
                                                >
                                                    <span className="d-flex align-items-center justify-content-center">{curElem?.documents?.slice(0, 2)?.map((docu, index) => <span key={index}> <Tooltip title={docu?.file_original_name} color="#5746EB"><a href={docu?.s3url} target="_blank" rel="noreferrer"><IoDocumentText /></a></Tooltip></span>)}</span>
                                                </small>
                                            </Skeleton>
                                        </Col>

                                    </Row>
                                ))}
                            {showMoreRight?.open && showMoreRight?.index === itemkey ? (
                                <>
                                    {/* <p className="list_style_ans_accordian">{contentTextShowMore}</p> */}
                                    <div
                                        className="d-flex align-items-center justify-content-end cursor-pointer themeBlue pointer"
                                        onClick={() => {
                                            dispatch(setShowMoreRight({
                                                open: false,
                                                index: itemkey
                                            }))
                                            // setShowMore(false);
                                        }}
                                    >
                                        show less
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div
                                        className="d-flex align-items-center justify-content-end cursor-pointer themeBlue pointer"
                                        onClick={() => {
                                            dispatch(setShowMoreRight({
                                                open: true,
                                                index: itemkey
                                            }))
                                            // setShowMore(true);
                                        }}
                                    >
                                        show more
                                    </div>
                                </>
                            )}

                            {data?.data?.length === 0 && !loading && (
                                <div
                                    className="d-flex justify-content-center align-items-center"
                                    style={{ height: 100 }}
                                >
                                    <span>No Transcripts Found Related to search</span>
                                </div>
                            )}
                        </div>
                    </div>
                }
            />

        </div>
    );
}

export default SecondaryReport;
