import { Modal, Table, Tooltip } from "antd";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsOpenAiModal } from "../Pages/Dashboard/news.slice";
import { Row, Col } from 'react-bootstrap';
import Loader from "../Components/Loader"
import { formattedDate } from "../../Utils/Util";
import { setOpenCompanyTranscriptModal, setSelectedCompanyTranscript } from "../Pages/V2/Dashboard/Answer.slice";
import moment from "moment";
import { FilePdfOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

function CompanyTranscriptModal() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { openCompanyTranscriptModal, isLoadingCompanyTranscript, companyTranscriptList, selectedCompanyTranscript } = useSelector((state) => state.answer)
    console.log('selectedCompanyTranscript: ', selectedCompanyTranscript, companyTranscriptList);


    const footer = (
        <>
            <span
                target="_blank"
                style={{ color: "#5746ec", fontWeight: 500 }}
                // href={selected_article?.link}
                onClick={() => {
                    navigate(`/company?company_id=${companyTranscriptList?.data?.company_id}`)
                }}
            >
                Navigate to Company
            </span>
        </>
    );
    const conferenceColumns = [
        {
            title: "Date",
            dataIndex: "date",
        },
        {
            title: "Heading",
            dataIndex: "heading",
        },
        {
            title: "Quarter",
            dataIndex: "quarter",
        },

    ];

    const confCallsData = companyTranscriptList?.data?.transcripts
        ?.map((data, index) => ({
            key: index,
            date: <div >{moment(data?.time)?.format("DD/MM/YYYY")}</div>,
            quarter: <div > {data?.quarter}</div>,
            heading: <div>{data?.title || "-"}</div>,
            data: data
        }));
    return (
        <div>
            {" "}
            <Modal
                title={
                    <>
                        Company Transcripts
                    </>
                }
                centered
                open={openCompanyTranscriptModal}
                footer={footer}
                className="borderLessHeader"
                onCancel={() => dispatch(setOpenCompanyTranscriptModal(false))}
                width={800}
                wrapClassName="custom-modal-mask"
                styles={
                    {
                        body: {
                            height: 400
                        }
                    }
                }
            >
                <div className="">
                    {isLoadingCompanyTranscript ?
                        <div className="d-flex justify-content-center align-item-center">
                            <Loader />
                        </div>
                        : <Table columns={conferenceColumns} dataSource={confCallsData} scroll={{ y: 300 }} onRow={(i) => ({
                            onClick: (e) => {
                                dispatch(setSelectedCompanyTranscript({ ...i?.data, cin: companyTranscriptList?.data?.cin, company_id: companyTranscriptList?.data?.company_id, symbol: companyTranscriptList?.data?.symbol }))
                                navigate(`/company-transcript/${companyTranscriptList?.data?.company_id}?cin=${companyTranscriptList?.data?.cin}&symbol=${companyTranscriptList?.data?.symbol}`)
                            }

                        })} />}
                </div>
            </Modal>
        </div>
    );
}

export default CompanyTranscriptModal;
