import { Card, Collapse, Popover, Skeleton, Spin, theme, Tooltip } from "antd";
import React from "react";
import { Col, Row } from "react-bootstrap";
import TranscriptPopOver from "./TranscriptPopOver";
import useProject from "../Hooks/useProject";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  clearChatMessages,
  setSelectedTranscript,
} from "../Pages/Transcript/TranscriptChat.slice";
import UnlockTranscriptModal from "./UnlockTranscriptModal";
import { LoadingOutlined } from "@ant-design/icons";
import AnswerAccordianRight from "./AnswerAccordianRight";
import { formattedDate } from "../../Utils/Util";
import { setShowMoreRight } from "../Pages/V2/Dashboard/Answer.slice";

function PrimaryTranscript({
  title,
  cls,
  loading,
  spendCredits,
  itemkey, showMoreRight,
  data = { data: [1, 2, 3, 4, 5, 6, 7, 8] },
}) {

  const userData = JSON.parse(localStorage.getItem("userData"));
  const [hoveredItemId, setHoveredItemId] = React.useState(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  // const [showMore, setShowMore] = React.useState(false);
  const [selected, setSelected] = React.useState({});
  const creditsData = useSelector((state) => state?.user?.all_credits);
  const { projectListById } = useSelector((state) => state.project);
  const history = useNavigate();
  const dispatch = useDispatch();

  const permission = React.useMemo(
    () => userData?.user?.role_id,
    [userData?.user?.role_id]
  );
  const sliceData = React.useMemo(() => showMoreRight?.open && showMoreRight?.index === itemkey ? data?.data : data?.data?.slice(0, 4), [data?.data, itemkey, showMoreRight?.index, showMoreRight?.open])
  const { AddExpertProject, getProjectListById, mutateProjectList } =
    useProject();

  const handleCancel = React.useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const capitalizeFirstLetter = React.useCallback((string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  }, []);

  const goToDetailsPage = React.useCallback(
    (curElem) => {
      history({
        pathname: `/transcript-details/${curElem?._id}`,
        state: { curElem },
      });
    },
    [history]
  );

  const handleSpendCredit = React.useCallback(
    async (id, category) => {
      let res = await spendCredits({
        id,
        category,
        credits_spend: creditsData?.credits_for_transcript,
        navigation: false,
      });
      return res;
    },
    [creditsData?.credits_for_transcript, spendCredits]
  );
  const showModal = React.useCallback((elem) => {
    setSelected(elem);
    setIsModalOpen(true);
  }, []);
  const handleClickTranscript = React.useCallback(
    (curElem) => {
      // if (curElem?.unlock === true) {
      if (permission?.transcript?.view) {
        goToDetailsPage(curElem);
        dispatch(clearChatMessages());
      }
      // } else if (
      //   hoveredItemId === curElem?._id &&
      //   permission?.transcript?.unlock
      // ) {
      //   dispatch(clearChatMessages());
      //   showModal(curElem);
      // }
    },
    [dispatch, goToDetailsPage, permission?.transcript?.view]
  );

  return (
    <div className={`${cls}`}>
      <AnswerAccordianRight
        title={title}
        content={
          <div
          // className="custom_card_right"
          >
            <div className="ctm_header my-2">
              <Row>
                <Col xs={4}>
                  <Skeleton
                    rootClassName="custom_skeleton"
                    loading={loading}
                    style={{ width: 400, margin: 0 }}
                    active
                    title={true}
                    className="mb-0"
                    paragraph={{
                      rows: 0,
                      style: {
                        margin: 0,
                      },
                    }}
                  >
                    {" "}
                    <div>Transcript Title</div>
                  </Skeleton>
                </Col>
                <Col xs={3}>
                  <Skeleton
                    loading={loading}
                    style={{ width: 300 }}
                    active
                    title={true}
                    className=" mb-0"
                    paragraph={{
                      rows: 0,
                      style: {
                        margin: 0,
                      },
                    }}
                  >
                    <div>Expert Title</div>
                  </Skeleton>
                </Col>
                <Col xs={3}>
                  <Skeleton
                    loading={loading}
                    style={{ width: 300 }}
                    active
                    title={true}
                    className=" mb-0"
                    paragraph={{
                      rows: 0,
                      style: {
                        margin: 0,
                      },
                    }}
                  >
                    <div>Expert Company</div>
                  </Skeleton>
                </Col>
                <Col xs={2}>
                  <Skeleton
                    loading={loading}
                    style={{ width: 200 }}
                    active
                    title={true}
                    className=" mb-0"
                    paragraph={{
                      rows: 0,
                      style: {
                        margin: 0,
                      },
                    }}
                  >
                    <div>Date</div>
                  </Skeleton>
                </Col>
              </Row>
            </div>
            <div className="ctm_body">
              {loading && (
                <div
                  className="d-flex flex-column justify-content-center align-items-center"
                  style={{ height: 100 }}
                >
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 30 }} spin />
                    }
                  />
                  <span>Initiating query processing and moderation.</span>
                </div>
              )}
              {!loading &&
                sliceData?.map((curElem, index) => (
                  <Row key={index}>
                    <Col
                      xs={4}
                      onMouseEnter={() => setHoveredItemId(curElem?._id)}
                      onMouseLeave={() => setHoveredItemId(null)}
                      onClick={() => handleClickTranscript(curElem)}
                    >
                      <Skeleton
                        loading={loading}
                        style={{ width: 400 }}
                        active
                        title={true}
                        className="mb-0"
                        paragraph={{
                          rows: 0,
                          style: {
                            margin: 0,
                          },
                        }}
                      >
                        {" "}
                        <Popover
                          placement="left"
                          content={
                            <TranscriptPopOver
                              curElem={curElem}
                              functions={{
                                AddExpertProject,
                                getProjectListById,
                                mutateProjectList,
                              }}
                              dataVariables={{ projectListById }}
                            />
                          }
                        >
                          <Tooltip
                            title={capitalizeFirstLetter(curElem?.topic)}
                          >
                            <p
                              className="fw-bolder truncate-text mb-0"
                              style={{ fontSize: "12px", cursor: "pointer" }}
                              onClick={() => {
                                if (curElem?.unlock === true) {
                                  if (permission?.transcript?.view) {
                                    goToDetailsPage(curElem);
                                    dispatch(clearChatMessages());
                                  }
                                } else {
                                  if (permission?.transcript?.unlock) {
                                    dispatch(clearChatMessages());
                                    showModal(curElem);
                                  }
                                }
                              }}
                            >
                              {capitalizeFirstLetter(curElem?.topic)}
                            </p>
                          </Tooltip>
                        </Popover>
                      </Skeleton>
                    </Col>
                    <Col
                      xs={3}
                      onMouseEnter={() => setHoveredItemId(curElem?._id)}
                      onMouseLeave={() => setHoveredItemId(null)}
                      onClick={() => handleClickTranscript(curElem)}
                    >
                      <Skeleton
                        loading={loading}
                        style={{ width: 300 }}
                        active
                        title={true}
                        className="mb-0"
                        paragraph={{
                          rows: 0,
                          style: {
                            margin: 0,
                          },
                        }}
                      >
                        {/* <Popover
                    placement="left"
                    content={
                      <TranscriptPopOver
                        curElem={curElem}
                        functions={{
                          AddExpertProject,
                          getProjectListById,
                          mutateProjectList,
                        }}
                        dataVariables={{ projectListById }}
                      />
                    }
                  >  */}
                        <Tooltip
                          title={curElem?.expertData?.[0]?.current_designation}
                        >
                          <small
                            className="fw-normal truncate-text pointer"
                          // onClick={() => {
                          //   if (permission?.experts?.request) {
                          //     dispatch(setIsOpenProject(true));
                          //   }
                          // }}
                          >
                            {curElem?.expertData?.[0]?.current_designation ||
                              "NA"}
                          </small>
                        </Tooltip>
                        {/* </Popover> */}
                      </Skeleton>
                    </Col>
                    <Col
                      xs={3}
                      onMouseEnter={() => setHoveredItemId(curElem?._id)}
                      onMouseLeave={() => setHoveredItemId(null)}
                      onClick={() => handleClickTranscript(curElem)}
                    >
                      <Skeleton
                        loading={loading}
                        style={{ width: 300 }}
                        active
                        title={true}
                        className="mb-0"
                        paragraph={{
                          rows: 0,
                          style: {
                            margin: 0,
                          },
                        }}
                      >
                        {" "}
                        <div>{curElem?.expert_company}</div>
                      </Skeleton>
                    </Col>
                    <Col
                      xs={2}
                      onMouseEnter={() => setHoveredItemId(curElem?._id)}
                      onMouseLeave={() => setHoveredItemId(null)}
                      onClick={() => handleClickTranscript(curElem)}
                    >
                      <Skeleton
                        loading={loading}
                        style={{ width: 300 }}
                        active
                        title={true}
                        className="mb-0"
                        paragraph={{
                          rows: 0,
                          style: {
                            margin: 0,
                          },
                        }}
                      >
                        {" "}
                        <small
                          className="fw-normal text-muted"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {formattedDate(curElem?.start_time)}
                        </small>
                      </Skeleton>
                    </Col>

                  </Row>
                ))}
              {showMoreRight?.open && showMoreRight?.index === itemkey ? (
                <>
                  {/* <p className="list_style_ans_accordian">{contentTextShowMore}</p> */}
                  <div
                    className="d-flex align-items-center justify-content-end cursor-pointer themeBlue pointer"
                    onClick={() => {
                      dispatch(setShowMoreRight({
                        open: false,
                        index: itemkey
                      }))
                      // setShowMore(false);
                    }}
                  >
                    show less
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="d-flex align-items-center justify-content-end cursor-pointer themeBlue pointer"
                    onClick={() => {
                      dispatch(setShowMoreRight({
                        open: true,
                        index: itemkey
                      }))
                      // setShowMore(true);
                    }}
                  >
                    show more
                  </div>
                </>
              )}
              {data?.data?.length === 0 && !loading && (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: 100 }}
                >
                  <span>No Transcripts Found Related to search</span>
                </div>
              )}
            </div>
          </div>
        }
      />

      <UnlockTranscriptModal
        handleCancel={handleCancel}
        isModalOpen={isModalOpen}
        unlockFunction={async () => {
          try {
            let response = await handleSpendCredit(selected?._id, "transcript");
            if (response) {
              goToDetailsPage(selected);
              dispatch(setSelectedTranscript(selected));
            }
            setIsModalOpen(false);
            return response;
          } catch (error) {
            return error;
          }
        }}
      />
    </div>
  );
}

export default PrimaryTranscript;
